import React from 'react';

function ChevronLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#00766C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 17l-5-5 5-5m7 10l-5-5 5-5"
      ></path>
    </svg>
  );
}

export default ChevronLeft;
