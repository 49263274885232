import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectClients = (state: RootState) => state.client.list;

export const selectPlan = (state: RootState) => state.plan.plan;

export const selectPlanInputs = (state: RootState) =>
  state.plan.plan?.planInputs;

export const selectPlanProjectStartDate = (state: RootState) =>
  state.plan.plan?.planInputs?.projectStartDate;
