import { InputNumber } from 'antd';
import React from 'react';

import { numberFormatter } from '../../utils/helperFunctions';

type Props = {
  name?: string;
  label: string;
  value?: string | number;
  onChange?: (e: any) => any;
  onBlur?: (e: any) => any;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: any;
  addonBefore?: string;
  style?: any;
  labelStyle?: any;
  step?: number;
  min?: number;
};

const FormInputNumberField = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  type,
  disabled,
  placeholder,
  error,
  addonBefore,
  style,
  labelStyle,
  step,
  min,
}: Props) => {
  const props =
    type !== 'number-sd'
      ? {
          // @ts-ignore
          parser: (v: string) => v.replace(/\$\s?|(,*)/g, ''),
          // @ts-ignore
          formatter: (v) => {
            // @ts-ignore
            return v !== null && v !== undefined && v !== ''
              ? numberFormatter(v, '', true)
              : v;
          },
        }
      : {};
  return (
    <div className="c-form-field">
      <div className="c-form-field__label" style={labelStyle}>
        {label}:
      </div>
      <div className="c-form-field__wrapper">
        {/* @ts-ignore */}
        <InputNumber
          name={name}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          addonBefore={addonBefore}
          style={style}
          step={step}
          min={min}
          {...props}
          precision={2}
        />
        <div className="c-form-field__error">{error ? error : undefined}</div>
      </div>
    </div>
  );
};

export default FormInputNumberField;
