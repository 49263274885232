import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchLoanTypes = createAsyncThunk(
  'loan_types/get',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/loantype`,
      options,
    );
    const data = decryptData(response.data);
    return data.loanType;
  },
);

export const createClient = createAsyncThunk(
  'client/create-client',
  async (apiParams: { token: string; data: any }, { rejectWithValue }) => {
    try {
      const options = getBasicAuthAPIOptions(apiParams.token);

      const response = await axios.post(
        `${env_constants.PP_API_BASE_URL}/Client`,
        apiParams.data,
        {
          headers: {
            ...options.headers,
            'content-type': 'multipart/form-data',
          },
        },
      );
      const data = decryptData(response.data);
      return data;
    } catch (error) {
      return rejectWithValue(
        // @ts-ignore
        error && error.response && error.response.data
          ? // @ts-ignore
            decryptData(error.response.data)
          : '',
      );
    }
  },
);

export const editClientName = createAsyncThunk(
  'client/edit-client-name',
  async (apiParams: { token: string; data: any }, { rejectWithValue }) => {
    try {
      const options = getBasicAuthAPIOptions(apiParams.token);
      const encrypted = encryptData(apiParams.data);

      const response = await axios.put(
        `${env_constants.PP_API_BASE_URL}/Client`,
        encrypted,
        {
          headers: {
            ...options.headers,
          },
        },
      );

      const data = decryptData(response.data);

      return data;
    } catch (error) {
      return rejectWithValue(
        // @ts-ignore
        error && error.response && error.response.data
          ? // @ts-ignore
            decryptData(error.response.data)
          : '',
      );
    }
  },
);

export const fetchClients = createAsyncThunk(
  'client/get',
  async (apiParams: { searchValue: string; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Client/${apiParams.searchValue}`,
      options,
    );

    const data = decryptData(response.data);
    return data.clients;
  },
);
