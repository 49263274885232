import { createSlice } from '@reduxjs/toolkit';

import {
  fetchCostsByStateData,
  fetchLMIRates,
  fetchStampDuties,
  updateCostsByStateData,
  updateLMIRate,
  updateStampDuty,
} from './async_thunks';
import { STAMPANDLMISTATE } from './types';

const initialState: STAMPANDLMISTATE = {
  stamp_duties: [],
  lmi_rates: [],
  cost_by_state: [],
};

export const nftSlice = createSlice({
  name: 'stamp_and_lmi',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCostsByStateData.fulfilled, (state, action) => {
        state.cost_by_state = action.payload;
      })
      .addCase(updateCostsByStateData.fulfilled, (state, action) => {
        state.cost_by_state = action.payload;
      })
      .addCase(fetchLMIRates.fulfilled, (state, action) => {
        state.lmi_rates = action.payload;
      })
      .addCase(updateLMIRate.fulfilled, (state, action) => {
        state.lmi_rates = action.payload;
      })
      .addCase(updateStampDuty.fulfilled, (state, action) => {
        state.stamp_duties = action.payload;
      })
      .addCase(fetchStampDuties.fulfilled, (state, action) => {
        state.stamp_duties = action.payload;
      });
  },
});

export default nftSlice.reducer;
