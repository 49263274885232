import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */

export const selectCashFlowProjectionPerformanceDetails = (state: RootState) =>
  state.property.cashflow_projection?.performanceDetails;

export const selectCashFlowProjectionMonthlyGraphData = (state: RootState) => {
  if (
    state.property.cashflow_projection?.monthlyGraph &&
    state.property.cashflow_projection?.monthlyGraph[0]
  ) {
    return state.property.cashflow_projection?.monthlyGraph[0]?.data;
  }
};

export const selectCashFlowProjectionQuaterlyGraphData = (state: RootState) => {
  if (
    state.property.cashflow_projection?.quaterlyGraph &&
    state.property.cashflow_projection?.quaterlyGraph[0]
  ) {
    return state.property.cashflow_projection?.quaterlyGraph[0]?.data;
  }
};

export const selectCashFlowProjectionYearlyGraphData = (state: RootState) => {
  if (
    state.property.cashflow_projection?.yearlyGraph &&
    state.property.cashflow_projection?.yearlyGraph[0]
  ) {
    return state.property.cashflow_projection?.yearlyGraph[0]?.data;
  }
};
