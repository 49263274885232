import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import {
  ClientStateType,
  PlanDuplicateSteps,
} from '../plan/plan_duplicate/types';
import ClientCard from './client_card/ClientCard';
import CreateClient from './create_client/CreateClient';
import { fetchClients } from './redux/async_thunks';
import { selectClients } from './redux/selectors';
import { setClients } from './redux/slice';

type ClientProps = {
  isPlanDuplicateView?: boolean;
  planDuplicateStep?: PlanDuplicateSteps;
  setClientForPlanDuplicate?: React.Dispatch<
    React.SetStateAction<ClientStateType>
  >;
  setPlanDuplicateStep?: React.Dispatch<
    React.SetStateAction<PlanDuplicateSteps>
  >;
};

const Client = ({
  isPlanDuplicateView,
  setClientForPlanDuplicate,
  planDuplicateStep,
  setPlanDuplicateStep,
}: ClientProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState<'search' | 'create'>('search');

  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [isSearchErrorMessageVisible, setIsSearchErrorMessageVisible] =
    useState(false);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const clients = useSelector(selectClients);

  const handleClientSearch = () => {
    if (searchText) {
      fetchClientsResults(searchText);
    }
  };

  useEffect(
    () => () => {
      dispatch(setClients([]));
    },
    [],
  );

  /**
   * Fetches the clients
   */
  const fetchClientsResults = async (searchValue: string) => {
    setIsSearchErrorMessageVisible(false);

    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      if (user?.token) {
        await dispatch(
          fetchClients({ token: user.token, searchValue: searchValue }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      dispatch(setClients([]));

      if (error.message.includes('404')) {
        setIsSearchErrorMessageVisible(true);
      }
    }
  };

  const handleViewChange = (value: 'search' | 'create') => () => {
    setView(value);
  };

  return (
    <div className="client-view">
      {isLoading && <FullPageLoader />}
      {view === 'search' && (
        <>
          <div className="client-view__search-label">Search Clients</div>
          <div className="client-view__search-wrapper">
            <Input
              value={searchText}
              className="client-view__search-input"
              prefix={<SearchOutlined />}
              placeholder={`Search Clients`}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleClientSearch}
            />
            <Button
              type="primary"
              className="client-view__search-btn"
              onClick={handleClientSearch}
            >
              Search
            </Button>
          </div>

          <div>
            or <a onClick={handleViewChange('create')}>Create new Client</a>
          </div>
          <div className="client-view__search-results-wrapper">
            {isSearchErrorMessageVisible && (
              <p className="client-view__search-no-result">No clients found.</p>
            )}
            {clients &&
              clients?.map((client) => (
                <ClientCard
                  key={client.clientId}
                  client={client}
                  isPlanDuplicateView={isPlanDuplicateView}
                  setClientForPlanDuplicate={setClientForPlanDuplicate}
                  planDuplicateStep={planDuplicateStep}
                  setPlanDuplicateStep={setPlanDuplicateStep}
                />
              ))}
          </div>
        </>
      )}
      {view === 'create' && (
        <CreateClient
          handleViewChange={handleViewChange}
          isPlanDuplicateView={isPlanDuplicateView}
          setClientForPlanDuplicate={setClientForPlanDuplicate}
          setPlanDuplicateStep={setPlanDuplicateStep}
        />
      )}
    </div>
  );
};

export default Client;
