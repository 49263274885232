import { Buffer } from 'buffer';
import CryptoES from 'crypto-es';

import envConstant from '../../internals/env/env_constants.json';
import { getItemFromStorage } from './storageHandler';

const b64ToUTF8 = (str: string) => {
  return Buffer.from(str, 'base64').toString('utf8');
};

export const getPassKeyAndIV = () => {
  const key = Buffer.from(envConstant.DEFAULT_PASS_KEY, 'base64').toString(
    'utf8',
  );

  const iv = Buffer.from(envConstant.DEFAULT_PASS_IV, 'base64').toString(
    'utf8',
  );

  return [key, iv];
};

export const encryptData = (data: any, keyIV?: any[]) => {
  const fixedData = typeof data === 'string' ? data : JSON.stringify(data);
  if (keyIV) {
    // for custom Key and IV
    const encrypted = CryptoES.AES.encrypt(
      fixedData,
      CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[0])),
      {
        // @ts-ignore
        iv: CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[1])),
        format: CryptoES.format.Hex,
        mode: CryptoES.mode.CBC,
        padding: CryptoES.pad.Pkcs7,
      },
    );

    return encrypted.toString();
  }
  const passKeyIV = getPassKeyAndIV();
  const encrypted = CryptoES.AES.encrypt(
    typeof data === 'string' ? data : JSON.stringify(data),
    CryptoES.enc.Utf8.parse(passKeyIV[0]),
    {
      iv: CryptoES.enc.Utf8.parse(passKeyIV[1]),
      mode: CryptoES.mode.CBC,
    },
  );

  return encrypted.toString();
};

export const decryptData = (data: any, keyIV?: any[]) => {
  if (keyIV) {
    // for custom Key and IV
    const decrypted = CryptoES.AES.decrypt(
      data,
      CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[0])),
      {
        iv: CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[1])),
        format: CryptoES.format.Hex,
        mode: CryptoES.mode.CBC,
        padding: CryptoES.pad.Pkcs7,
      },
    );
    return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
  }

  const passKeyIV = getPassKeyAndIV();
  const decrypted = CryptoES.AES.decrypt(
    data,
    CryptoES.enc.Utf8.parse(passKeyIV[0]),
    {
      iv: CryptoES.enc.Utf8.parse(passKeyIV[1]),
      mode: CryptoES.mode.CBC,
    },
  );

  return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
};

export const getClientId = (clientId?: string | undefined) => {
  return clientId || getSessionClientID();
};

export const getSessionClientID = () => {
  const userAgent = window.navigator.userAgent;
  const clientIp = getItemFromStorage('client_ip');

  return encryptData(`${userAgent}${clientIp ? '/' + clientIp : ''}`);
};

export const decryptResponseMessage = (responseData: string, key: string) => {
  if (responseData) {
    const decryptedRes = decryptData(responseData);

    if (decryptedRes && (decryptedRes[key] || decryptedRes.responseMessage)) {
      return decryptedRes[key] || decryptedRes.responseMessage;
    }
    return '';
  }
};
