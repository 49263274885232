import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../../foundation/components/form_modal/FormModal';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { updateStampDuty } from '../redux/async_thunks';

const DutyTransferTable = ({
  dataSource,
  isLoading,
  state,
}: {
  dataSource: [];
  isLoading: boolean;
  state: string;
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const titlesToBold: string[] = [];

  const ColumnValueRenderer = (symbol) => (text: string, record: any) => {
    return colValueRenderer(
      text,
      record,
      symbol,
      titlesToBold,
      'lowerLimitAmount',
    );
  };

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Over -',
      dataIndex: 'lowerLimitAmount',
      key: 'lowerLimitAmount',
      ellipsis: true,
      render: ColumnValueRenderer('$'),
    },
    {
      title: 'But Not Over',
      dataIndex: 'upperLimitAmount',
      key: 'upperLimitAmount',
      ellipsis: true,
      render: ColumnValueRenderer('$'),
    },
    {
      title: 'Tax Rate',
      dataIndex: 'taxRate',
      key: 'taxRate',
      ellipsis: true,
      render: ColumnValueRenderer('%'),
    },
    {
      title: 'Part Value For Surplus',
      dataIndex: 'surplusPartValueAmount',
      key: 'surplusPartValueAmount',
      ellipsis: true,
      render: ColumnValueRenderer('$'),
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'surplusPartValueAmount',
      key: 'action',
      width: 80,
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="l-costs-by-lga__edit-record-btn"
          />
        );
      },
    },
  ];

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Over -',
        name: 'lowerLimitAmount',
        key: 'lowerLimitAmount',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Over - is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'But Not Over',
        name: 'upperLimitAmount',
        key: 'upperLimitAmount',
        type: 'number',
        isRequired: true,
        requiredMessage: 'But Not Over is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Tax Rate',
        name: 'taxRate',
        key: 'taxRate',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Tax Rate is required',
        addonBefore: '%',
        min: 0,
      },
      {
        label: 'Surplus Part Value Amount',
        name: 'surplusPartValueAmount',
        key: 'surplusPartValueAmount',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Surplus Part Value Amount is required',
        addonBefore: '$',
        min: 0,
      },
    ],
    [],
  );

  const onEditRecord = (record: any) => () => {
    setModalData({
      ...record,
      state,
      taxRate: percentageParser(record.taxRate),
    });
    setModalVisibility(true);
  };

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleStampDutyEdit = async (values: any) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          const data = {
            ...values,
            userId: user.user_id,
          };

          await dispatch(
            updateStampDuty({
              data: data,
              token: user?.token,
            }),
            // @ts-ignore
          ).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Stamp Duty`}
          onSubmit={handleStampDutyEdit}
          formFieldsArray={formFieldsArray}
        />
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size={useTableSize()}
        loading={isLoading}
        style={{ marginBottom: 20 }}
        scroll={{ x: true }}
      />
    </>
  );
};

export default DutyTransferTable;
