import * as Yup from 'yup';

import { numberFormatter } from '../../../foundation/utils/helperFunctions';

const requiredMessage = `This field is required`;

export const leverageCashValidationSchema = () => {
  const fields: any = {
    cashSource: Yup.string().trim().required(requiredMessage).nullable(),
    amount: Yup.number().required(requiredMessage).nullable(),
    use: Yup.number()
      .min(0)
      .when('maxUsableAmount', (maxUsableAmount, schema) => {
        return schema.max(
          maxUsableAmount,
          `You can't use more than ${numberFormatter(maxUsableAmount, '$')}`,
        );
      })
      .nullable(),
  };

  return Yup.object().shape(fields);
};

export const leverageEquityValidationSchema = () => {
  const fields: any = {
    equity: Yup.string().trim().required(requiredMessage).nullable(),
    product: Yup.string().trim().nullable(),
    redrawLoanOption: Yup.string().trim().required(requiredMessage).nullable(),
    amountAvailable: Yup.number().required(requiredMessage).nullable(),
    actualRefinancingMaxLvr: Yup.number().required(requiredMessage).nullable(),
    loanType: Yup.string().when('redrawLoanOption', {
      is: 'Split Loan',
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().optional().nullable(),
    }),
    loanTerm: Yup.number().when('redrawLoanOption', {
      is: 'Split Loan',
      then: Yup.number().required(requiredMessage).nullable(),
      otherwise: Yup.number().optional().nullable(),
    }),
    // lvr: Yup.number().when('redrawLoanOption', {
    //   is: 'Split Loan',
    //   then: Yup.number().required(requiredMessage).nullable(),
    //   otherwise: Yup.string().optional().nullable(),
    // }),
    loanRate: Yup.number().when('redrawLoanOption', {
      is: 'Split Loan',
      then: Yup.number().required(requiredMessage).nullable(),
      otherwise: Yup.number().optional().nullable(),
    }),
    switchBackRate: Yup.number().when('product', {
      is: 'I/O',
      then: Yup.number().when('redrawLoanOption', {
        is: 'Split Loan',
        then: Yup.number().required(requiredMessage).nullable(),
        otherwise: Yup.number().optional().nullable(),
      }),
      otherwise: Yup.number().optional().nullable(),
    }),
    switchBackTerm: Yup.number()
      .min(0, 'Must be greater than or equal to 0')
      .when(
        ['loanTerm'],
        // @ts-ignore
        (loanTerm, schema) => {
          return schema.max(
            loanTerm - 1,
            `Value should be less than ${loanTerm}`,
          );
        },
      ),
    redraw: Yup.number()
      .min(0)
      .when(
        ['amountAvailable'],
        // @ts-ignore
        (amountAvailable, schema) => {
          const value = amountAvailable;

          return schema.max(
            value,
            `You can't use more than ${numberFormatter(value, '$')}`,
          );
        },
      )
      .nullable(),
  };

  return Yup.object().shape(fields);
};
