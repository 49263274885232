import { Layout } from 'antd';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { RouteType } from '../../../foundation/config/routes';

const { Content } = Layout;

type Props = {
  children: ReactNode;
  routeConfig: RouteType | undefined;
};

const ContentWrapper = ({ children, routeConfig }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [pathname, contentRef]);

  return (
    <Content className="c-app-layout__content" ref={contentRef}>
      {routeConfig?.displayHeader && (
        <div className="l-content-header">
          <div className="l-content-header__title">
            {routeConfig?.headerTitle}
          </div>
          <div className="l-content-header__subtitle">
            {routeConfig?.headerSubTitle}
          </div>
        </div>
      )}

      <div>{children}</div>
    </Content>
  );
};

export default ContentWrapper;
