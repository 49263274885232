import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */

export const selectClientDashboardCashflowResult = (state: RootState) =>
  state.client_dashboard?.values?.cashFlowResult;

export const selectClientDashboardAssetResult = (state: RootState) =>
  state.client_dashboard?.values?.assetResult;

export const selectClientDashboardNextPurchase = (state: RootState) =>
  state.client_dashboard?.values?.nextPurchaseStats;

export const selectClientDashboardNumberOfProperties = (state: RootState) =>
  state.client_dashboard?.values?.numberOfProperties;

export const selectClientDashboardPortfolioValue = (state: RootState) =>
  state.client_dashboard?.values?.portfolioValue;

export const selectClientDashboardTotalDebt = (state: RootState) =>
  state.client_dashboard?.values?.totalDebt;

export const selectClientDashboardTotalEquity = (state: RootState) =>
  state.client_dashboard?.values?.totalEquity;

export const selectClientDashboardPortfolioPerformance = (state: RootState) =>
  state.client_dashboard?.values?.proPortfolioPerformance
    ?.portfolioPerformanceDetails;

export const selectClientDashboardAnnualCashflowProjectionData = (
  state: RootState,
) => state.client_dashboard?.values?.annualCashFlow[0]?.data;

export const selectClientDashboardMonthlyCashflowProjectionData = (
  state: RootState,
) => state.client_dashboard?.values?.monthlyCashFlow[0]?.data;

export const selectClientDashboardEquityForecast = (state: RootState) =>
  state.client_dashboard?.values?.equityGrowth[0]?.data;

export const selectClientDashboardAboutProperties = (state: RootState) =>
  state.client_dashboard?.values?.aboutProperties?.aboutPropertiesDetails;

export const selectClientDashboardPurchaseRoadmap = (state: RootState) =>
  state.client_dashboard?.values?.propertyPurchaseRoadmap[0]?.data;

export const selectClientDashboardPurchaseRoadmapAxisData = (
  state: RootState,
) => state.client_dashboard?.values?.propertyPurchaseRoadmap[0]?.axis;

export const selectClientDashboardPlanTargetCalendarYear = (state: RootState) =>
  state.client_dashboard?.values?.planTargetCalendarYear;

export const selectClientDashboardPlans = (state: RootState) =>
  state.client_dashboard?.client_plans;

export const selectClientDashboardSelectedPlan = (state: RootState) =>
  state.client_dashboard?.values?.planId;

export const selectClientDashboardSelectedClient = (state: RootState) =>
  state.client_dashboard?.client_id;

export const selectClientDashboardSelectedClientName = (state: RootState) =>
  state.client_dashboard?.client_name;

export const selectClientDashboardSelectedClientPhoto = (state: RootState) =>
  state.client_dashboard?.client_photo;

export const selectClientDashboardReportToken = (state: RootState) =>
  state.client_dashboard?.report_token;

export const selectClientDashboardCurrentTimelineYears = (state: RootState) =>
  state.client_dashboard?.timeline_years;

export const selectClientDashboardTimelineTypes = (state: RootState) =>
  state.client_dashboard?.timeline_types;

export const selectClientDashboardLinkData = (state: RootState) =>
  state.client_dashboard?.link_data;
