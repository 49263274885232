import { createSlice } from '@reduxjs/toolkit';

import { fetchCostsByLGA, updateCostsByLGA } from './async_thunks';
import { CostsByLGAState } from './types';

const initialState: CostsByLGAState = {
  list: [],
};

export const nftSlice = createSlice({
  name: 'costs_by_lga',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCostsByLGA.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(updateCostsByLGA.fulfilled, (state, action) => {
        state.list = action.payload;
      });
  },
});

export default nftSlice.reducer;
