import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useRouteConfig from '../../foundation/cutom_hooks/useRouteConfig';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import { selectUser } from '../authentication/redux/selectors';
import ContentWrapper from './content_wrapper/ContentWrapper';
import Header from './header/Header';
import Sider from './sider/Sider';

type Props = {
  children: ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const { isDesktopViewport } = useViewport();
  const location = useLocation();

  const routeConfig = useRouteConfig(location.pathname);

  const user = useSelector(selectUser);

  /**
   * Hide the layout if the user is not available.
   */
  if (!user) {
    return children;
  }

  return (
    <Layout className="c-app-layout">
      <Header routeConfig={routeConfig}>Header</Header>
      {routeConfig?.displaySidebar && (
        <Layout
          style={{
            padding: isDesktopViewport ? '25px' : '15px',
            position: 'relative',
          }}
        >
          <Sider routeConfig={routeConfig} />
          <ContentWrapper routeConfig={routeConfig}>{children}</ContentWrapper>
        </Layout>
      )}
      {!routeConfig?.displaySidebar && <>{children}</>}
    </Layout>
  );
};

export default AppLayout;
