import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import AuthenticationPageImage from '../elements/AuthenticationPageImage';
import ClientAuthenticationForm from '../forms/ClientAuthenticationForm';
import { clientLogin } from '../redux/async_thunks';

const Login = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isLoginFormLoading, setLoginFormLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    try {
      setLoginFormLoading(true);
      const data = { ...values };
      // @ts-ignore
      const response = await dispatch(clientLogin({ values: data })).unwrap();
      setLoginFormLoading(false);
      navigate('/plan');
    } catch (error) {
      setLoginFormLoading(false);
    }
  };

  return (
    <>
      <div className="client-dash-login">
        <ClientAuthenticationForm
          handleFormSubmit={handleFormSubmit}
          isFormLoading={isLoginFormLoading}
          formType="login"
        />
        <AuthenticationPageImage />
      </div>
    </>
  );
};

export default Login;
