import { Button, DatePicker, Modal, notification } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { selectUser } from '../../../features/authentication/redux/selectors';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../store/hooks';
import { selectPlan } from '../../plan/redux/selectors';
import { createNewOffsetAccount } from '../redux/async_thunks';
import PropertyTypeValidationSchema from './validation_schema';

type Props = {
  closeModal: () => any;
};

const CreateOffsetAccountModal = ({ closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const plan = useSelector(selectPlan);

  const modalOpts = {
    title: 'Create New Offset Account',
    open: true,
    onCancel: () => {
      closeModal();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);

      if (user?.token && plan?.planId) {
        const data = {
          transactionDate: values.transactionDate.format('MM-YYYY'),
          userId: user.user_id,
          planId: plan?.planId,
        };

        const response = await dispatch(
          createNewOffsetAccount({ token: user.token, values: data }),
          // @ts-ignore
        ).unwrap();

        notification.success({
          message: 'Success!',
          description: 'Offset account created successfully',
        });

        setIsLoading(false);
        closeModal();

        if (response) {
          navigate(`/oa-transactions/${response.offsetAccountId}`);
        }
      }
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <Modal {...modalOpts} className="c-form-modal">
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          transactionDate: undefined,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={PropertyTypeValidationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="c-form-field">
                <div className="c-form-field__label">Date</div>
                <div className="c-form-field__wrapper">
                  {/* @ts-ignore */}
                  <DatePicker
                    picker="month"
                    format={'MM-YYYY'}
                    className="c-date-field"
                    value={values.transactionDate}
                    onChange={(v) => {
                      setFieldValue('transactionDate', v);
                    }}
                  />
                </div>
              </div>
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Create
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateOffsetAccountModal;
