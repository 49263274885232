import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import { colValueRenderer } from '../../foundation/utils/helperFunctions';
import { YearlySavingsRequired } from './redux/types';

/**
 * Form fields
 */
export const yearlySavingsFormFieldsArray: FieldType[] = [
  {
    label: 'Growth',
    name: 'growth',
    key: 'growth',
    type: 'number',
    isRequired: true,
    requiredMessage: 'Growth is required',
    addonBefore: '$',
    min: 0,
  },
  {
    label: 'Balanced',
    name: 'balanced',
    key: 'balanced',
    type: 'number',
    isRequired: true,
    requiredMessage: 'Balanced is required',
    addonBefore: '$',
    min: 0,
  },
  {
    label: 'Cashflow',
    name: 'cashFlow',
    key: 'cashFlow',
    type: 'number',
    isRequired: true,
    requiredMessage: 'Cashflow is required',
    addonBefore: '$',
    min: 0,
  },
];

type FocusGrowthTableProps = {
  isLoading: boolean;
  titlesToBold: string[];
  dataSource: YearlySavingsRequired[];
  onEdit: (values: any) => void;
  setFormType: React.Dispatch<React.SetStateAction<'focus' | 'yearly_saving'>>;
};
const FocusSavingsTable = ({
  titlesToBold,
  dataSource,
  isLoading,
  onEdit,
  setFormType,
}: FocusGrowthTableProps) => {
  const onEditRecord = (record: YearlySavingsRequired) => () => {
    setFormType('yearly_saving');
    onEdit({
      ...record,
    });
  };

  /**
   * Data table's columns.
   */
  const columns: ColumnsType<YearlySavingsRequired> = [
    {
      title: 'Growth',
      dataIndex: 'growth',
      key: 'growth',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'year');
      },
    },
    {
      title: 'Balanced',
      dataIndex: 'balanced',
      key: 'balanced',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'year');
      },
    },
    {
      title: 'Cashflow',
      dataIndex: 'cashFlow',
      key: 'cashFlow',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'year');
      },
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: '',
      key: '',
      width: 80,
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="l-costs-by-lga__edit-record-btn"
          />
        );
      },
    },
  ];
  return (
    <div className="l-focus__table-wrapper">
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        size={useTableSize()}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default FocusSavingsTable;
