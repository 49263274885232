import { Avatar, Card } from 'antd';
import React from 'react';
import CountUp from 'react-countup';

type Props = {
  icon: React.ReactNode;
  formatNumber?: boolean;
  label: string;
  background?: string;
  color?: string;
  stats?: string;
};

const StatsCard = ({
  icon,
  formatNumber,
  label,
  stats,
  background,
  color,
}: Props) => {
  return (
    <Card className="dashboard__card dashboard__card--stats-card">
      <Avatar
        size={40}
        icon={icon}
        style={{ marginBottom: 10, background, color }}
        className="dashboard__stats-card-icon-wrapper"
      />
      {formatNumber && (
        <div className="dashboard__stats-card-num-wrapper">
          {stats?.toString().substring(0, 1)}
          <CountUp
            end={
              stats
                ? +parseFloat(
                    stats.toString().substring(1, stats.length - 1),
                  ).toFixed(2)
                : 0
            }
            duration={1}
            decimals={2}
          />

          {stats?.toString().substring(stats.length - 1, stats.length)}
        </div>
      )}
      {!formatNumber && (
        <div className="dashboard__stats-card-num-wrapper">
          {<CountUp end={stats ? parseInt(stats) : 0} duration={1} />}
        </div>
      )}
      <div className="dashboard__stats-card-label-wrapper">{label}</div>
    </Card>
  );
};

export default StatsCard;
