import React, { useMemo } from 'react';

import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../../foundation/components/form_modal/FormModal';

type Props = {
  visible?: true | false;
  closeModal: () => any;
  modalData: any;
  onSubmit: (v: any) => any;
  modalTitle: string;
  formFieldsArray: FieldType[];
  validationSchema?: any;
  isDraggable?: boolean;
};

const RecordModal = ({
  visible = true,
  closeModal,
  modalData,
  modalTitle,
  formFieldsArray,
  onSubmit,
  validationSchema,
  isDraggable,
}: Props) => {
  const fields = useMemo(() => {
    const arr: FieldType[] = [];

    for (const i of formFieldsArray) {
      if (
        modalData.editableFields &&
        modalData.editableFields.indexOf(i.name) > -1
      ) {
        i.isRequired = true;
        i.disabled = false;
        arr.push(i);
      } else {
        i.isRequired = false;
        i.disabled = true;
        arr.push(i);
      }
    }
    return arr;
  }, [modalData, formFieldsArray]);

  return (
    <FormModal
      visible={visible}
      closeModal={closeModal}
      modalData={modalData}
      modalTitle={modalTitle}
      onSubmit={onSubmit}
      formFieldsArray={fields}
      validationSchema={validationSchema}
      isDraggable={isDraggable}
    />
  );
};
export default RecordModal;
