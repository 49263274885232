import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../../foundation/utils/helperFunctions';
import { InterestRate } from '../redux/types';

type InterestRateProjectionsTableProps = {
  isLoading: boolean;
  titlesToBold: string[];
  dataSource: InterestRate[];
  onEdit: (values: any) => void;
};
const InterestRateProjectionsTable = ({
  titlesToBold,
  dataSource,
  isLoading,
  onEdit,
}: InterestRateProjectionsTableProps) => {
  const onEditRecord = (record: InterestRate) => () => {
    onEdit({
      ...record,
      interestRate: percentageParser(record.interestRate),
    });
  };

  /**
   * Data table's columns.
   */
  const columns: ColumnsType<InterestRate> = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, undefined, titlesToBold, 'year');
      },
    },
    {
      title: 'Interest Rate',
      dataIndex: 'interestRate',
      key: 'interestRate',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'year');
      },
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'year',
      key: 'year',
      width: 80,
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="l-costs-by-lga__edit-record-btn"
          />
        );
      },
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        size={useTableSize()}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default InterestRateProjectionsTable;
