import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from '../../authentication/redux/selectors';
import { fetchClientPurchaseReport } from '../redux/async_thunks';

const ClientPurchase = ({ isLoading, setIsLoading }: any) => {
  const MONTH_FORMAT = 'MM/YYYY';
  const MONTH_DISPLAY_FORMAT = 'MMMM YYYY';

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { MonthPicker } = DatePicker;

  const currentMonth = dayjs(); // Get current month
  const [selectedMonth, setSelectedMonth] = useState<string>(
    currentMonth.format(MONTH_DISPLAY_FORMAT),
  );

  const downloadReport = async () => {
    setIsLoading(true);

    const dateObject = dayjs(selectedMonth, MONTH_DISPLAY_FORMAT).toDate();
    const formattedDate = dayjs(dateObject).format(MONTH_FORMAT);

    try {
      if (user?.token) {
        const data = {
          userId: user.user_id,
          reportMonth: formattedDate,
        };

        await dispatch(
          fetchClientPurchaseReport({
            token: user.token,
            data: data,
            format: MONTH_FORMAT,
          }),
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onChange = (date: any) => {
    if (date) {
      setSelectedMonth(dayjs(date).format(MONTH_DISPLAY_FORMAT));
    }
  };

  return (
    <>
      <Divider />
      <div className="client-view__create-field-label">Select Month:</div>
      <MonthPicker
        onChange={onChange}
        value={dayjs(selectedMonth, MONTH_DISPLAY_FORMAT)}
        format={MONTH_DISPLAY_FORMAT}
        allowClear={false}
      />
      <br />
      <Button
        type="primary"
        className="client-view__create-client-btn"
        disabled={isLoading}
        onClick={downloadReport}
        style={{ margin: 0 }}
      >
        <DownloadOutlined /> Download
      </Button>
    </>
  );
};

export default ClientPurchase;
