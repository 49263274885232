import 'chartjs-adapter-moment';

import { Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LineGraph from '../../../foundation/components/line_graph/LineGraph';
import { selectDashboardEquityForecast } from '../redux/selectors';

const EquityGrowthSection = () => {
  const equityGraphData = useSelector(selectDashboardEquityForecast);

  /**
   * X-axis labels.
   */
  const labels = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => moment(item.timeline))
      : [];
  }, [equityGraphData]);

  /**
   * Formatted data for net cashflow graph.
   */
  const graphData = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => ({
          y: item.availableEquity,
          x: moment(item.timeline),
        }))
      : [];
  }, [equityGraphData]);

  const graphPortfolioData = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => ({
          y: item.portfolioValue,
          x: moment(item.timeline),
        }))
      : [];
  }, [equityGraphData]);

  const graphTotalEquityData = useMemo(() => {
    return equityGraphData
      ? equityGraphData.map((item) => ({
          y: item.totalEquity,
          x: moment(item.timeline),
        }))
      : [];
  }, [equityGraphData]);

  /**
   * Formatted data used by the line graph.
   */
  const data = {
    labels,
    datasets: [
      {
        label: 'Portfolio Value',
        data: graphPortfolioData,
        segment: {},
        borderColor: 'rgba(255, 147, 76,0.7)',
        backgroundColor: 'rgba(255, 147, 76,0.7)',
        color: 'rgba(0,0,0,1)',
        pointBorderColor: 'transparent',
        pointRadius: 2,
        fill: {
          target: 'origin',
          below: 'rgba(255, 77, 79, 0.2)', // Area will be red above the origin
          above: 'rgba(255, 147, 76,0.2)',
        },
      },
      {
        label: 'Total Equity',
        data: graphTotalEquityData,
        segment: {},
        borderColor: 'rgba(0, 178, 163,0.7)',
        backgroundColor: 'rgba(0, 178, 163,0.7)',
        color: 'rgba(0,0,0,1)',
        pointBorderColor: 'transparent',
        pointRadius: 2,
        fill: {
          target: 'origin',
          below: 'rgba(255, 77, 79, 0.1)', // Area will be red above the origin
          above: 'rgba(0, 178, 163,0.1)',
        },
      },
      {
        label: 'Available Equity (@LVR)',
        data: graphData,
        segment: {},
        borderColor: 'rgba(255, 179, 25, 0.7)',
        backgroundColor: 'rgba(255, 179, 25, 0.7)',
        color: 'rgba(0,0,0,1)',
        pointBorderColor: 'transparent',
        pointRadius: 2,
        fill: {
          target: 'origin',
          below: 'rgba(255, 77, 79, 0.2)', // Area will be red above the origin
          above: 'rgba(255, 179, 25, 0.5)',
        },
      },
    ],
  };

  return (
    <Row className="equity-forecast">
      <div className="dashboard__header">
        <div className="dashboard__header-title dashboard__header-title--medium">
          Equity Growth
        </div>
      </div>
      <LineGraph
        xAxesLabel="Graph Timeline"
        yAxesLabel="Equity"
        data={data}
        graphTitle="Equity Growth Timeline"
        areaAboveFill="rgba(255, 179, 25, 0.2)"
        displayLegend={true}
      />
    </Row>
  );
};

export default EquityGrowthSection;
