import { Card, Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { numberFormatter } from '../../../foundation/utils/helperFunctions';
import { selectDashboardNextPurchase } from '../redux/selectors';

const NextPurchaseCard = () => {
  const nextPurchase = useSelector(selectDashboardNextPurchase);
  return (
    <Card className="dashboard__card dashboard__card--no-padding dashboard__card--full-height">
      <div className="dashboard__np-header">Next Purchase</div>
      <Row justify="space-between" className="dashboard__np-row">
        <Col>Funds Required for {nextPurchase?.nextProperty}</Col>
        <Col
          className="dashboard__np-amount-wrapper"
          style={{
            color: '#40A9FF',
            backgroundColor: '#E6F7FF',
          }}
        >
          {numberFormatter(nextPurchase?.fundsRequired, '$')}
        </Col>
      </Row>
      <Row justify="space-between" className="dashboard__np-row">
        <Col>Current Equity Available</Col>
        <Col
          className="dashboard__np-amount-wrapper"
          style={{
            color: '#FF4D4F',
            backgroundColor: '#FFF1F0',
          }}
        >
          {numberFormatter(nextPurchase?.availableEquity, '$')}
        </Col>
      </Row>
      <Row justify="space-between" className="dashboard__np-row">
        <Col>Current Cash Available</Col>
        <Col
          className="dashboard__np-amount-wrapper"
          style={{
            color: '#FF7A45',
            backgroundColor: '#FFF2E8',
          }}
        >
          {numberFormatter(nextPurchase?.availableCash, '$')}
        </Col>
      </Row>
      <Row justify="space-between" className="dashboard__np-row">
        <Col>Shortfall</Col>
        <Col
          className="dashboard__np-amount-wrapper"
          style={{
            color: '#FFC53D',
            backgroundColor: '#FFFBE6',
          }}
        >
          {numberFormatter(nextPurchase?.shortfall, '$')}
        </Col>
      </Row>
    </Card>
  );
};

export default NextPurchaseCard;
