import React from 'react';

function TwoBars() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      fill="none"
      viewBox="0 0 20 14"
    >
      <path
        fill="#FFC53D"
        d="M.063.25v5.625h19.875V.25H.063zm18.75 4.5H1.188V1.375h17.625V4.75zm-18.75 9h19.875V8.125H.063v5.625zm1.125-4.5h17.625v3.375H1.188V9.25z"
      ></path>
    </svg>
  );
}

export default TwoBars;
