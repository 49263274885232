import { Editor } from '@tinymce/tinymce-react';
import React, { forwardRef } from 'react';

import env_constants from '../../../internals/env/env_constants.json';

const WysiwygEditor = forwardRef(
  ({ initialValue }: { initialValue: string }, ref: any) => {
    return (
      <>
        <Editor
          apiKey={env_constants.TINYMCE_API_KEY}
          onInit={(evt, editor) => {
            ref.current = editor;
          }}
          initialValue={initialValue}
          init={{
            branding: false,
            height: 380,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat',
            content_style:
              'body { font-family:Inter, Arial,sans-serif; font-size:14px } ' +
              'h1, h2, h3, h4, h5, h6 { font-family: "Inter", "Open Sans", sans-serif; font-weight: 700;' +
              'line-height: 1.3; letter-spacing: 0px; margin-bottom: 0; margin-top: 0; padding-bottom: 20px } ' +
              'h1 { font-size: 45px }' +
              'h2 { font-size: 40px }' +
              'h3 { font-size: 24px }' +
              'h4 { font-size: 21px }' +
              'h5 { font-size: 18px }' +
              'h6 { font-size: 16px }' +
              'p, pre { margin: 0; padding: 0}',
          }}
        />
      </>
    );
  },
);

export default WysiwygEditor;
