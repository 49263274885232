import { Button, Modal, notification } from 'antd';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';

import formFieldRenderer, {
  formFieldsValidationSchema,
} from '../../utils/formUtils';
import FullPageLoader from '../full_page_loader/FullPageLoader.index';
import { FieldType } from './form_modal_types';

type Props = {
  visible?: true | false;
  closeModal: () => any;
  modalData?: any;
  onSubmit: (v: any) => any;
  modalTitle: string;
  formFieldsArray: FieldType[];
  validationSchema?: any;
  isDraggable?: boolean;
};

const FormModal = ({
  visible = true,
  closeModal,
  modalData,
  modalTitle,
  formFieldsArray,
  onSubmit,
  validationSchema,
  isDraggable,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isDragginDisabled, disableDraggging] = useState(true);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef<HTMLDivElement | null>(null);

  const modalOpts = {
    open: visible,
    onCancel: () => {
      if (!isLoading) {
        closeModal();
      }
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    title: isDraggable ? (
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (isDragginDisabled) {
            disableDraggging(false);
          }
        }}
        onMouseOut={() => {
          disableDraggging(true);
        }}
      >
        {modalTitle}
      </div>
    ) : (
      modalTitle
    ),
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await onSubmit(values);
      setIsLoading(false);
      closeModal();

      notification.success({
        message: 'Success!',
        description: 'Record updated successfully',
      });
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Modal
      {...modalOpts}
      className="c-form-modal"
      modalRender={(modal) => {
        if (isDraggable) {
          return (
            <Draggable
              disabled={isDragginDisabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          );
        } else {
          return modal;
        }
      }}
    >
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          ...modalData,
        }}
        validationSchema={
          validationSchema
            ? validationSchema
            : formFieldsValidationSchema(formFieldsArray)
        }
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {formFieldRenderer(
                handleChange,
                values,
                errors,
                handleBlur,
                formFieldsArray,
                setFieldValue,
              )}
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Update
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default FormModal;
