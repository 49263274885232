import { notification } from 'antd';
import { Buffer } from 'buffer';
import randomstring from 'randomstring';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import OTPView from '../../../foundation/components/otp_view/OTPView';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import { clientForgotPass, clientResetPass } from '../redux/async_thunks';

const ResetPassword = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formType, setFormType] = useState<'forgot' | 'reset'>('forgot');

  const [isResetPassFormLoading, setIsResetPassFormLoading] = useState(false);

  const [email, setEmail] = useState<string | undefined>(undefined);

  const [OTP, setOTP] = useState('');

  const [isOTPViewVisible, setOTPViewVisibility] = useState(false);

  const handleForgotPasswordFormSubmit = async (values) => {
    if (isResetPassFormLoading) {
      return;
    }
    try {
      setIsResetPassFormLoading(true);

      setEmail(values.email);

      const reqData = {
        email: values.email,
      };

      // @ts-ignore
      const resp = await dispatch(clientForgotPass({ data: reqData })).unwrap();

      setIsResetPassFormLoading(false);
      setOTPViewVisibility(true);
    } catch (error) {
      setIsResetPassFormLoading(false);
    }
    setEmail(values.email);
    setOTPViewVisibility(true);
  };

  const handleResetPassword = async (values) => {
    if (isResetPassFormLoading) {
      return;
    }

    try {
      if (email) {
        setIsResetPassFormLoading(true);

        const passSuffix = randomstring.generate(20);
        const passPrefix = randomstring.generate(20);

        const appendedPass = `${passPrefix}${values.newPassCode}${passSuffix}`;

        const encodedPass = Buffer.from(appendedPass).toString('base64');

        const data = {
          email,
          otp: OTP,
          newPassCode: encodedPass,
        };

        // @ts-ignore
        await dispatch(clientResetPass({ data })).unwrap();

        setIsResetPassFormLoading(false);

        notification.success({
          message: 'Success!',
          description: 'Password reset successfully.',
        });

        navigate('client/login');
      }
    } catch (error) {
      console.log(error);
      setIsResetPassFormLoading(false);
    }
  };

  const handleOTPCancel = () => {
    setEmail(undefined);
    setOTPViewVisibility(false);
    setFormType('forgot');
  };

  const handleOTPSubmit = (otp: string) => {
    setOTP(otp);
    setFormType('reset');
    setOTPViewVisibility(false);
  };

  const handleResendOTP = () => {
    const reqData = {
      email,
    };

    // @ts-ignore
    return dispatch(clientForgotPass({ data: reqData })).unwrap();
  };

  return (
    <>
      {isOTPViewVisible && (
        <OTPView
          email={email || ''}
          handleCancel={handleOTPCancel}
          onSubmit={handleOTPSubmit}
          handleResendOTP={handleResendOTP}
        />
      )}

      <div className="l-reset-pass">
        <div className="l-reset-pass__content-wrapper">
          <div className="l-reset-pass__app-name">Portfolio Growth Plan</div>
          <div className="l-reset-pass__form-wrapper">
            <ResetPasswordForm
              formType={formType}
              isFormLoading={isResetPassFormLoading}
              handleFormSubmit={
                formType === 'forgot'
                  ? handleForgotPasswordFormSubmit
                  : handleResetPassword
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
