import { EditOutlined } from '@ant-design/icons';
import { Col, DatePicker, Row, Table, Tabs } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import SoldLabel from '../../foundation/components/sold_label/SoldLabel';
import { useRole } from '../../foundation/cutom_hooks/useRole';
import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import FinancialProjections from '../financial_projections/FinancialProjections';
import RecordModal from '../property/record_modal/RecordModal';
import { fetchPerformanceProjectionGraphData } from '../property/redux/async_thunks';
import {
  resetCashflowProjection,
  resetEequityForecast,
  resetMonthlyModeling,
} from '../property/redux/slice';
import {
  fetchMonthlyModelingByDataView,
  updateMonthlyModelingGrossIncome,
  updateMonthlyModelingInterestRate,
  updateMonthlyModelingPurchaseValue,
} from './redux/async_thunks';
import {
  selectMonthlyModeling,
  selectMonthlyModelingColumns,
  selectMonthlyModelingContractDate,
  selectMonthlyModelingInterestRateForYearDisplayed,
  selectMonthlyModelingQuaterlyColumns,
  selectMonthlyModelingStartMonth,
  selectMonthlyModelingYearDisplayed,
  selectMonthlyModelingYearlyColumns,
  selectPropMonthlyModelingIsSold,
  selectQuaterlyModeling,
  selectYearlyModeling,
} from './redux/selectors';

const { TabPane } = Tabs;

type Props = {
  contractDate: string | undefined;
  yearDisplayed: string | undefined;
  year: Dayjs | undefined | null;
  isPropertySold?: boolean;
  handleYearChange: (v: Dayjs | null, date: string) => any;
};

const MonthlyModelingHeader = ({
  contractDate,
  year,
  handleYearChange,
  yearDisplayed,
  isPropertySold,
}: Props) => {
  return (
    <div className="monthly-modeling__header">
      <Row align="middle" gutter={[10, 0]}>
        <Col xs={24} md={12}>
          <div className="monthly-modeling__header-title">
            Monthly Modeling {isPropertySold && <SoldLabel />}
          </div>
        </Col>
        <Col xs={24} md={7} className="h-text-align">
          Year:
        </Col>
        <Col xs={24} md={5}>
          <DatePicker
            disabledDate={(v: Dayjs) => {
              return v < dayjs(contractDate);
            }}
            onChange={handleYearChange}
            picker="year"
            value={year ? year : dayjs(yearDisplayed?.toString())}
            className="plan-view__date-field"
          />
        </Col>
      </Row>
    </div>
  );
};

const MonthlyModeling = () => {
  const { id: propertyId }: any = useParams();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [isFPLoading, setIsFPLoading] = useState(true);

  const [graphTimeline, setGraphTimeline] = useState('10');

  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState<any>(undefined);

  const [modalMetaData, setModalMetaData] = useState({
    title: '',
    type: '',
  });

  const [year, setYear] = useState<Dayjs | undefined | null>(undefined);

  const [dataView, setDataView] = useState<'m' | 'a' | 'q'>('m');

  const user = useSelector(selectUser);

  const isClientView = useRole();

  const monthlyModeling = useSelector(selectMonthlyModeling);

  const quaterlyModeling = useSelector(selectQuaterlyModeling);

  const yearlyModeling = useSelector(selectYearlyModeling);

  const startMonth = useSelector(selectMonthlyModelingStartMonth);

  const yearDisplayed = useSelector(selectMonthlyModelingYearDisplayed);

  const contractDate = useSelector(selectMonthlyModelingContractDate);

  const monthlyColumns = useSelector(selectMonthlyModelingColumns);

  const quaterlyColumns = useSelector(selectMonthlyModelingQuaterlyColumns);

  const yearlyColumns = useSelector(selectMonthlyModelingYearlyColumns);

  const isPropertySold = useSelector(selectPropMonthlyModelingIsSold);

  const interestRate = useSelector(
    selectMonthlyModelingInterestRateForYearDisplayed,
  );

  const titlesToBold = [
    'Property Value',
    'Loan Amount',
    'Principal Payments',
    'Equity Total',
    'Equity Available',
    'Gross Income',
    'Cash Deductions',
    'Net Monthly Cashflow',
  ];

  const fetchInitialData = async (
    reset?: boolean,
    passedYear?: Dayjs | null,
    dataView?: 'm' | 'a' | 'q',
  ) => {
    try {
      setIsLoading(true);
      if (user?.token) {
        if (
          (dataView === 'm' && !monthlyModeling) ||
          (dataView === 'q' && !quaterlyModeling) ||
          (dataView === 'a' && !yearlyModeling) ||
          reset
        ) {
          let yearValue = '';

          if (passedYear) {
            yearValue = dayjs(passedYear).format('YYYY');
          } else if (year) {
            yearValue = dayjs(year).format('YYYY');
          } else {
            yearValue = dayjs().format('YYYY');
          }

          await dispatch(
            fetchMonthlyModelingByDataView({
              token: user.token,
              propertyId,
              year: yearValue,
              dataView: dataView ? dataView : 'm',
            }),
            // @ts-ignore
          ).unwrap();
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchInitialFPData = async (
    passedTimeline?: string,
    dataView?: 'm' | 'a' | 'q',
  ) => {
    try {
      setIsFPLoading(true);
      if (user?.token) {
        const reqDataCashflow = {
          userId: user.user_id,
          propertyId,
          graphType: 'CashflowProjection',
          timelineYears: passedTimeline ? passedTimeline : graphTimeline,
          Dataview: dataView,
        };
        const reqDataEquity = {
          userId: user.user_id,
          propertyId,
          graphType: 'EquityForecast',
          timelineYears: passedTimeline ? passedTimeline : graphTimeline,
          Dataview: dataView,
        };
        const $promises = [
          dispatch(
            fetchPerformanceProjectionGraphData({
              token: user.token,
              data: reqDataCashflow,
              dataView,
            }),
            // @ts-ignore
          ).unwrap(),
          dispatch(
            fetchPerformanceProjectionGraphData({
              token: user.token,
              data: reqDataEquity,
              dataView,
            }),
            // @ts-ignore
          ).unwrap(),
        ];

        await Promise.all($promises);
      }

      setIsFPLoading(false);
    } catch (error) {
      console.log(error);
      setIsFPLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData(true, undefined, dataView);
    fetchInitialFPData(undefined, dataView);
  }, [propertyId]);

  useEffect(() => {
    return () => {
      dispatch(resetMonthlyModeling());
      dispatch(resetCashflowProjection());
      dispatch(resetEequityForecast());
    };
  }, [propertyId]);

  const handleYearChange = (v: Dayjs | null, date: string) => {
    dispatch(resetMonthlyModeling());
    setYear(v);
    fetchInitialData(true, v, dataView);
  };

  const onEditRecord = (record: any) => {
    if (isClientView) {
      return;
    }
    if (record.months === 'Gross Income') {
      setModalMetaData({
        title: `Update Gross Income For ${
          year ? year.format('YYYY') : yearDisplayed
        }`,
        type: 'monthly_modeling',
      });
    } else {
      setModalMetaData({
        title: `Update Purchase Value For ${
          year ? year.format('YYYY') : yearDisplayed
        }`,
        type: 'monthly_modeling',
      });
    }

    setModalData({ ...record });
    setModalVisibility(true);
  };

  const onEditInterestRate = () => {
    if (isClientView) {
      return;
    }
    setModalMetaData({
      title: `Update Interest Rate`,
      type: 'interest_rate',
    });

    const formValues = {
      interestRate: interestRate ? percentageParser(interestRate) : undefined,
      newInterestRate: undefined,
      applyFrom: undefined,
      isEditable: true,
      editableFields: ['newInterestRate', 'applyFrom'],
    };

    setModalData(formValues);
    setModalVisibility(true);
  };

  const recordEditManager = (record) => () => {
    if (isClientView) {
      return;
    }
    if (record.months === 'Interest Rate') {
      onEditInterestRate();
    } else {
      onEditRecord(record);
    }
  };

  /**
   * Data table's columns.
   */
  const columns: any = useMemo(() => {
    if (startMonth || startMonth === '') {
      const cols: any = [
        {
          title: 'Name',
          dataIndex: 'months',
          key: 'months',
          fixed: true,
          render: (text: string, record: any) =>
            colValueRenderer(text, record, undefined, titlesToBold, 'months'),
        },
      ];

      let apiCols: string[] = [];

      if (dataView === 'm' && monthlyColumns) {
        apiCols = monthlyColumns;
      } else if (dataView === 'q' && quaterlyColumns) {
        apiCols = quaterlyColumns;
      } else if (yearlyColumns) {
        apiCols = yearlyColumns;
      }

      const startingIndex = apiCols.indexOf(startMonth);

      for (let i = startingIndex; i < apiCols.length; i++) {
        if (apiCols[i]) {
          cols.push({
            title: apiCols[i].charAt(0).toUpperCase() + apiCols[i].slice(1),
            dataIndex: apiCols[i],
            key: apiCols[i],
            ellipsis: true,
            render: (text: string, record: any) =>
              colValueRenderer(
                text,
                record,
                record.unit,
                titlesToBold,
                'months',
              ),
          });
        }
      }

      cols.push({
        title: <span className="c-action-title">Action</span>,
        dataIndex: 'type',
        key: 'type',
        width: 80,
        fixed: 'right',
        render: (text: any, record: any) => {
          if (record.isEditable) {
            return (
              <EditOutlined
                onClick={recordEditManager(record)}
                className="c-edit-record-btn"
              />
            );
          }
        },
      });

      return cols;
    }
  }, [
    startMonth,
    monthlyColumns,
    quaterlyColumns,
    yearlyColumns,
    dataView,
    year,
  ]);

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Name',
        name: 'months',
        key: 'months',
        type: 'text',
        isRequired: true,
        requiredMessage: 'Name is required',
      },
      {
        label: 'At Purchase',
        name: 'atPurchase',
        key: 'atPurchase',
        type: 'number',
        isRequired: true,
        requiredMessage: 'At Purchase is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'January',
        name: 'january',
        key: 'january',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'February',
        name: 'february',
        key: 'february',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'March',
        name: 'march',
        key: 'march',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'April',
        name: 'april',
        key: 'april',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'May',
        name: 'may',
        key: 'may',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'June',
        name: 'june',
        key: 'june',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'July',
        name: 'july',
        key: 'july',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'August',
        name: 'august',
        key: 'august',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'September',
        name: 'september',
        key: 'september',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'October',
        name: 'october',
        key: 'october',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'November',
        name: 'november',
        key: 'november',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'December',
        name: 'december',
        key: 'december',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Value is required',
        addonBefore: '$',
        min: 0,
      },
    ],
    [],
  );

  const interestRateFieldsArray: FieldType[] = [
    {
      label: 'Current Yearly Interest Rate',
      name: 'interestRate',
      key: 'interestRate',
      type: 'number',
      isRequired: true,
      requiredMessage: 'Value is required',
      addonBefore: '%',
      disabled: true,
    },
    {
      label: 'New Yearly Interest Rate',
      name: 'newInterestRate',
      key: 'newInterestRate',
      type: 'number',
      isRequired: true,
      requiredMessage: 'Value is required',
      addonBefore: '%',
      min: 0,
      max: 100,
    },
    {
      label: 'Apply From',
      name: 'applyFrom',
      key: 'applyFrom',
      type: 'month',
      isRequired: true,
      requiredMessage: 'Value is required',
      format: 'MMMM YYYY',
    },
  ];

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleFormSubmission = (values: any) => {
    if (isLoading || isClientView) {
      return;
    }
    try {
      const data: any = {
        propertyId: propertyId,
        year: yearDisplayed,
        ...values,
      };

      delete data.editableFields;
      delete data.months;
      delete data.unit;
      delete data.atPurchase;
      delete data.isEditable;

      if (user?.token) {
        data.userId = user.user_id;

        if (values.months === 'Gross Income') {
          return dispatch(
            updateMonthlyModelingGrossIncome({
              token: user.token,
              data: data,
              dataView,
            }),
            // @ts-ignore
          ).unwrap();
        } else {
          return dispatch(
            updateMonthlyModelingPurchaseValue({
              token: user.token,
              data: data,
              dataView,
            }),
            // @ts-ignore
          ).unwrap();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInterestFormChange = (values: any) => {
    if (isLoading || isClientView) {
      return;
    }
    try {
      const data: any = {
        propertyId: propertyId,
        currentlyDisplayedYear: yearDisplayed,
        ...values,
        newInterestRate: values.newInterestRate / 100,
      };

      delete data.interestRate;
      delete data.editableFields;
      delete data.isEditable;

      if (user?.token) {
        data.userId = user.user_id;

        return dispatch(
          updateMonthlyModelingInterestRate({
            token: user.token,
            data: data,
            dataView,
          }),
          // @ts-ignore
        ).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = (v: any) => {
    setDataView(v);
    fetchInitialData(undefined, undefined, v);
    fetchInitialFPData(undefined, v);
  };

  const handleGraphTimelineChange = (v: string) => {
    fetchInitialFPData(v, dataView);
    setGraphTimeline(v);
  };

  const modalSubmitHandler = async (values: any) => {
    if (isClientView) {
      return;
    }
    if (modalMetaData.type === 'interest_rate') {
      return handleInterestFormChange(values);
    } else {
      return handleFormSubmission(values);
    }
  };

  const modalFieldsHandler = () => {
    if (modalMetaData.type === 'interest_rate') {
      return interestRateFieldsArray;
    } else {
      return formFieldsArray;
    }
  };

  return (
    <div className="monthly-modeling">
      {isLoading && <FullPageLoader />}
      {/* {isPropertySold && <SoldOverlay />} */}
      {isModalVisible && (
        <RecordModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={modalMetaData.title}
          onSubmit={modalSubmitHandler}
          formFieldsArray={modalFieldsHandler()}
        />
      )}

      <Tabs type="card" onChange={handleTabChange} activeKey={dataView}>
        <TabPane tab="Monthly" key="m">
          <MonthlyModelingHeader
            year={year}
            handleYearChange={handleYearChange}
            contractDate={contractDate}
            yearDisplayed={yearDisplayed}
            isPropertySold={isPropertySold}
          />
          <Table
            columns={columns}
            dataSource={monthlyModeling}
            size={useTableSize()}
            pagination={false}
            scroll={{ x: true }}
          />
          <div style={{ marginTop: 50 }}>
            <FinancialProjections
              dataView="m"
              isLoading={isFPLoading}
              handleTimelineChange={handleGraphTimelineChange}
              timeline={graphTimeline}
            />
          </div>
        </TabPane>
        <TabPane tab="Quarterly" key="q">
          <MonthlyModelingHeader
            year={year}
            handleYearChange={handleYearChange}
            contractDate={contractDate}
            yearDisplayed={yearDisplayed}
            isPropertySold={isPropertySold}
          />
          <Table
            columns={columns}
            dataSource={quaterlyModeling}
            size={useTableSize()}
            pagination={false}
            scroll={{ x: true }}
          />
          <div style={{ marginTop: 50 }}>
            <FinancialProjections
              dataView="q"
              isLoading={isFPLoading}
              handleTimelineChange={handleGraphTimelineChange}
              timeline={graphTimeline}
            />
          </div>
        </TabPane>
        <TabPane tab="Yearly" key="a">
          <MonthlyModelingHeader
            year={year}
            handleYearChange={handleYearChange}
            contractDate={contractDate}
            yearDisplayed={yearDisplayed}
            isPropertySold={isPropertySold}
          />
          <Table
            columns={columns}
            dataSource={yearlyModeling}
            size={useTableSize()}
            pagination={false}
            scroll={{ x: true }}
          />
          <div style={{ marginTop: 50 }}>
            <FinancialProjections
              dataView={dataView}
              isLoading={isFPLoading}
              handleTimelineChange={handleGraphTimelineChange}
              timeline={graphTimeline}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MonthlyModeling;
