import { EditOutlined } from '@ant-design/icons';
import { Col, Row, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../foundation/components/form_modal/FormModal';
import FormSelectField from '../../foundation/components/form_select_field/FormSelectField';
import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import {
  selectedFocusTypeOptions,
  selectPropertyTypeList,
} from '../property/redux/selectors';
import { fetchPropertyTypes, updatePropertyType } from './redux/async_thunks';
import {
  selectPropertyTypesYearRates,
  selectPropertyTypesYearsList,
} from './redux/selectors';
import { PropertyType } from './redux/types';

const PropertyTypes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);
  const [selectedYear, setSelectedYear] = useState<undefined | number>(
    undefined,
  );
  const [dataSource, setDataSource] = useState<PropertyType[]>([]);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const propertyTypeYearRates = useSelector(selectPropertyTypesYearRates);

  const focusTypeOptions = useSelector(selectedFocusTypeOptions);

  const propertyTypes = useSelector(selectPropertyTypeList);

  const yearsList = useSelector(selectPropertyTypesYearsList);

  const titlesToBold: string[] = [];

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Focus',
      dataIndex: 'focus',
      key: 'focus',
      width: 100,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, undefined, titlesToBold, 'focus');
      },
    },
    {
      title: 'Property Type',
      dataIndex: 'propertyType',
      key: 'propertyType',
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, undefined, titlesToBold, 'focus');
      },
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchasePrice',
      key: 'purchasePrice',
      ellipsis: true,
      width: 200,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'Valuation At Purchase',
      dataIndex: 'valuationAtPurchase',
      key: 'valuationAtPurchase',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'Annual Growth Rate',
      dataIndex: 'annualPriceGrowth',
      key: 'annualPriceGrowth',
      width: 160,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'focus');
      },
    },
    {
      title: 'LVR',
      dataIndex: 'lvr',
      key: 'lvr',
      ellipsis: true,
      width: 100,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'focus');
      },
    },
    {
      title: 'Yield',
      dataIndex: 'yield',
      key: 'yield',
      width: 100,
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'focus');
      },
    },
    {
      title: 'Rent/Week',
      dataIndex: 'rentPerWeek',
      key: 'rentPerWeek',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'B&P(per unit)',
      dataIndex: 'buildingPestInspection',
      key: 'buildingPestInspection',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'Maintenance at purchase (per unit)',
      dataIndex: 'maintenanceAtPurchase',
      key: 'maintenanceAtPurchase',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'Conveyancing',
      dataIndex: 'conveyancing',
      key: 'conveyancing',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'Independent Property Valuation',
      dataIndex: 'independentPropertyValuation',
      key: 'independentPropertyValuation',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'Yearly Insurance(per unit)',
      dataIndex: 'yearlyInsurance',
      key: 'yearlyInsurance',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: 'Property Management Letting Fee',
      dataIndex: 'propertyManagementLettingFee',
      key: 'propertyManagementLettingFee',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'focus');
      },
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'name',
      key: 'name',
      width: 80,
      fixed: 'right',
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="property-type__edit-record-btn"
          />
        );
      },
    },
  ];

  const onEditRecord = (record: any) => () => {
    setModalData({
      ...record,
      lvr: percentageParser(record?.lvr),
      yield: percentageParser(record?.yield),
      annualPriceGrowth: percentageParser(record?.annualPriceGrowth),
      year: selectedYear,
    });
    setModalVisibility(true);
  };

  useEffect(() => {
    if (yearsList?.length > 0) {
      // @ts-ignore
      setSelectedYear(yearsList[0].value);
    }
  }, [yearsList]);

  useEffect(() => {
    if (propertyTypeYearRates && selectedYear) {
      for (const item of propertyTypeYearRates) {
        if (item.year === selectedYear) {
          setDataSource(item.newPropertyType);
        }
      }
    }
  }, [selectedYear, propertyTypeYearRates]);

  /**
   * Fetches the costs by LGA records
   */
  const fetchData = async () => {
    try {
      if (user?.token) {
        await dispatch(
          fetchPropertyTypes({ token: user.token }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Year',
        name: 'year',
        type: 'text',
        key: 'year',
        isRequired: true,
        disabled: true,
        requiredMessage: 'Year is required',
      },
      {
        label: 'Focus',
        name: 'focus',
        type: 'select',
        key: 'focus',
        isRequired: true,
        requiredMessage: 'Focus is required',
        options: focusTypeOptions,
      },
      {
        label: 'Property Type',
        name: 'propertyType',
        type: 'select',
        key: 'propertyType',
        isRequired: true,
        requiredMessage: 'Property Type is required',
        options: propertyTypes,
      },
      {
        label: 'Purchase Price',
        name: 'purchasePrice',
        type: 'number',
        key: 'purchasePrice',
        isRequired: true,
        requiredMessage: 'Purchase Price is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Valuation At Purchase',
        name: 'valuationAtPurchase',
        type: 'number',
        key: 'valuationAtPurchase',
        isRequired: true,
        requiredMessage: 'Valuation At Purchase is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Annual Growth Rate',
        name: 'annualPriceGrowth',
        type: 'number',
        key: 'annualPriceGrowth',
        isRequired: true,
        requiredMessage: 'Annual Growth Rate is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'LVR',
        name: 'lvr',
        type: 'number',
        key: 'lvr',
        isRequired: true,
        requiredMessage: 'LVR is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Yield',
        name: 'yield',
        type: 'number',
        key: 'yield',
        isRequired: true,
        requiredMessage: 'Yield is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Rent/Week',
        name: 'rentPerWeek',
        type: 'number',
        key: 'rentPerWeek',
        isRequired: true,
        requiredMessage: 'Rent/Week is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'B&P(per unit)',
        name: 'buildingPestInspection',
        type: 'number',
        key: 'buildingPestInspection',
        isRequired: true,
        requiredMessage: 'B&P(per unit) is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Maintenance at purchase (per unit)',
        name: 'maintenanceAtPurchase',
        type: 'number',
        key: 'maintenanceAtPurchase',
        isRequired: true,
        requiredMessage: 'Maintenance at purchase (per unit) is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Conveyancing',
        name: 'conveyancing',
        type: 'number',
        key: 'conveyancing',
        isRequired: true,
        requiredMessage: 'Conveyancing is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Independent Property Valuation',
        name: 'independentPropertyValuation',
        type: 'number',
        key: 'independentPropertyValuation',
        isRequired: true,
        requiredMessage: 'Independent Property Valuation is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Yearly Insurance(per unit)',
        name: 'yearlyInsurance',
        type: 'number',
        key: 'yearlyInsurance',
        isRequired: true,
        requiredMessage: 'Yearly Insurance(per unit) is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Property Management Letting Fee',
        name: 'propertyManagementLettingFee',
        type: 'number',
        key: 'propertyManagementLettingFee',
        isRequired: true,
        requiredMessage: 'Property Management Letting Fee is required',
        addonBefore: '$',
        min: 0,
      },
    ],
    [focusTypeOptions],
  );

  const handlePropertyEdit = async (values: any) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          const data = {
            ...values,
            userId: user.user_id,
            lvr: values?.lvr / 100,
            yield: values?.yield / 100,
            annualPriceGrowth: values?.annualPriceGrowth / 100,
            year: selectedYear,
          };

          await dispatch(
            updatePropertyType({
              data: data,
              token: user?.token,
            }),
            // @ts-ignore
          ).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleYearChange = (v: any) => {
    setSelectedYear(v);
  };

  return (
    <div className="property-type">
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Property Type`}
          onSubmit={handlePropertyEdit}
          formFieldsArray={formFieldsArray}
        />
      )}
      <Row>
        <Col xs={24} md={10} lg={6}>
          <FormSelectField
            options={yearsList}
            label="Year"
            value={selectedYear}
            onChange={handleYearChange}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        size={useTableSize()}
        loading={isLoading}
        scroll={{ x: 2600 }}
      />
    </div>
  );
};

export default PropertyTypes;
