import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../foundation/components/form_modal/FormModal';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import RiskProfileTable from './elements/RiskProfileTable';
import { fetchRiskProfileData, updateRiskProfile } from './redux/async_thunks';
import { selectRiskProfilesList } from './redux/selectors';
import { RiskProfile } from './redux/types';

const ClientRiskProfile = () => {
  const titlesToBold = [];
  const dispatch = useAppDispatch();

  const [isModalVisible, setModalVisibility] = useState(false);

  const [modalData, setModalData] = useState<undefined | RiskProfile>(
    undefined,
  );

  const [isRiskProfilesLoading, setRiskProfilesLoader] = useState(true);

  const user = useSelector(selectUser);

  const riskProfiles = useSelector(selectRiskProfilesList);

  const fetchData = async () => {
    try {
      if (user?.token) {
        await dispatch(fetchRiskProfileData({ token: user.token })).unwrap();

        setRiskProfilesLoader(false);
      }
    } catch (error) {
      console.log(error);
      setRiskProfilesLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getFieldAddInfo = () => {
    if (modalData) {
      switch (modalData.fieldType) {
        case 'price': {
          return {
            addonBefore: '$',
            type: 'number',
            min: 0,
          };
        }
        case 'boolean': {
          return {
            type: 'select',
            options: [
              { key: 'Yes', value: true },
              { key: 'No', value: false },
            ],
          };
        }
        case 'percentage': {
          return { addonBefore: '%', min: 0, type: 'number' };
        }
        default: {
          return {
            type: 'number-sd',
            min: modalData.fieldMin,
          };
        }
      }
    }
  };

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Title',
        name: 'fieldTitle',
        key: 'fieldTitle',
        type: 'text',
        disabled: true,
      },
      {
        label: 'Aggressive',
        name: 'aggressive',
        key: 'aggressive',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Aggressive is required',
        ...getFieldAddInfo(),
      },
      {
        label: 'Moderate',
        name: 'moderate',
        key: 'moderate',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Moderate is required',
        ...getFieldAddInfo(),
      },
      {
        label: 'Conservative',
        name: 'conservative',
        key: 'conservative',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Conservative is required',
        ...getFieldAddInfo(),
      },
    ],
    [modalData],
  );

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleTableEdit = (values: any) => {
    setModalData(values);
    setModalVisibility(true);
  };

  const handleRiskProfileEdit = (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          let data = {
            fieldName: values.fieldName,
            aggressive: values.aggressive,
            moderate: values.moderate,
            conservative: values.conservative,
            userId: user.user_id,
          };
          if (values.fieldType === 'percentage') {
            data = {
              ...data,
              aggressive: data.aggressive / 100,
              moderate: data.moderate / 100,
              conservative: data.conservative / 100,
            };
          }
          await dispatch(
            updateRiskProfile({ data, token: user.token }),
          ).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <div className="l-risk-profile" style={{ paddingTop: 20 }}>
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Risk Profile`}
          onSubmit={handleRiskProfileEdit}
          formFieldsArray={formFieldsArray}
        />
      )}
      <RiskProfileTable
        isLoading={isRiskProfilesLoading}
        titlesToBold={titlesToBold}
        dataSource={riskProfiles}
        onEdit={handleTableEdit}
      />
    </div>
  );
};

export default ClientRiskProfile;
