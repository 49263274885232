import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FieldType } from '../../../../foundation/components/form_modal/form_modal_types';
import { useRole } from '../../../../foundation/cutom_hooks/useRole';
import { selectUser } from '../../../authentication/redux/selectors';
import RecordModal from '../../record_modal/RecordModal';
import {
  updatePurchaseDetails,
  updatePurchaseDetailsStatus,
} from '../../redux/async_thunks';
import {
  selectPaymentStatusTypeValues,
  selectPropCashReqIsSold,
} from '../../redux/selectors';
import ConditionalExchange from './ConditionalExchange';
import Engangement from './Engangement';
import PostSettlement from './PostSettlement';
import Settlement from './Settlement';
import UnconditionalExchange from './UnconditionalExchange';

const { Panel } = Collapse;

type Props = {
  isLoading: boolean;
  propertyId: string;
  setIsLoading: (v: boolean) => any;
};

const Details = ({ isLoading, setIsLoading, propertyId }: Props) => {
  const [isUpdateStatusModalVisible, setUpdateStatusModalVisibility] =
    useState(false);
  const [statusSectionName, setStatusSectionName] = useState<
    string | undefined
  >(undefined);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const isPropertySold = useSelector(selectPropCashReqIsSold);

  const isClientView = useRole();

  const paymentStatusTypes = useSelector(selectPaymentStatusTypeValues);

  /**
   * Updates the data.
   */
  const updateData = async (values: any) => {
    if (isLoading || isClientView) {
      return;
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);

        if (user?.token) {
          const data = {
            ...values,
            userId: user.user_id,
            propertyId,
          };
          await dispatch(
            updatePurchaseDetails({ token: user.token, values: data }),
            // @ts-ignore
          ).unwrap();

          resolve('');
        }

        setIsLoading(false);
      } catch (error) {
        reject();
        console.log(error);
        setIsLoading(false);
      }
    });
  };

  const updateStatus = async (status: string) => {
    if (isLoading || isClientView) {
      return;
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);

        if (user?.token) {
          const data = {
            sectionName: statusSectionName,
            paymentStatus: status,
            userId: user.user_id,
            propertyId,
          };
          await dispatch(
            updatePurchaseDetailsStatus({ token: user.token, values: data }),
            // @ts-ignore
          ).unwrap();

          resolve('');
        }

        setIsLoading(false);
      } catch (error) {
        reject();
        console.log(error);
        setIsLoading(false);
      }
    });
  };

  const handleModalClose = () => {
    setUpdateStatusModalVisibility(false);
  };

  const handleFormSubmission = (values: any) => {
    return updateStatus(values.status);
  };

  const openStatusModal = (sectionName: string) => () => {
    if (isClientView) {
      return;
    }
    setStatusSectionName(sectionName);
    setUpdateStatusModalVisibility(true);
  };

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Status',
        name: 'status',
        key: 'status',
        type: 'select',
        isRequired: true,
        requiredMessage: 'Status is required',
        options: paymentStatusTypes,
      },
    ],
    [paymentStatusTypes],
  );

  const statusModalTitle = useMemo(() => {
    switch (statusSectionName) {
      case 'conditionalExchangeFeesInfo':
      case 'engagementFeesInfo':
      case 'unconditionalExchangeFeesInfo':
      case 'settlementFeesInfo':
      case 'postSettlementFeesInfo':
        return 'Update Status Value For The Whole Section';

      default: {
        return 'Update Status Of All The Sections';
      }
    }
  }, [statusSectionName]);

  const renderUpdateStatusButton = (sectionName: string) => {
    return (
      <div
        className="purchase__section-header"
        style={{ justifyContent: 'flex-end', padding: '10px 0px' }}
      >
        <Button
          type="primary"
          onClick={openStatusModal(sectionName)}
          disabled={isClientView || isPropertySold}
        >
          Update Status
        </Button>
      </div>
    );
  };

  return (
    <div className="purchase__section">
      {isUpdateStatusModalVisible && (
        <RecordModal
          visible={isUpdateStatusModalVisible}
          closeModal={handleModalClose}
          modalData={{
            paymentStatus: undefined,
          }}
          modalTitle={statusModalTitle}
          onSubmit={handleFormSubmission}
          formFieldsArray={formFieldsArray}
        />
      )}
      <div className="purchase__section-header">
        <div className="purchase__section-title">Details</div>
        <div className="purchase__section-title">
          <Button
            type="primary"
            onClick={openStatusModal('all')}
            disabled={isClientView || isPropertySold}
          >
            Update Status
          </Button>
        </div>
      </div>
      <div className="purchase__details-collapse-wrapper">
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
        >
          <Panel
            header="Engagement"
            key="1"
            className="site-collapse-custom-panel"
          >
            {renderUpdateStatusButton('engagementFeesInfo')}
            <Engangement updateData={updateData} isClientView={isClientView} />
          </Panel>
          <Panel
            header="Conditional exchange of contract"
            key="2"
            className="site-collapse-custom-panel"
          >
            {renderUpdateStatusButton('conditionalExchangeFeesInfo')}
            <ConditionalExchange
              updateData={updateData}
              isClientView={isClientView}
            />
          </Panel>
          <Panel
            header="Unconditional exchange of contract"
            key="3"
            className="site-collapse-custom-panel"
          >
            {renderUpdateStatusButton('unconditionalExchangeFeesInfo')}
            <UnconditionalExchange
              updateData={updateData}
              isClientView={isClientView}
            />
          </Panel>
          <Panel
            header="Settlement"
            key="4"
            className="site-collapse-custom-panel"
          >
            {renderUpdateStatusButton('settlementFeesInfo')}
            <Settlement updateData={updateData} isClientView={isClientView} />
          </Panel>
          <Panel
            header="Post settlement"
            key="5"
            className="site-collapse-custom-panel"
          >
            {renderUpdateStatusButton('postSettlementFeesInfo')}
            <PostSettlement
              updateData={updateData}
              isClientView={isClientView}
            />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Details;
