import { Button, Input } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

const internalShareValidationSchema = () => {
  const fields: any = {
    email: Yup.string().email().required('Email is required').nullable(),
  };

  return Yup.object().shape(fields);
};

type Props = {
  onSubmit: (email: string) => Promise<any>;
};

const InternalShareComponent = ({ onSubmit }: Props) => {
  const [isInternalShareLoading, setIsInternalShareLoading] = useState(false);

  const handleFormSubmit = async (values: any) => {
    if (!isInternalShareLoading) {
      try {
        setIsInternalShareLoading(true);
        await onSubmit(values.email);
        setIsInternalShareLoading(false);
      } catch (error) {
        setIsInternalShareLoading(false);
      }
    }
  };

  return (
    <div className="l-otp__form-wrapper">
      <div className="l-otp__form-msg">
        {`Please enter your Dashdot email address.`}
      </div>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleFormSubmit}
        validationSchema={internalShareValidationSchema}
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <div className="c-form-field">
                <div className="c-form-field__label">Email:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    onBlur={handleBlur}
                    placeholder={'jonsnow@got.com'}
                  />
                  <div className="c-form-field__error">
                    {errors.email ? errors.email : undefined}
                  </div>
                </div>
              </div>

              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isInternalShareLoading}
                  disabled={isInternalShareLoading}
                  style={{ width: '100%', height: 40 }}
                >
                  Share
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InternalShareComponent;
