import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectMonthlyModeling = (state: RootState) =>
  state.property.monthly_modeling?.monthlyModeling;

export const selectPropMonthlyModelingIsSold = (state: RootState) =>
  state.property.monthly_modeling?.isSold;

export const selectMonthlyModelingContractDate = (state: RootState) =>
  state.property.monthly_modeling?.contractDate;

export const selectMonthlyModelingStartMonth = (state: RootState) =>
  state.property.monthly_modeling?.monthKey;

export const selectMonthlyModelingYearDisplayed = (state: RootState) =>
  state.property.monthly_modeling?.yearDisplayed;

export const selectMonthlyModelingInterestRateForYearDisplayed = (
  state: RootState,
) => state.property.monthly_modeling?.interestRateForDisplayedYear;

export const selectMonthlyModelingColumns = (state: RootState) =>
  state.property.monthly_modeling?.monthlyColumns;

export const selectMonthlyModelingQuaterlyColumns = (state: RootState) =>
  state.property.monthly_modeling?.quaterlyColumns;

export const selectMonthlyModelingYearlyColumns = (state: RootState) =>
  state.property.monthly_modeling?.yearlyColumns;

export const selectQuaterlyModeling = (state: RootState) =>
  state.property.monthly_modeling?.quaterlyModeling;

export const selectYearlyModeling = (state: RootState) =>
  state.property.monthly_modeling?.yearlyModeling;
