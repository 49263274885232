import { Tabs } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCostByStatesData } from '../redux/selectors';
import CostByStateTable from './CostByStateTable';

const { TabPane } = Tabs;

type CostByStateProps = {
  isLoading: boolean;
};

const CostByState = ({ isLoading }: CostByStateProps) => {
  const costByStateData = useSelector(selectCostByStatesData);

  const tabPanes = useMemo(() => {
    if (costByStateData) {
      const tabPanesArray: any = [];

      for (const item of costByStateData) {
        if (item && item.propertyType) {
          tabPanesArray.push(
            <TabPane tab={item.propertyType} key={item.propertyType}>
              <CostByStateTable
                key={item.propertyType}
                dataSource={item.rates}
                isLoading={isLoading}
                propertyType={item.propertyType}
              />
            </TabPane>,
          );
        }
      }

      return tabPanesArray;
    }

    return null;
  }, [costByStateData, [isLoading]]);
  return (
    <>
      <div className="stamp_duty_lmi__section-title">Costs By State</div>
      <Tabs>{tabPanes}</Tabs>
    </>
  );
};

export default CostByState;
