import * as Yup from 'yup';

const SellFormValidationSchema = () => {
  const requiredMessage = `This field is required`;
  const fields: any = {
    saleSettlementDate: Yup.string().when('sellEnabled', {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().optional().nullable(),
    }),
  };

  return Yup.object().shape(fields);
};

export default SellFormValidationSchema;
