import * as Yup from 'yup';

const PropertyTypeValidationSchema = () => {
  const fields: any = {
    propertyName: Yup.string()
      .trim()
      .required('Name is required')
      .min(4, 'Name must be at least 4 characters'),
    purchaseType: Yup.string().required('Purchase Type is required'),
  };

  return Yup.object().shape(fields);
};

export default PropertyTypeValidationSchema;
