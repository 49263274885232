import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../foundation/components/form_modal/FormModal';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import {
  fetchSettlementDays,
  updateSettlementDays,
} from './redux/async_thunks';

const SettlementPeriod = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState<any>(undefined);

  const [data, setData] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const onEditRecord = (record: any) => () => {
    setModalData({
      ...record,
    });
    setModalVisibility(true);
  };

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // ellipsis: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      // ellipsis: true,
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'name',
      key: 'name',
      width: 100,
      fixed: 'right',
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="property-type__edit-record-btn"
          />
        );
      },
    },
  ];

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Name',
        name: 'name',
        type: 'text',
        key: 'name',
        isRequired: true,
        requiredMessage: 'Name is required',
        disabled: true,
      },
      {
        label: 'Value',
        name: 'value',
        type: 'number-sd',
        key: 'value',
        isRequired: true,
        requiredMessage: 'Value is required',
        min: 0,
      },
    ],
    [],
  );

  /**
   * Fetches the unconditional settlement days
   */
  const fetchData = async () => {
    try {
      if (user?.token) {
        const resData = await dispatch(
          fetchSettlementDays({ token: user.token }),
          // @ts-ignore
        ).unwrap();

        setData(resData);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDataEdit = async (values: any) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token && modalData && modalData.key) {
          const reqData = {
            [modalData.key]: values.value,
            userId: user.user_id,
          };

          for (const i of data) {
            if (i.key !== modalData.key) {
              reqData[i.key] = i.value;
            }
          }

          const resData = await dispatch(
            updateSettlementDays({
              data: reqData,
              token: user?.token,
            }),
            // @ts-ignore
          ).unwrap();

          setData(resData);
          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  return (
    <div className="settlement-days">
      {isLoading && <FullPageLoader />}
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Data`}
          onSubmit={handleDataEdit}
          formFieldsArray={formFieldsArray}
        />
      )}
      <Table
        columns={columns}
        dataSource={data}
        size={useTableSize()}
        loading={isLoading}
        pagination={false}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default SettlementPeriod;
