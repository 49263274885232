import { Card, Col, Progress, Row } from 'antd';
import React from 'react';

import { percentageFormatter } from '../../../foundation/utils/helperFunctions';

type Props = {
  percentage?: number;
  goal?: number;
  actual?: number;
  percentageTitle?: string;
  strokeColor?: string;
  actualValueLabel?: string;
};

const ProgressCard = ({
  percentage,
  strokeColor,
  goal,
  actual,
  percentageTitle,
  actualValueLabel,
}: Props) => {
  return (
    <Card className="dashboard__card">
      <div className="dashboard__progress-card-header">
        <div>{percentageTitle}</div>
        <div>{percentageFormatter(percentage ? percentage * 100 : 0)}</div>
      </div>
      <Progress
        percent={percentage ? percentage * 100 : 0}
        showInfo={false}
        style={{ marginBottom: 10 }}
        strokeColor={strokeColor}
      />
      <div className="dashboard__progress-card-values">
        <Row justify="space-between">
          <Col>Target:</Col>
          <Col>
            <b>{goal}</b>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>{actualValueLabel}:</Col>
          <Col>
            <b>{actual}</b>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default ProgressCard;
