import { notification } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../store/hooks';
import OTPView from '../../client_dashboard/OTPView';
import { selectClientDashboardLinkData } from '../../client_dashboard/redux/selectors';
import AuthenticationPageImage from '../elements/AuthenticationPageImage';
import ClientAuthenticationForm from '../forms/ClientAuthenticationForm';
import { clientSignup } from '../redux/async_thunks';

const Signup = () => {
  const dispatch = useAppDispatch();
  const [isSingupFormLoading, setSignupFormLoading] = useState(false);

  const [isOTPViewVisible, setOTPViewVisibility] = useState(false);

  const [singupFormData, setSignupFormData] = useState<any>(undefined);

  const linkData = useSelector(selectClientDashboardLinkData);

  const navigate = useNavigate();

  const handleFormSubmit = (values) => {
    try {
      setSignupFormData({
        email: values.email,
        passCode: values.passCode,
      });
      setOTPViewVisibility(true);
    } catch (error) {
      setSignupFormLoading(false);
    }
  };

  const handleSignup = async (otp: string | undefined) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          ...singupFormData,
          linkId: linkData?.linkId,
          otp,
        };

        const response = await dispatch(
          clientSignup({ values: data }),
          // @ts-ignore
        ).unwrap();

        notification.success({
          message: 'Succes!',
          description: 'Successfully registered! please log in to continue.',
        });

        resolve(true);

        navigate('/client/login');
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  return (
    <>
      {!isOTPViewVisible && (
        <div className="client-dash-login">
          <ClientAuthenticationForm
            handleFormSubmit={handleFormSubmit}
            isFormLoading={isSingupFormLoading}
            formType="signup"
            redactedEmail={linkData?.redactedEmail}
          />
          <AuthenticationPageImage />
        </div>
      )}
      {isOTPViewVisible && (
        <OTPView
          onSubmit={handleSignup}
          set2FAVisibility={setOTPViewVisibility}
        />
      )}
    </>
  );
};

export default Signup;
