import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import SoldLabel from '../../../foundation/components/sold_label/SoldLabel';
import SoldOverlay from '../../../foundation/components/sold_overlay/SoldOverlay';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { fetchAnnualFigures } from '../redux/async_thunks';
import {
  selectAnnualFiguresContractDate,
  selectAnnualFiguresPropertyName,
  selectPropAnnualFigureIsSold,
} from '../redux/selectors';
import { resetAnnualFigures } from '../redux/slice';
import CashIn from './sections/CashIn';
import CashOut from './sections/CashOut';
import GrowthProjections from './sections/GrowthProjections';
import InterestRateProjections from './sections/InterestRateProjections';
import PerformanceAssumptions from './sections/PerformanceAssumptions';

const AnnualFigures = () => {
  const { isDesktopViewport } = useViewport();

  const { id: propertyId }: any = useParams();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector(selectUser);

  const isClientView = useRole();

  const contract_date = useSelector(selectAnnualFiguresContractDate);

  const property_name = useSelector(selectAnnualFiguresPropertyName);

  const isPropertySold = useSelector(selectPropAnnualFigureIsSold);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      if (user?.token) {
        await dispatch(
          fetchAnnualFigures({ token: user.token, propertyId }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
    return () => {
      dispatch(resetAnnualFigures());
    };
  }, [propertyId]);

  return (
    <div className="annual-figures">
      {isLoading && <FullPageLoader />}
      {isPropertySold && <SoldOverlay />}
      <div className="annual-figures__header">
        <div className="annual-figures__header-title">
          Annual Figures {isPropertySold && <SoldLabel />}
        </div>
        <Row gutter={[20, 0]}>
          <Col
            xs={24}
            md={12}
            style={isDesktopViewport ? {} : { marginBottom: 10 }}
          >
            <div style={{ marginRight: 10 }}>Property</div>
            <div>
              <Input disabled value={property_name} />
            </div>
          </Col>
          <Col
            xs={24}
            md={6}
            style={isDesktopViewport ? {} : { marginBottom: 10 }}
          >
            <div style={{ marginRight: 10 }}>Contract Date</div>
            <div>
              <Input disabled value={contract_date} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="annual-figures__section-wrapper">
        <CashIn
          isLoading={isLoading}
          propertyId={propertyId}
          isClientView={isClientView}
        />
        <CashOut
          isLoading={isLoading}
          propertyId={propertyId}
          isClientView={isClientView}
        />
        <PerformanceAssumptions
          isLoading={isLoading}
          propertyId={propertyId}
          isClientView={isClientView}
        />

        <div className="annual-figures__dual-section-container">
          <GrowthProjections
            isLoading={isLoading}
            propertyId={propertyId}
            isClientView={isClientView}
          />

          <InterestRateProjections
            isLoading={isLoading}
            propertyId={propertyId}
            isClientView={isClientView}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnualFigures;
