/* eslint-disable no-async-promise-executor */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../foundation/components/form_modal/FormModal';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import FocusGrowthTable from './FocusGrowthTable';
import FocusSavingsTable, {
  yearlySavingsFormFieldsArray,
} from './FocusSavingsTable';
import {
  fetchFocusData,
  fetchYearlySavingsRequired,
  updateFocusData,
  updateYearlySavingsRequired,
} from './redux/async_thunks';
import {
  selectFocusPriceGrowth,
  selectFocusRentalGrowth,
  selectFocusYearlySavingsRequired,
} from './redux/selectors';

const Focus = () => {
  const titlesToBold = [];
  const dispatch = useAppDispatch();

  const [isModalVisible, setModalVisibility] = useState(false);

  const [modalData, setModalData] = useState(undefined);

  const [isFocusLoading, setFocusLoader] = useState(true);

  const [formType, setFormType] = useState<'focus' | 'yearly_saving'>('focus');

  const user = useSelector(selectUser);

  const rentalGrowth = useSelector(selectFocusRentalGrowth);

  const priceGrowth = useSelector(selectFocusPriceGrowth);

  const yearlySavingsRequired = useSelector(selectFocusYearlySavingsRequired);

  const fetchData = async () => {
    try {
      if (user?.token) {
        await Promise.all([
          dispatch(fetchFocusData({ token: user.token })).unwrap(),
          dispatch(fetchYearlySavingsRequired({ token: user.token })).unwrap(),
        ]);

        setFocusLoader(false);
      }
    } catch (error) {
      console.log(error);
      setFocusLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Form fields
   */
  const focusFormFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Year',
        name: 'year',
        key: 'year',
        type: 'text',
        isRequired: true,
        disabled: true,
        requiredMessage: 'Year is required',
      },
      {
        label: 'Growth',
        name: 'growth',
        key: 'growth',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Growth is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Balanced',
        name: 'balanced',
        key: 'balanced',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Balanced is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Cashflow',
        name: 'cashFlow',
        key: 'cashFlow',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Cashflow is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
    ],
    [],
  );

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleFocusTableEdit = (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          const data = {
            ...values,
            growth: values?.growth / 100,
            balanced: values?.balanced / 100,
            cashFlow: values?.cashFlow / 100,
            userId: user.user_id,
          };

          await dispatch(updateFocusData({ data, token: user.token })).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleYearlySavingsTableEdit = (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          const data = {
            ...values,
            userId: user.user_id,
          };

          await dispatch(
            updateYearlySavingsRequired({ data, token: user.token }),
          ).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const onGrowthTableEdit = (values: any) => {
    setModalData(values);
    setModalVisibility(true);
  };

  return (
    <div className="l-focus">
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={
            formType === 'focus'
              ? `Update Target Growth`
              : `Yearly Savings Required`
          }
          onSubmit={
            formType === 'focus'
              ? handleFocusTableEdit
              : handleYearlySavingsTableEdit
          }
          formFieldsArray={
            formType === 'focus'
              ? focusFormFieldsArray
              : yearlySavingsFormFieldsArray
          }
        />
      )}

      <div className="l-focus__section-title">Price Growth Assumptions</div>

      <FocusGrowthTable
        dataSource={priceGrowth}
        isLoading={isFocusLoading}
        titlesToBold={titlesToBold}
        targetGrowth="PriceGrowth"
        onEdit={onGrowthTableEdit}
        setFormType={setFormType}
      />
      <div className="l-focus__section-title">Rental Growth Assumptions</div>

      <FocusGrowthTable
        dataSource={rentalGrowth}
        isLoading={isFocusLoading}
        titlesToBold={titlesToBold}
        targetGrowth="RentalGrowth"
        onEdit={onGrowthTableEdit}
        setFormType={setFormType}
      />

      <div className="l-focus__section-title">Yearly Savings Required</div>
      <FocusSavingsTable
        dataSource={yearlySavingsRequired}
        isLoading={isFocusLoading}
        titlesToBold={titlesToBold}
        onEdit={onGrowthTableEdit}
        setFormType={setFormType}
      />
    </div>
  );
};

export default Focus;
