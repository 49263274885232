import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../foundation/utils/helperFunctions';
import { FocusRate } from './redux/types';

type FocusGrowthTableProps = {
  isLoading: boolean;
  titlesToBold: string[];
  dataSource: FocusRate[];
  targetGrowth: string;
  onEdit: (values: any) => void;
  setFormType: React.Dispatch<React.SetStateAction<'focus' | 'yearly_saving'>>;
};
const FocusGrowthTable = ({
  titlesToBold,
  dataSource,
  targetGrowth,
  isLoading,
  onEdit,
  setFormType,
}: FocusGrowthTableProps) => {
  const onEditRecord = (record: FocusRate) => () => {
    setFormType('focus');
    onEdit({
      growth: percentageParser(record.growth),
      balanced: percentageParser(record.balanced),
      cashFlow: percentageParser(record.cashFlow),
      targetGrowth,
      year: record.year,
    });
  };

  /**
   * Data table's columns.
   */
  const columns: ColumnsType<FocusRate> = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, undefined, titlesToBold, 'year');
      },
    },
    {
      title: 'Growth',
      dataIndex: 'growth',
      key: 'growth',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'year');
      },
    },
    {
      title: 'Balanced',
      dataIndex: 'balanced',
      key: 'balanced',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'year');
      },
    },
    {
      title: 'Cashflow',
      dataIndex: 'cashFlow',
      key: 'cashFlow',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'year');
      },
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'year',
      key: 'year',
      width: 80,
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="l-costs-by-lga__edit-record-btn"
          />
        );
      },
    },
  ];
  return (
    <div className="l-focus__table-wrapper">
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        size={useTableSize()}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default FocusGrowthTable;
