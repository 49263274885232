import React from 'react';

function EmailFeatured() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <rect width="48" height="48" x="4" y="4" fill="#D6F7F4" rx="24"></rect>
      <path
        stroke="#019386"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M38 22c0-1.1-.9-2-2-2H20c-1.1 0-2 .9-2 2m20 0v12c0 1.1-.9 2-2 2H20c-1.1 0-2-.9-2-2V22m20 0l-10 7-10-7"
      ></path>
      <rect
        width="48"
        height="48"
        x="4"
        y="4"
        stroke="#EFFCFB"
        strokeWidth="8"
        rx="24"
      ></rect>
    </svg>
  );
}

export default EmailFeatured;
