import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../foundation/components/form_modal/FormModal';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import InterestRateProjectionsTable from './elements/InterestRateProjectionsTable';
import {
  fetchInterestRateProjectionData,
  updateInterestRateProjection,
} from './redux/async_thunks';
import { selectInterestRateProjectionList } from './redux/selectors';
import { InterestRate } from './redux/types';

const InterestRateProjection = () => {
  const titlesToBold = [];

  const dispatch = useAppDispatch();

  const [isInterestRateProjectionsLoading, setInterestRateProjectionsLoading] =
    useState(true);

  const [isModalVisible, setModalVisibility] = useState(false);

  const [modalData, setModalData] = useState<undefined | InterestRate>(
    undefined,
  );

  const user = useSelector(selectUser);

  const interestRateProjections = useSelector(selectInterestRateProjectionList);

  const fetchData = async () => {
    try {
      if (user?.token) {
        await dispatch(
          fetchInterestRateProjectionData({ token: user.token }),
        ).unwrap();

        setInterestRateProjectionsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setInterestRateProjectionsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTableEdit = (values: any) => {
    setModalData(values);
    setModalVisibility(true);
  };

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Year',
        name: 'year',
        key: 'year',
        type: 'text',
        isRequired: true,
        disabled: true,
        requiredMessage: 'Year is required',
      },
      {
        label: 'Interest Rate',
        name: 'interestRate',
        key: 'interestRate',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Interest Rate is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
    ],
    [],
  );

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleInterestRateProjectionEdit = (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          const data = {
            ...values,
            userId: user.user_id,
            interestRate: values.interestRate / 100,
          };

          await dispatch(
            updateInterestRateProjection({ data, token: user.token }),
          ).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <div style={{ paddingTop: 20 }}>
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Interest Rate`}
          onSubmit={handleInterestRateProjectionEdit}
          formFieldsArray={formFieldsArray}
        />
      )}
      <InterestRateProjectionsTable
        isLoading={isInterestRateProjectionsLoading}
        titlesToBold={titlesToBold}
        dataSource={interestRateProjections}
        onEdit={handleTableEdit}
      />
    </div>
  );
};

export default InterestRateProjection;
