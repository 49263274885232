import { Button, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import EmailFeatured from '../../foundation/assets/svgs/EmailFeatured.svg';
import OtpInput from '../../foundation/components/otp_input/OtpInput';
import InternalShareComponent from './InternalShareComponent';
import { sendInternalOTP, sendOTP, validateOTP } from './redux/async_thunks';
import { selectClientDashboardLinkData } from './redux/selectors';
import { setClientDashboardLinkData } from './redux/slice';

let timer: any = undefined;

type Props = {
  set2FAVisibility: (v: boolean) => void;
  onSubmit?: (otp: string | undefined) => Promise<any>;
};

const OTPView = ({ onSubmit, set2FAVisibility }: Props) => {
  const [isOTPLoading, setIsOTPLoading] = useState(false);

  const navigate = useNavigate();

  const [isInternalShareView, setIsInternalShareView] = useState<
    boolean | undefined
  >(undefined);

  const [internalEmail, setInternalEmail] = useState<string | undefined>(
    undefined,
  );

  const [OTP, setOTP] = useState<string | undefined>(undefined);

  const linkData = useSelector(selectClientDashboardLinkData);

  const [otpExpiry, setOtpExpiry] = useState<number | undefined>(
    linkData?.otpExpirationSeconds,
  );

  const dispatch = useDispatch();

  /**
   * Show the internal email view when the user is an internal user.
   */
  useEffect(() => {
    if (linkData?.internalShare && isInternalShareView === undefined) {
      setIsInternalShareView(true);
    }
  }, [linkData]);

  const sendOTPToClient = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      try {
        if (linkData?.linkId) {
          const data = await dispatch(
            sendOTP({ linkId: linkData?.linkId }),
            // @ts-ignore
          ).unwrap();

          if (!data.internalShare) {
            notification.success({
              message: 'Succes!',
              description: 'OTP has been sent to your email address.',
            });

            setOtpExpiry(data?.otpExpirationSeconds);

            // setOTPData(data);
          } else {
            // setOTPData(data);
          }
          dispatch(
            setClientDashboardLinkData({
              ...data,
            }),
          );

          res(data);
        }
      } catch (error) {
        rej(error);
      }
    });
  };

  const sendInternalShareOTP = (email: string) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      try {
        const values = {
          emailAddress: email,
          linkId: linkData?.linkId,
        };

        setInternalEmail(email);

        // @ts-ignore
        const data = await dispatch(sendInternalOTP({ values })).unwrap();

        notification.success({
          message: 'Succes!',
          description: 'OTP has been sent to your email address.',
        });

        setIsInternalShareView(false);
        setOtpExpiry(data?.otpExpirationSeconds);

        dispatch(setClientDashboardLinkData({ ...data }));

        res(data);
      } catch (error) {
        rej(error);
      }
    });
  };

  const isInternalShare = useMemo(() => linkData?.internalShare, [linkData]);

  const errorHandler = () => {
    setIsOTPLoading(false);
    navigate('/client/join');
  };

  const otpTimerHandler = () => {
    if (otpExpiry && otpExpiry !== 0) {
      setOtpExpiry((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else if (time) {
          return time - 1;
        }
      });
    }
  };

  useEffect(() => {
    if (linkData?.otpExpirationSeconds && linkData?.otpExpirationSeconds > 0) {
      timer = setInterval(otpTimerHandler, 1000);
    }
  }, [linkData]);

  const handleOTPValidation = async () => {
    if (isOTPLoading) {
      return;
    }

    try {
      setIsOTPLoading(true);

      if (isInternalShare) {
        const reqBody = {
          linkId: linkData?.linkId,
          otp: OTP,
        };

        await dispatch(
          validateOTP({
            values: reqBody,
            email: internalEmail,
          }),

          // @ts-ignore
        ).unwrap();
        setIsOTPLoading(false);
        if (set2FAVisibility) {
          set2FAVisibility(false);
        }

        navigate('/plan');
      } else {
        if (onSubmit) {
          await onSubmit(OTP);
          navigate('/plan');
        }

        setIsOTPLoading(false);
      }
    } catch (error) {
      errorHandler();
    }
  };

  const handleOTPChange = (v: any) => {
    setOTP(v);
  };

  const handleResendOTP = async () => {
    if (isOTPLoading || otpExpiry === undefined || otpExpiry !== 0) {
      return;
    }

    try {
      setIsOTPLoading(true);

      if (isInternalShare && internalEmail) {
        await sendInternalShareOTP(internalEmail);
      } else {
        await sendOTPToClient();
      }

      setIsOTPLoading(false);
    } catch (error) {
      console.log(error);
      errorHandler();
    }
  };

  const handleOTPCancel = () => {
    if (isInternalShare) {
      setIsInternalShareView(true);
    } else {
      set2FAVisibility(false);
    }
    clearInterval(timer);
  };

  return (
    <div className="l-otp">
      {isInternalShareView ? (
        <InternalShareComponent onSubmit={sendInternalShareOTP} />
      ) : (
        <div className="l-otp__form-wrapper">
          <div>
            <EmailFeatured />
          </div>
          <div className="l-otp__form-msg">Please check your email</div>
          <div className="l-otp__form-sub-msg">{`We've sent a code to ${
            isInternalShare ? internalEmail : linkData?.redactedEmail
          }`}</div>
          <div>
            <OtpInput
              value={OTP}
              inputStyle="inputStyle"
              numInputs={6}
              errorStyle="error"
              separatorPlacement={3}
              onChange={handleOTPChange}
              separator={<span className="otp-separator">-</span>}
              isInputNum={true}
              shouldAutoFocus
              placeholder={306600}
            />
          </div>
          <div className="l-otp__resend-code ">
            <span style={{ marginRight: 5 }}>{`Didn’t get a code?`}</span>
            <a
              onClick={handleResendOTP}
              className={`l-otp__resend-code ${
                otpExpiry === undefined || otpExpiry !== 0
                  ? ' l-otp__resend-code--disabled '
                  : 'l-otp__resend-code--enabled'
              }`}
            >
              Click to resend
              {otpExpiry && otpExpiry !== 0 ? `(in ${otpExpiry}s)` : ''}
            </a>
          </div>

          <div className={`l-otp__btn-wrapper `}>
            <Button
              className="l-otp__btn l-otp__btn--cancel"
              loading={isOTPLoading}
              onClick={handleOTPCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleOTPValidation}
              className="l-otp__btn l-otp__btn--verify"
              loading={isOTPLoading}
              disabled={OTP && OTP?.length >= 6 ? false : true}
            >
              Verify
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OTPView;
