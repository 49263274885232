import { createSlice } from '@reduxjs/toolkit';

import {
  fetchFocusData,
  fetchYearlySavingsRequired,
  updateFocusData,
  updateYearlySavingsRequired,
} from './async_thunks';
import { FocusState } from './types';

const initialState: FocusState = {
  priceGrowth: [],
  rentalGrowth: [],
  yearlySavingsRequired: [],
};

export const focusSlice = createSlice({
  name: 'focus',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateFocusData.fulfilled, (state, action) => {
        state.priceGrowth = action.payload.priceGrowth;
        state.rentalGrowth = action.payload.rentalGrowth;
      })
      .addCase(fetchYearlySavingsRequired.fulfilled, (state, action) => {
        state.yearlySavingsRequired = action.payload;
      })
      .addCase(updateYearlySavingsRequired.fulfilled, (state, action) => {
        state.yearlySavingsRequired = action.payload;
      })
      .addCase(fetchFocusData.fulfilled, (state, action) => {
        state.priceGrowth = action.payload.priceGrowth;
        state.rentalGrowth = action.payload.rentalGrowth;
      });
  },
});

export default focusSlice.reducer;
