import 'chartjs-adapter-moment';

import { Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import BarGraph from '../../../foundation/components/bar_graph/BarGraph';
import { selectDashboardAnnualCashflowProjectionData } from '../redux/selectors';

const AnnualCashflowSection = () => {
  const performaceGraphData = useSelector(
    selectDashboardAnnualCashflowProjectionData,
  );

  /**
   * X-axis labels.
   */
  const labels = useMemo(() => {
    return performaceGraphData
      ? performaceGraphData.map((item) => moment(item.year))
      : [];
  }, [performaceGraphData]);

  /**
   * Formatted data for net cashflow graph.
   */
  const graphData = useMemo(() => {
    return performaceGraphData
      ? performaceGraphData.map((item) => ({
          y: item.annualCashFlow,
          x: moment(item.year),
        }))
      : [];
  }, [performaceGraphData]);

  /**
   * Formatted data used by the line graph.
   */
  const data = {
    labels,
    datasets: [
      {
        label: 'Net Monthly Cashflow ',
        data: graphData,
        segment: {},
        borderColor: 'rgba(0, 178, 163,0.7)',
        backgroundColor: 'rgba(0, 178, 163,0.7)',
        color: 'rgba(0,0,0,1)',
        pointRadius: 0,
      },
    ],
  };

  return (
    <Row className="dashboard__section dashboard__annual-cashflow-section">
      <div className="dashboard__header">
        <div className="dashboard__header-title dashboard__header-title--medium">
          Annual Net Cashflow
        </div>
      </div>
      <BarGraph
        xAxesLabel="Graph Timeline"
        yAxesLabel="Net Cashflow"
        data={data}
        graphTitle="Cashflow Projection"
        areaAboveFill="rgba(0, 178, 163,0.2)"
        xAxesUnit="year"
        xAxesType="time"
        titleMomentFormat="YYYY"
      />
    </Row>
  );
};

export default AnnualCashflowSection;
