import { Button, Col, Modal, notification, Row } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

import FormInputField from '../../../foundation/components/form_input_field/FormInputField';
import FormInputNumberField from '../../../foundation/components/form_input_number_field/FormInputNumberField';
import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import FormSelectField from '../../../foundation/components/form_select_field/FormSelectField';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { KeyValue } from '../../../foundation/types';
import formFieldRenderer from '../../../foundation/utils/formUtils';
import { percentageParser } from '../../../foundation/utils/helperFunctions';
import {
  selectPurchaseScenarioLoanTypeList,
  selectPurchaseScenarioPurchaseDate,
  selectPurchaseScenarioRedrawLoanOptions,
  selectPurchaseScenarioShortfallAmount,
} from '../redux/selectors';

type DraggableWrapperProps = { isDragginDisabled: boolean; modal: any };

const DraggableWrapper = ({
  isDragginDisabled,
  modal,
}: DraggableWrapperProps) => {
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef<HTMLDivElement | null>(null);

  const onStart = (_event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Draggable
      disabled={isDragginDisabled}
      bounds={bounds}
      onStart={(event, uiData) => onStart(event, uiData)}
    >
      <div ref={draggleRef}>{modal}</div>
    </Draggable>
  );
};

type Props = {
  visible?: true | false;
  closeModal: () => void;
  modalData?: any;
  onSubmit: (v: any) => void;
  modalTitle: string;
  validationSchema?: any;
  isDraggable?: boolean;
};

const LeverageEquityModal = ({
  visible = true,
  closeModal,
  modalData,
  modalTitle,
  onSubmit,
  validationSchema,
  isDraggable,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isDragginDisabled, disableDraggging] = useState(true);

  const redrawLoanOptions = useSelector(
    selectPurchaseScenarioRedrawLoanOptions,
  );

  const loanTypeList = useSelector(selectPurchaseScenarioLoanTypeList);

  const purchaseDate = useSelector(selectPurchaseScenarioPurchaseDate);

  const fundsRequired = useSelector(selectPurchaseScenarioShortfallAmount);

  const modalOpts = {
    open: visible,
    onCancel: () => {
      if (!isLoading) {
        closeModal();
      }
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    title: isDraggable ? (
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (isDragginDisabled) {
            disableDraggging(false);
          }
        }}
        onMouseOut={() => {
          disableDraggging(true);
        }}
      >
        {modalTitle}
      </div>
    ) : (
      modalTitle
    ),
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const switchBackDue = calcSwitchDue(values);

      const reqData = {
        ...values,
        switchBackDue,
      };

      await onSubmit(reqData);
      setIsLoading(false);
      closeModal();

      notification.success({
        message: 'Success!',
        description: 'Record updated successfully',
      });
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Equity',
        name: 'equity',
        key: 'equity',
        type: 'text',
        isRequired: true,
        disabled: true,
        requiredMessage: 'Equity is required',
      },
      {
        label: 'Amount Available',
        name: 'amountAvailable',
        key: 'amountAvailable',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Amount is required',
        addonBefore: '$',
        disabled: true,
      },
      {
        label: 'Baseline Refinancing Max LVR',
        name: 'baselineRefinancingMaxLvr',
        key: 'baselineRefinancingMaxLvr',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Baseline Refinancing Max LVR is required',
        addonBefore: '%',
        disabled: true,
        step: 0.1,
        min: 0,
        max: 100,
      },
      {
        label: 'Actual Refinancing Max LVR',
        name: 'actualRefinancingMaxLvr',
        key: 'actualRefinancingMaxLvr',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Actual Refinancing Max LVR is required',
        addonBefore: '%',
        step: 0.1,
        min: 0,
        max: 100,
      },
      {
        label: 'Redraw Loan Option',
        name: 'redrawLoanOption',
        key: 'redrawLoanOption',
        type: 'select',
        isRequired: true,
        requiredMessage: 'Redraw Loan Option is required',
        options: redrawLoanOptions,
      },
    ],
    [redrawLoanOptions],
  );

  const loanTypeOptions = useMemo(() => {
    const states: KeyValue[] = [];
    if (loanTypeList) {
      for (const item of loanTypeList) {
        states.push({
          key: item.apexLoanType,
          value: item.apexLoanType,
        });
      }
    }
    return states;
  }, [loanTypeList]);

  /**
   * Form fields
   */
  const loanTypeFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Product',
        name: 'product',
        key: 'product',
        type: 'text',
        placeholder: 'I/O',
        isRequired: true,
        requiredMessage: 'Product is required.',
        hasWrapperCol: true,
        disabled: true,
        colSpan: 11,
        colOffset: 2,
      },
      {
        label: 'Term (Years)',
        name: 'loanTerm',
        key: 'loanTerm',
        type: 'number-sd',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Term is required.',
        hasWrapperCol: true,
        disabled: false,
        colSpan: 11,
      },
      {
        label: 'Rate',
        name: 'loanRate',
        key: 'loanRate',
        type: 'number',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Loan Rate is required.',
        hasWrapperCol: true,
        disabled: false,
        colSpan: 11,
        colOffset: 2,
        addonBefore: '%',
        min: 0,
        max: 100,
        step: 0.1,
      },
    ],
    [],
  );

  const switchBackLoanFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Loan Product',
        name: 'switchBackProduct',
        key: 'switchBackProduct',
        type: 'text',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Loan product is required.',
        hasWrapperCol: true,
        colSpan: 11,
        // colOffset: 2,
        disabled: true,
      },
      {
        label: 'Interest Rate',
        name: 'switchBackRate',
        key: 'switchBackRate',
        type: 'number',
        placeholder: '',
        isRequired: true,
        requiredMessage: 'Interest Rate is required.',
        hasWrapperCol: true,
        colSpan: 11,
        colOffset: 2,
        addonBefore: '%',
        min: 0,
        max: 100,
        step: 0.1,
        // disabled: true,
      },
    ],
    [],
  );

  const calcSwitchDue = (values: any) => {
    const date = moment(purchaseDate);
    const month = date.format('MMMM');
    const year = date.format('YYYY');

    const term = values.switchBackTerm;

    if (year && month && term) {
      return `${month} ${parseInt(year) + parseInt(term)}`;
    } else if (year && month) {
      return `${month} ${parseInt(year)}`;
    }
  };

  const handleModalRendering = (modal: any) => {
    if (isDraggable) {
      return (
        <DraggableWrapper modal={modal} isDragginDisabled={isDragginDisabled} />
      );
    } else {
      return modal;
    }
  };

  /**
   * Formik form initialization
   */
  const formik = useFormik({
    initialValues: {
      redraw: undefined,
      ...modalData,
    },
    onSubmit: handleFormSubmit,
    // enableReinitialize: true,
    validationSchema: validationSchema,
  });

  const [
    values,
    handleBlur,
    errors,
    handleChange,
    setFieldValue,
    dirty,
    isValid,
    setValues,
  ] = useMemo(() => {
    return [
      formik.values,
      formik.handleBlur,
      formik.errors,
      formik.handleChange,
      formik.setFieldValue,
      formik.dirty,
      formik.isValid,
      formik.setValues,
    ];
  }, [formik]);

  useEffect(() => {
    if (values.actualRefinancingMaxLvr) {
      const actualRefinancinMaxLvr = values.actualRefinancingMaxLvr / 100;
      // This value is not parsed before sending in modal data therefore no need to divide by 100
      const currentLvr = modalData.currentLvr;

      const amount =
        (actualRefinancinMaxLvr - currentLvr) * modalData.propertyValue;

      /**
       * If value is negative set 0
       */
      if (amount <= 0) {
        setFieldValue('amountAvailable', 0);
      } else {
        const parsedAmount = Math.abs(parseFloat(`${amount}`))
          .toFixed(2)
          .toString();
        setFieldValue('amountAvailable', parsedAmount);
      }
    }
  }, [values.actualRefinancingMaxLvr, setFieldValue, modalData]);

  const handleLoanTypeChange = useCallback(
    (v: string) => {
      if (loanTypeList) {
        for (const i of loanTypeList) {
          if (i.apexLoanType === v) {
            setValues({
              ...values,
              loanRate: percentageParser(i.rate),
              loanTerm: i.term,
              loanType: v,
              product: i.product,
              switchBackTerm: i.switchBackTerm,
              switchBackProduct: i.switchBackProduct,
            });
          }
        }
      }
    },
    [loanTypeList, values, setValues],
  );

  const addMaxHandler = () => {
    const redrawLoanOption = values.redrawLoanOption;
    const amount = values.amountAvailable;
    const maximumLoanableAmount = values.amountAvailable;

    const redraw = modalData.redraw;
    let availableAmount = 0;
    if (redrawLoanOption === 'Split Loan') {
      availableAmount = maximumLoanableAmount;
    } else {
      availableAmount = amount;
    }
    if (fundsRequired) {
      const totalFundsRequired = fundsRequired - redraw;

      const remainingFromAvailable =
        parseFloat(availableAmount + '') + totalFundsRequired;

      let amountRequired = 0;

      if (remainingFromAvailable < 0) {
        amountRequired = availableAmount;
      } else {
        amountRequired =
          parseFloat(availableAmount + '') - remainingFromAvailable;
      }

      setFieldValue('redraw', parseFloat(amountRequired + '').toFixed(2));
    }
  };

  return (
    <Modal
      {...modalOpts}
      className="c-form-modal"
      modalRender={handleModalRendering}
    >
      {isLoading && <FullPageLoader />}

      <form onSubmit={formik.handleSubmit}>
        {formFieldRenderer(
          handleChange,
          values,
          errors,
          handleBlur,
          formFieldsArray,
          setFieldValue,
        )}

        <Row>
          {values.redrawLoanOption === 'Split Loan' && (
            <Col span={11}>
              <FormSelectField
                label="Loan Type"
                options={loanTypeOptions}
                value={values.loanType}
                onChange={handleLoanTypeChange}
                // error={errors.loanType}
              />
            </Col>
          )}

          {formFieldRenderer(
            handleChange,
            values,
            errors,
            handleBlur,
            values.redrawLoanOption === 'Split Loan' ? loanTypeFieldsArray : [],
            setFieldValue,
          )}

          {values.redrawLoanOption === 'Split Loan' &&
            values.product === 'I/O' && (
              <>
                <Col span={11}>
                  <FormInputField
                    name="switchBackTerm"
                    label="Switch Term (if I/O)"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.switchBackTerm}
                    error={errors.switchBackTerm}
                    disabled={values.product !== 'I/O'}
                  />
                </Col>
                <Col span={11} offset={2}>
                  <FormInputField
                    name="switchBackDue"
                    label="Switch Due"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={calcSwitchDue(values)}
                    error={errors.switchBackDue}
                    disabled
                  />
                </Col>
              </>
            )}

          {formFieldRenderer(
            handleChange,
            values,
            errors,
            handleBlur,
            values.redrawLoanOption === 'Split Loan' && values.product === 'I/O'
              ? switchBackLoanFieldsArray
              : [],
            setFieldValue,
          )}
          {values.redrawLoanOption === 'Split Loan' &&
            values.product === 'I/O' && (
              <Col span={11}>
                <FormInputField
                  name="switchBackLoanTerm"
                  label="Loan Term (Years)"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={
                    values?.loanTerm && values?.switchBackTerm
                      ? values?.loanTerm - values?.switchBackTerm
                      : ''
                  }
                  //   error={errors.switchBackLoanTerm}
                  disabled
                />
              </Col>
            )}
        </Row>

        <div className="purchase-scenario__redraw-wrapper">
          <FormInputNumberField
            name="redraw"
            label="Redraw"
            type="number"
            onChange={(v) => setFieldValue('redraw', v)}
            // onBlur={handleBlur}
            value={values.redraw}
            error={undefined}
            addonBefore="$"
          />
          <Button
            type="primary"
            className="purchase-scenario__add-max-btn"
            onClick={addMaxHandler}
          >
            Add Max
          </Button>
        </div>
        <div className="c-form-field__error">
          {/* @ts-ignore */}
          {errors.redraw ? errors.redraw : undefined}
        </div>
        <div className="c-form__btn-wrapper">
          <Button
            type="primary"
            htmlType="submit"
            disabled={!(isValid && dirty) || isLoading}
          >
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default LeverageEquityModal;
