import { getSessionClientID } from '../utils/api';

export default (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
      clientid: getSessionClientID(),
    },
  };
};
