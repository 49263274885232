import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectOffsetAccounts = (state: RootState) =>
  state.offset_account.list;

export const selectCurrentOffsetAccount = (state: RootState) =>
  state.offset_account.selected_offset_account;

export const selectOffsetAccountSourceAccounts = (state: RootState) =>
  state.offset_account.selected_offset_account?.accountsOffsetInfo
    .sourceAccounts;

export const selectOffsetAccountsInfo = (state: RootState) =>
  state.offset_account.selected_offset_account?.accountsOffsetInfo
    .offsetAccounts;
