import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../../foundation/components/form_modal/FormModal';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { updateCostsByStateData } from '../redux/async_thunks';
import { COST_BY_STATE_RATE } from '../redux/types';

const CostByStateTable = ({
  dataSource,
  isLoading,
  propertyType,
}: {
  dataSource: COST_BY_STATE_RATE[];
  isLoading: boolean;
  propertyType: string;
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const titlesToBold: string[] = [];

  const ColumnValueRenderer = (symbol) => (text: string, record: any) => {
    return colValueRenderer(
      text,
      record,
      symbol,
      titlesToBold,
      'lowerLimitAmount',
    );
  };

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      ellipsis: true,
    },
    {
      title: 'Council + Water Rates',
      dataIndex: 'councilWaterRates',
      key: 'councilWaterRates',
      ellipsis: true,
      render: ColumnValueRenderer('$'),
    },
    {
      title: 'Property Mgmt.',
      dataIndex: 'propertyManagement',
      key: 'propertyManagement',
      ellipsis: true,
      render: ColumnValueRenderer('%'),
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'surplusPartValueAmount',
      key: 'action',
      width: 80,
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="l-costs-by-lga__edit-record-btn"
          />
        );
      },
    },
  ];

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Council + Water Rates',
        name: 'councilWaterRates',
        key: 'councilWaterRates',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Council + Water Rates is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Property Mgmt.',
        name: 'propertyManagement',
        key: 'propertyManagement',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Property Mgmt. is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
    ],
    [],
  );

  const onEditRecord = (record: any) => () => {
    setModalData({
      ...record,
      propertyManagement: percentageParser(record.propertyManagement),
    });
    setModalVisibility(true);
  };

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleStampDutyEdit = async (values: any) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          const data = {
            ...values,
            propertyManagement: values?.propertyManagement / 100,
            userId: user.user_id,
            propertyType,
          };

          await dispatch(
            updateCostsByStateData({
              data: data,
              token: user?.token,
            }),
            // @ts-ignore
          ).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Stamp Duty`}
          onSubmit={handleStampDutyEdit}
          formFieldsArray={formFieldsArray}
        />
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size={useTableSize()}
        loading={isLoading}
        scroll={{ x: true }}
      />
    </>
  );
};

export default CostByStateTable;
