import * as Yup from 'yup';

const InfoValidationSchema = () => {
  const requiredMessage = `This field is required`;
  const percentageFieldRequiredMessage = `Field must be in between 0-100`;
  const fields: any = {
    propertyName: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(4, 'Name must be at least 4 characters'),
    isActive: Yup.boolean().required(requiredMessage),
    state: Yup.string().required(requiredMessage).nullable(),
    focus: Yup.string().nullable(),
    fhog: Yup.number().nullable(),
    propertyType: Yup.string().nullable(),
    valueAddTimingMonth: Yup.string().nullable(),
    valueAddTimingYear: Yup.string().nullable(),
    purchasePrice: Yup.number().required(requiredMessage).nullable(),
    valuationAtPurchase: Yup.number().required(requiredMessage).nullable(),
    lvrIfFunded: Yup.number()
      .min(0, percentageFieldRequiredMessage)
      .max(100, percentageFieldRequiredMessage),
    maxLvr: Yup.number()
      .min(0, percentageFieldRequiredMessage)
      .max(100, percentageFieldRequiredMessage)
      .nullable(),
    lvr: Yup.number()
      .min(0, percentageFieldRequiredMessage)
      .max(100, percentageFieldRequiredMessage)
      .nullable(),
    switchBackRate: Yup.number()
      .min(0, percentageFieldRequiredMessage)
      .max(100, percentageFieldRequiredMessage),
    rentalYield: Yup.number().min(0, 'Field should not be less than 0'),
    sellProperty: Yup.boolean(),
    sellDate: Yup.string().when('sellProperty', {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().optional().nullable(),
    }),
    sellAmount: Yup.string().when('sellProperty', {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().optional().nullable(),
    }),
    sellReason: Yup.string().when('sellProperty', {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().optional().nullable(),
    }),
    lmiAmount: Yup.number().when('lmiPaymentMode', {
      is: 'Add To Loan Amount',
      then: Yup.number()
        .required(requiredMessage)
        .min(0, 'Field should not be less than 0')
        .nullable(),
      otherwise: Yup.number().optional().nullable(),
    }),
  };

  return Yup.object().shape(fields);
};

export default InfoValidationSchema;
