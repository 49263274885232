import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectPlanProperties = (state: RootState) => state.property.list;

export const selectCostByStateValues = (state: RootState) =>
  state.property.form_inputs?.costByStateValues.states;

export const selectMonthsList = (state: RootState) =>
  state.property.form_inputs?.startMonth;

export const selectPropertyTypeList = (state: RootState) =>
  state.property.form_inputs?.apexPropertyTypeValues.propertyTypeList;

export const selectFocusPropertyTypeList = (state: RootState) =>
  state.property.form_inputs?.apexPropertyTypeValues.focusPropertyTypeList;

export const selectPaymentStatusTypeValues = (state: RootState) =>
  state.property.form_inputs?.paymentStatusTypeValues;

export const selectLoanTypeList = (state: RootState) =>
  state.property.form_inputs?.loanTypeList;

export const selectPropertyPurchaseTypeValues = (state: RootState) =>
  state.property.form_inputs?.propertyPurchaseType;

export const selectPerformanceTimelineTypes = (state: RootState) =>
  state.property.form_inputs?.performanceTimelineTypes;

export const selectPropertyApexInputs = (state: RootState) =>
  state.property.property?.apexInputs;

export const selectPropertySellValues = (state: RootState) =>
  state.property.property?.sellValues;

export const selectPropertyIsSold = (state: RootState) =>
  state.property.property?.isSold;

export const selectPropCashReqIsSold = (state: RootState) =>
  state.property.purchase?.isSold;

export const selectPropAnnualFigureIsSold = (state: RootState) =>
  state.property.annual_figures?.isSold;

export const selectPropertyIsPassed = (state: RootState) =>
  state.property.property?.isPassed;

export const selectPropertyPurchaseType = (state: RootState) =>
  state.property.property?.purchaseType;

export const selectPropertyPurchase = (state: RootState) =>
  state.property.purchase;

export const selectPurchasePaymentCategories = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo.paymentCategories;

export const selectPurchasePaymentSchedules = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo.paymentSchedules;

export const selectPurchasePaymentSources = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo.paymentSource;

export const selectPurchaseEngagement = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo.engagementFeesInfo;

export const selectPurchaseConditionalExchange = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo.conditionalExchangeFeesInfo;

export const selectPurchaseUnconditionalExchange = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo
    .unconditionalExchangeFeesInfo;

export const selectPurchaseSettlement = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo.settlementFeesInfo;

export const selectPurchasePostSettlement = (state: RootState) =>
  state.property.purchase?.cashRequiredEstimateInfo.postSettlementFeesInfo;

export const selectPurchasePropertyName = (state: RootState) =>
  state.property.purchase?.propertyName;

export const selectPurchasePropertyContractDate = (state: RootState) =>
  state.property.purchase?.contractDate;

export const selectAnnualFiguresCashIn = (state: RootState) =>
  state.property.annual_figures?.cashIn;

export const selectAnnualFiguresCashOut = (state: RootState) =>
  state.property.annual_figures?.cashOut;

export const selectAnnualFiguresGrowthProjections = (state: RootState) =>
  state.property.annual_figures?.annualGrowthProjections;

export const selectAnnualFiguresInterestRateProjections = (state: RootState) =>
  state.property.annual_figures?.annualInterestRates;

export const selectAnnualFiguresPerformanceAssumptions = (state: RootState) =>
  state.property.annual_figures?.performanceAssumptions;

export const selectAnnualFiguresPropertyName = (state: RootState) =>
  state.property.annual_figures?.propertyName;

export const selectAnnualFiguresContractDate = (state: RootState) =>
  state.property.annual_figures?.contractDate;

export const selectPreviousPropertyUnderContractDate = (state: RootState) =>
  state.property.property?.previousUnderContractDate;

export const selectPropertyContractDate = (state: RootState) =>
  state.property.property?.contractDate;

export const selectedPlanOffsetAccounts = (state: RootState) =>
  state.property.offset_accounts;

export const selectedPlanRecords = (state: RootState) =>
  state.property.planRecords;

export const selectedOffsetAccountTransactionTypes = (state: RootState) =>
  state.property.form_inputs?.offsetAccountsTransactionValues.transactionType;

export const selectedOffsetAccountFromToOptions = (state: RootState) =>
  state.property.form_inputs?.offsetAccountsTransactionValues.fromTo;

export const selectedFocusTypeOptions = (state: RootState) =>
  state.property.form_inputs?.focusValues.focusType;

export const selectSellFeatureCGTDiscountOptions = (state: RootState) =>
  state.property.form_inputs?.sellFeatureCGTDiscountOptions;

export const selectClientRiskProfileListOptions = (state: RootState) =>
  state.property.form_inputs?.planInputsValues.clientRiskProfileList;

export const selectFinancialGoalListOptions = (state: RootState) =>
  state.property.form_inputs?.planInputsValues.financialGoalList;

export const selectOptimiseForListOptions = (state: RootState) =>
  state.property.form_inputs?.planInputsValues.optimiseForList;

export const selectLmiPaymentModeListOptions = (state: RootState) =>
  state.property.form_inputs?.planInputsValues.lmiPaymentModeList;

export const selectClientRiskProfileValues = (state: RootState) =>
  state.property.form_inputs?.planInputsValues.clientRiskProfileValues;

export const selectPropertyClientRefinancingMaxLvr = (state: RootState) =>
  state.property.property?.clientRefinancingMaxLvr;

export const selectPropertyMaxClientPurchaseLvr = (state: RootState) =>
  state.property.property?.maxClientPurchaseLvr;
