import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetch-data',
  async (apiParams: { token: string; values: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedValues = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Dashboard`,
      encryptedValues,
      options,
    );

    const data = decryptData(response.data);
    return data;
  },
);

export const sharePlanDashboard = createAsyncThunk(
  'dashboard/share',
  async (apiParams: { token: string; values: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };
    const encryptedValues = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Dashboard/share`,
      encryptedValues,
      options,
    );

    const data = decryptData(response.data);
    return data;
  },
);
