import { Table } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import { colValueRenderer } from '../../../foundation/utils/helperFunctions';
import { selectPurchaseScenarioSummary } from '../redux/selectors';

type Props = {
  isLoading: boolean;
  propertyId: string;
};

const Summary = ({ isLoading, propertyId }: Props) => {
  const dataSource = useSelector(selectPurchaseScenarioSummary);

  const titlesToBold = ['Total Investment'];

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
      ellipsis: true,
      width: 250,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, undefined, titlesToBold, 'items');
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'items');
      },
    },
  ];

  return (
    <div className="purchase-scenario__section">
      <div className="purchase-scenario__section-title">Summary</div>
      <Table
        columns={columns}
        dataSource={dataSource}
        size={useTableSize()}
        pagination={false}
        scroll={{ x: true }}
        bordered
      />
    </div>
  );
};

export default Summary;
