import { Row, Table } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { colValueRenderer } from '../../../foundation/utils/helperFunctions';
import { selectDashboardPortfolioPerformance } from '../redux/selectors';

const PortfolioPerformanceSection = ({
  timeline,
}: {
  timeline: number | undefined;
}) => {
  const dataSource = useSelector(selectDashboardPortfolioPerformance);

  const titlesToBold: string[] = [];
  /**
   * Data table's columns.
   */
  const columns: any = useMemo(() => {
    const cols: any = [
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        ellipsis: true,
        width: 200,
        fixed: 'left',
        render: (text: string, record: any) => {
          return colValueRenderer(
            text,
            record,
            undefined,
            titlesToBold,
            'details',
          );
        },
      },
    ];

    if (dataSource) {
      const row = dataSource[0];
      const keys = Object.keys(row);

      for (const key of keys) {
        if (key !== 'unit' && key !== 'year' && key !== 'kpi') {
          cols.push({
            title: key,
            dataIndex: key,
            key: key,
            ellipsis: true,
            render: (text: string, record: any) =>
              colValueRenderer(
                text,
                record,
                record.unit,
                titlesToBold,
                'details',
              ),
          });
        }
      }
    }

    return cols;
  }, [dataSource]);

  const scroll = useMemo(() => {
    if (timeline) {
      if (timeline < 10) {
        return {};
      } else if (timeline === 10) {
        return { x: 1700 };
      } else if (timeline === 15) {
        return { x: 2200 };
      } else if (timeline === 20) {
        return { x: 2800 };
      } else if (timeline === 25) {
        return { x: 3200 };
      } else if (timeline === 30) {
        return { x: 4000 };
      }
    }
  }, [timeline]);

  return (
    <Row className="dashboard__section dashboard__portfolio-performance-section">
      <div className="dashboard__header">
        <div className="dashboard__header-title dashboard__header-title--medium">
          How does the Portfolio Perform?
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        // loading={isLoading}
        size="middle"
        pagination={false}
        scroll={scroll}
      />
    </Row>
  );
};

export default PortfolioPerformanceSection;
