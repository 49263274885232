export const addItemToStorage = (key: string, data: string) => {
  return sessionStorage.setItem(key, data);
};

export const removeItemToStorage = (key: string) => {
  return sessionStorage.removeItem(key);
};

export const getItemFromStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

export const clearStorage = () => {
  return sessionStorage.clear();
};
