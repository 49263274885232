import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectStampDuties = (state: RootState) =>
  state.stamp_and_lmi.stamp_duties;

export const selectLMIRates = (state: RootState) =>
  state.stamp_and_lmi.lmi_rates;

export const selectCostByStatesData = (state: RootState) =>
  state.stamp_and_lmi.cost_by_state;
