import { createSlice } from '@reduxjs/toolkit';

import { fetchRiskProfileData, updateRiskProfile } from './async_thunks';
import { RiskProfileState } from './types';

const initialState: RiskProfileState = {
  list: [],
};

export const riskProfileSlice = createSlice({
  name: 'risk_profile',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRiskProfileData.fulfilled, (state, action) => {
        state.list = action.payload.riskProfiles;
      })
      .addCase(updateRiskProfile.fulfilled, (state, action) => {
        state.list = action.payload.riskProfiles;
      });
  },
});

export default riskProfileSlice.reducer;
