import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useClientIP } from '../../foundation/cutom_hooks/useClientIP';
import { selectUser } from '../authentication/redux/selectors';
import OTPView from './OTPView';
import { sendOTP } from './redux/async_thunks';
import { selectClientDashboardReportToken } from './redux/selectors';
import { setClientDashboardLinkData } from './redux/slice';

const ClientDashboardStartup = () => {
  const clientIp = useClientIP();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [linkId, setLinkId] = useState<any>(undefined);

  const [, set2FAVisibility] = useState(true);

  const reportToken = useSelector(selectClientDashboardReportToken);

  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector(selectUser);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.t) {
      setLinkId(parsed.t);
    } else if ((reportToken && reportToken.token) || (user && user.token)) {
      setIsLoading(false);
      return;
    } else {
      navigate('/client/login');
    }
  }, [location.search]);

  const handleClientReportRenderingLogic = async () => {
    try {
      // @ts-ignore
      const linkData = await dispatch(sendOTP({ linkId })).unwrap();
      if (linkData) {
        dispatch(setClientDashboardLinkData({ ...linkData }));
        if (!linkData.internalShare && linkData.createPassword) {
          navigate('/client/join');
        } else if (!linkData.internalShare && !linkData.createPassword) {
          navigate('/client/login');
        } else if (linkData.internalShare) {
          setIsLoading(false);
        }
      } else if (!user) {
        navigate('/client/login');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      navigate('/client/login');
    }
  };

  useEffect(() => {
    if (linkId && clientIp) {
      handleClientReportRenderingLogic();
    }
  }, [linkId, clientIp]);

  if (isLoading) {
    return <FullPageLoader classNames="dashboard__loader" />;
  }
  return (
    <div className="dashboard">
      <OTPView set2FAVisibility={set2FAVisibility} />
    </div>
  );
};

export default ClientDashboardStartup;
