import { OffsetAccount } from '../redux/types';

export const handleTotalForAccountOffset = (sourceArray: OffsetAccount[]) => {
  const newSourceArr = [...sourceArray];

  const totalRow: OffsetAccount = {
    rowId: newSourceArr.length,
    propertyName: 'Total',
    accountBalance: 0,
    transactionType: '',
    fromTo: '',
    use: 0,
    newAccountBalance: 0,
    isEditable: false,
    editableFields: [],
    maximumDeposit: 0,
  };

  if (newSourceArr[newSourceArr.length - 1].propertyName === 'Total') {
    newSourceArr[newSourceArr.length - 1] = totalRow;
  } else {
    newSourceArr.push(totalRow);
  }

  for (const acc of newSourceArr) {
    if (acc.propertyName !== 'Total') {
      totalRow.accountBalance += acc.accountBalance;
      totalRow.use += acc.use;
      totalRow.newAccountBalance += acc.newAccountBalance;
    }
  }

  return newSourceArr;
};
