import { Button, Modal, notification } from 'antd';
import { Formik } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

import FormInputNumberField from '../../../foundation/components/form_input_number_field/FormInputNumberField';
import { FieldType } from '../../../foundation/components/form_modal/form_modal_types';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import formFieldRenderer from '../../../foundation/utils/formUtils';
import { selectPurchaseScenarioShortfallAmount } from '../redux/selectors';

type Props = {
  visible?: true | false;
  closeModal: () => void;
  modalData?: any;
  onSubmit: (v: any) => void;
  modalTitle: string;
  validationSchema?: any;
  isDraggable?: boolean;
};

const LeverageCashModal = ({
  visible = true,
  closeModal,
  modalData,
  modalTitle,
  onSubmit,
  validationSchema,
  isDraggable,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isDragginDisabled, disableDraggging] = useState(true);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const fundsRequired = useSelector(selectPurchaseScenarioShortfallAmount);

  const draggleRef = useRef<HTMLDivElement | null>(null);

  const modalOpts = {
    open: visible,
    onCancel: () => {
      if (!isLoading) {
        closeModal();
      }
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    title: isDraggable ? (
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (isDragginDisabled) {
            disableDraggging(false);
          }
        }}
        onMouseOut={() => {
          disableDraggging(true);
        }}
      >
        {modalTitle}
      </div>
    ) : (
      modalTitle
    ),
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await onSubmit({ ...values });
      setIsLoading(false);
      closeModal();

      notification.success({
        message: 'Success!',
        description: 'Record updated successfully',
      });
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  const onStart = (_event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  /**
   * Form fields
   */
  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Cash Source',
        name: 'cashSource',
        key: 'cashSource',
        type: 'text',
        isRequired: true,
        requiredMessage: 'Cash Source is required',
      },
      {
        label: 'Amount Available',
        name: 'amount',
        key: 'amount',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Amount is required',
        addonBefore: '$',
      },
      //   {
      //     label: 'Use',
      //     name: 'use',
      //     key: 'use',
      //     type: 'number',
      //     isRequired: true,
      //     requiredMessage: 'Use is required',
      //     addonBefore: '$',
      //   },
    ],
    [],
  );

  // const addMaxHandler = (setFieldValue, amount) => () => {
  //   setFieldValue('use', amount);
  // };

  const addMaxHandler = (setFieldValue, amount) => () => {
    const redraw = modalData.use;
    let availableAmount = 0;

    availableAmount = amount;

    if (fundsRequired && Math.abs(fundsRequired) !== parseFloat(redraw)) {
      const totalFundsRequired = Math.abs(fundsRequired) + parseFloat(redraw);

      const remainingFromAvailable =
        parseFloat(availableAmount + '') - totalFundsRequired;

      let amountRequired = 0;

      if (remainingFromAvailable < 0) {
        amountRequired = availableAmount;
      } else {
        amountRequired =
          parseFloat(availableAmount + '') - remainingFromAvailable;
      }

      setFieldValue('use', parseFloat(amountRequired + '').toFixed(2));
    }
  };
  return (
    <Modal
      {...modalOpts}
      className="c-form-modal"
      modalRender={(modal) => {
        if (isDraggable) {
          return (
            <Draggable
              disabled={isDragginDisabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          );
        } else {
          return modal;
        }
      }}
    >
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          ...modalData,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        // enableReinitialize
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {formFieldRenderer(
                handleChange,
                values,
                errors,
                handleBlur,
                formFieldsArray,
                setFieldValue,
              )}

              <div className="purchase-scenario__redraw-wrapper">
                <FormInputNumberField
                  name="use"
                  label="Use"
                  type="number"
                  onChange={(v) => setFieldValue('use', v)}
                  // onBlur={handleBlur}
                  value={values.use}
                  error={undefined}
                  addonBefore="$"
                />
                <Button
                  type="primary"
                  className="purchase-scenario__add-max-btn"
                  onClick={addMaxHandler(setFieldValue, values.maxUsableAmount)}
                >
                  Add Max
                </Button>
              </div>
              <div className="c-form-field__error">
                {errors.use ? errors.use : undefined}
              </div>
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Update
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default LeverageCashModal;
