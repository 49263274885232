import { DollarOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DollarBag from '../../foundation/assets/svgs/DollarBag.svg';
import TwoBars from '../../foundation/assets/svgs/TwoBars.svg';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import CustomSelect from '../../foundation/components/select/Select';
import YearField from '../../foundation/components/year_field/YearField';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import { selectPlan } from '../plan/redux/selectors';
import { selectPerformanceTimelineTypes } from '../property/redux/selectors';
import AnnualCashflowSection from './elements/AnnualCashflowSection';
import EquityGrowthSection from './elements/EquityGrowthSection';
import MonthlyCashflowSection from './elements/MonthlyCashflowSection';
import NextPurchaseCard from './elements/NextPurchaseCard';
import PortfolioPerformanceSection from './elements/PortfolioPerformanceSection';
import ProgressCard from './elements/ProgressCard';
import PurchaseRoadmapSection from './elements/PurchaseRoadmapSection';
import ShareReportModal from './elements/ShareReportModal';
import StatsCard from './elements/StatsCard';
import { fetchDashboardData } from './redux/async_thunks';
import {
  selectDashboardAchievedYear,
  selectDashboardAssetResult,
  selectDashboardCashflowResult,
  selectDashboardNumberOfProperties,
  selectDashboardPlanTargetCalendarYear,
  selectDashboardPortfolioValue,
  selectDashboardTimelineYears,
  selectDashboardTotalDebt,
  selectDashboardTotalEquity,
} from './redux/selectors';
import { resetDashboard } from './redux/slice';

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const [timeline, setTimeline] = useState<string | undefined>(undefined);

  const user = useSelector(selectUser);

  const plan = useSelector(selectPlan);

  const cashFlowResult = useSelector(selectDashboardCashflowResult);

  const targetCalendarYear = useSelector(selectDashboardPlanTargetCalendarYear);

  const selectedAchievedYear = useSelector(selectDashboardAchievedYear);

  const performanceTimelineYears = useSelector(selectPerformanceTimelineTypes);

  const timelineYears = useSelector(selectDashboardTimelineYears);

  const assetResult = useSelector(selectDashboardAssetResult);
  const numOfProperties = useSelector(selectDashboardNumberOfProperties);
  const portfolioValue = useSelector(selectDashboardPortfolioValue);
  const totalDebt = useSelector(selectDashboardTotalDebt);
  const totalEquity = useSelector(selectDashboardTotalEquity);

  const [achievedYear, setAchievedYear] = useState<string | null>(null);

  useEffect(() => {
    if (selectedAchievedYear) {
      setAchievedYear(`${selectedAchievedYear}`);
    }
  }, [selectedAchievedYear]);

  useEffect(() => {
    if (timelineYears) {
      setTimeline(`${timelineYears}`);
    }
  }, [timelineYears]);

  /**
   * Fetches the dashboard data
   */
  const fetchData = async (
    passedTimeline?: number,
    passedAchievedYear?: string,
  ) => {
    try {
      if (user?.token) {
        setIsLoading(true);

        let years: any = null;

        /**
         * If we have a passedTimeline value then we will use that
         * otherwise we will use the timeline state variable. We have to do it
         * because we are using this function on change of timeline dropdown.
         */
        if (passedTimeline) {
          years = passedTimeline;
        } else if (timeline) {
          years = parseInt(timeline);
        }

        const reqData = {
          planId: plan?.planId,
          userId: user.user_id,
          timelineYears: years,
          achievedYear: passedAchievedYear || achievedYear,
        };

        await dispatch(
          fetchDashboardData({ token: user.token, values: reqData }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(
    () => () => {
      dispatch(resetDashboard());
    },
    [],
  );

  const onShareClick = async () => {
    setIsShareModalVisible(true);
  };

  const onShareModalClose = () => {
    setIsShareModalVisible(false);
  };

  const handleTimelineChange = (v: string) => {
    setTimeline(v);
    fetchData(parseInt(v));
  };

  const handleAchievedYearChange = (date: Dayjs) => {
    setAchievedYear(date.format('YYYY'));
    fetchData(undefined, date.format('YYYY'));
  };

  return (
    <div className="dashboard">
      {isLoading && <FullPageLoader classNames="dashboard__loader" />}
      {isShareModalVisible && (
        <ShareReportModal closeModal={onShareModalClose} />
      )}
      <div className="dashboard__header">
        <div className="dashboard__header-title">
          Property Portfolio Dashboard
        </div>
        <div className="dashboard__header-fields">
          <div className="dashboard__header-field" style={{ marginRight: 20 }}>
            <div className="dashboard__header-field-label">Target Year:</div>
            <Input
              value={targetCalendarYear}
              disabled
              style={{ width: 80, height: 36 }}
            />
          </div>
          <div className="dashboard__header-field" style={{ marginRight: 20 }}>
            <div className="dashboard__header-field-label">Achieved Year:</div>
            <YearField
              onChange={handleAchievedYearChange}
              style={{ height: 36, width: 100 }}
              picker="year"
              value={dayjs(achievedYear, 'YYYY')}
              className="c-date-field"
            />
          </div>
          <div className="dashboard__header-field">
            <div className="dashboard__header-field-label">
              Dashboard Timeline:
            </div>
            <div>
              <CustomSelect
                placeholder="Years"
                options={performanceTimelineYears || []}
                className="cashflow-projection__performace-timeline-select"
                onChange={handleTimelineChange}
                value={timeline}
              />
            </div>
          </div>
          <div className="dashboard__header-field">
            <Button
              className="dashboard__header-share"
              type="primary"
              onClick={onShareClick}
            >
              Share
            </Button>
          </div>
        </div>
      </div>
      <div className="dashboard__content-wrapper">
        <Row gutter={[20, 16]} style={{ marginBottom: 20 }}>
          <Col span={17}>
            <Row gutter={[20, 16]} style={{ marginBottom: 20 }}>
              <Col span={12}>
                <ProgressCard
                  percentage={cashFlowResult?.percentage}
                  actual={cashFlowResult?.actual}
                  goal={cashFlowResult?.goal}
                  percentageTitle="Portfolio Cash Flow"
                  actualValueLabel="Annual Cashflow at Achieved Year"
                />
              </Col>
              <Col span={12}>
                <ProgressCard
                  percentage={assetResult?.percentage}
                  actual={assetResult?.actual}
                  goal={assetResult?.goal}
                  percentageTitle="Portfolio Equity"
                  strokeColor="#FFB319"
                  actualValueLabel="Portfolio Equity at Achieved Year"
                />
              </Col>
            </Row>
            <Row gutter={[20, 16]} style={{ marginBottom: 20 }}>
              <Col span={6}>
                <StatsCard
                  icon={<HomeOutlined />}
                  label="Properties Owned"
                  stats={numOfProperties ? `${numOfProperties}` : ''}
                  formatNumber={false}
                  background={`#E6FFFB`}
                  color={`#36CFC9`}
                />
              </Col>
              <Col span={6}>
                <StatsCard
                  icon={<DollarOutlined />}
                  label="Portfolio Value"
                  stats={portfolioValue ? `${portfolioValue}` : ''}
                  formatNumber={true}
                  background={`#E6F7FF`}
                  color={`#40A9FF`}
                />
              </Col>
              <Col span={6}>
                <StatsCard
                  icon={<DollarBag />}
                  label="Total Debt"
                  stats={totalDebt ? `${totalDebt}` : ''}
                  formatNumber={true}
                  background={`#F6FFED`}
                  color={`#73D13D`}
                />
              </Col>
              <Col span={6}>
                <StatsCard
                  icon={<TwoBars />}
                  label="Total Equity"
                  stats={totalEquity ? `${totalEquity}` : ''}
                  formatNumber={true}
                  background={`#FFFBE6`}
                  color={`#FFC53D`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={7}>
            <NextPurchaseCard />
          </Col>
        </Row>
        <PurchaseRoadmapSection />
        <PortfolioPerformanceSection
          timeline={timeline ? parseInt(timeline) : undefined}
        />
        <AnnualCashflowSection />
        <MonthlyCashflowSection />
        <EquityGrowthSection />
        {/* <AboutPropertiesDetails /> */}
      </div>
    </div>
  );
};

export default Dashboard;
