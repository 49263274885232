import { Button, Result } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

// Display when the route doesn't exists.
export default (): JSX.Element => {
  const navigate = useNavigate();

  const location = useLocation();

  const pathname = useMemo(() => location.pathname, [location]);

  /**
   * We have to add this logic because the property routes are only available
   * once the user selects a particular plan and is authenticated.
   */
  useEffect(() => {
    if (pathname.includes('properties')) {
      navigate('/');
    }
  }, [location]);

  if (pathname.includes('properties')) {
    return <></>;
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          Back Home
        </Button>
      }
    />
  );
};
