import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const forgotPassFormValidationSchema = () => {
  const fields: any = {
    email: Yup.string()
      .email('Please enter a valid email.')
      .required(requiredMessage)
      .nullable(),
  };

  return Yup.object().shape(fields);
};
