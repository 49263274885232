import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchClientData } from '../../features/authentication/redux/async_thunks';
import { getItemFromStorage } from '../utils/storageHandler';

export const useClientIP = () => {
  const [clientIp, setClientIp] = useState<string | null>(null);

  const dispatch = useDispatch();
  // get clientip first if user IP is not existing
  const getClientIp = async () => {
    try {
      const client_data = await getItemFromStorage('client_ip');

      if (!client_data) {
        const response = await dispatch(
          fetchClientData(), // @ts-ignore
        ).unwrap();
        if (response) {
          setClientIp(response);
        }
      } else {
        setClientIp(client_data);
      }
    } catch (error) {
      setClientIp(null);
    }
  };

  useEffect(() => {
    getClientIp();
  }, []);

  return clientIp;
};
