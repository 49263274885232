import React from 'react';

import Image from '../../../foundation/assets/images/AuthFormImage.png';

const AuthenticationPageImage = () => {
  return (
    <div
      className="client-dash-login__image-wrapper"
      style={{
        backgroundImage: `url(${Image})`,
      }}
    ></div>
  );
};

export default AuthenticationPageImage;
