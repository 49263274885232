import * as Yup from 'yup';

const PlanNameValidationSchema = () => {
  const fields: any = {
    planName: Yup.string()
      .trim()
      .required('Name is required')
      .min(4, 'Name must be at least 4 characters'),
  };

  return Yup.object().shape(fields);
};

export default PlanNameValidationSchema;
