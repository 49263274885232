import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchCostsByLGA = createAsyncThunk(
  'costs-by-lga/get',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/cost-by-lga`,
      options,
    );
    const data = decryptData(response.data);

    return data.costByLga;
  },
);

export const updateCostsByLGA = createAsyncThunk(
  'costs-by-lga/update',
  async (apiParams: { data: string; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/cost-by-lga`,
      encryptedData,
      options,
    );
    const data = decryptData(response.data);

    return data.costByLga;
  },
);
