import * as Yup from 'yup';

const PropertyTypeValidationSchema = () => {
  const fields: any = {
    transactionDate: Yup.string().required('Date is required'),
  };

  return Yup.object().shape(fields);
};

export default PropertyTypeValidationSchema;
