import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';

import axios from '../../../foundation/config/axios';
import { encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchClientPurchaseReport: any = createAsyncThunk(
  'report_download/client-purchase',
  async (apiParams: { token: string; data: any; format: string }) => {
    const MONTH_FILENAME_FORMAT = 'MM_YYYY';

    const options: AxiosRequestConfig = {
      ...getBasicAuthAPIOptions(apiParams.token),
      responseType: 'blob',
    };

    const encryptedData = encryptData(apiParams.data);

    await axios
      .post(
        `${env_constants.PP_API_BASE_URL}/Report/client-purchase-report`,
        encryptedData,
        options,
      )
      .then((response) => {
        const contentType = response.headers['content-type'];

        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        const dateObject = dayjs(
          apiParams.data.reportMonth,
          apiParams.format,
        ).toDate();

        const formattedDate = dayjs(dateObject).format(MONTH_FILENAME_FORMAT);

        link.href = url;
        link.setAttribute(
          'download',
          `Client_Purchase_Report_${formattedDate}.csv`,
        );

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        if (
          error.response.status === 404 &&
          error.response.config.responseType === 'blob'
        ) {
          notification.warning({
            message: 'Not Available',
            description: 'No records found for the selected purchase month.',
          });
        }

        return Promise.reject(error);
      });

    return;
  },
);
