import { Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { colValueRenderer } from '../../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { updateAnnualFiguresGrowthProjectionsBulk } from '../../redux/async_thunks';
import { selectAnnualFiguresGrowthProjections } from '../../redux/selectors';
import { GrowthProjections } from '../../redux/types';
import EditableTable from './EquityProjectionEditableTable';

type Props = {
  isLoading: boolean;
  propertyId: string;
  isClientView: boolean;
};

const GrowthProjectionsSection = ({
  isLoading,
  propertyId,
  isClientView,
}: Props) => {
  const dispatch = useAppDispatch();

  const [dataSource, setDataSource] = useState<GrowthProjections[]>([]);

  const [error, setError] = useState<boolean>(true);

  const [isEquityProjectionLoading, setIsEquityProjectionLoading] =
    useState(false);

  const initialSource = useSelector(selectAnnualFiguresGrowthProjections);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (initialSource) {
      setDataSource(initialSource);
    }
  }, [initialSource]);

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Years',
      dataIndex: 'years',
      key: 'years',
      ellipsis: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, undefined, [], 'years');
      },
    },
    {
      title: 'Price Growth',
      dataIndex: 'priceGrowth',
      key: 'priceGrowth',
      ellipsis: true,
      editable: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', [], 'years');
      },
    },
    {
      title: 'Rental Growth',
      dataIndex: 'rentalGrowth',
      key: 'rentalGrowth',
      ellipsis: true,
      editable: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', [], 'years');
      },
    },
  ];

  const handleSave = async () => {
    if (isLoading || isEquityProjectionLoading || error || isClientView) {
      return;
    }
    try {
      if (user?.token) {
        setIsEquityProjectionLoading(true);
        const annualGrowthRates: {
          yearId: number;
          equityRate: number;
          rentalRate: number;
        }[] = [];

        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].isEditable && dataSource[i].rowId !== 1) {
            annualGrowthRates.push({
              yearId: i,
              equityRate: dataSource[i].priceGrowth,
              rentalRate: dataSource[i].rentalGrowth,
            });
          }
        }
        const data = {
          annualGrowthRates,
          userId: user.user_id,
          propertyId,
        };

        await dispatch(
          updateAnnualFiguresGrowthProjectionsBulk({
            values: data,
            token: user.token,
          }),
          // @ts-ignore
        ).unwrap();

        setIsEquityProjectionLoading(false);

        notification.success({
          message: 'Success!',
          description: 'Record updated successfully',
        });

        setError(true);
      }
    } catch (error) {
      setIsEquityProjectionLoading(false);
      console.log(error);
    }
  };

  const updateSource = (source: GrowthProjections[]) => {
    setDataSource(source);
  };

  return (
    <div className="annual-figures__section annual-figures__section--dual">
      <div className="annual-figures__section-header">
        <div className="annual-figures__section-title">Growth Projections</div>
        <div className="annual-figures__section-title">
          <Button
            type="primary"
            onClick={handleSave}
            disabled={error || isClientView}
          >
            Update
          </Button>
        </div>
      </div>

      <EditableTable
        defaultColumns={columns}
        dataSource={dataSource}
        updateSource={updateSource}
        isLoading={isEquityProjectionLoading}
        setError={setError}
        isClientView={isClientView}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default GrowthProjectionsSection;
