import { Buffer } from 'buffer';
import query_string from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import NotFound from '../../foundation/components/not_found/NotFound';
import { RouteType, useRoutes } from '../../foundation/config/routes';
import { useClientIP } from '../../foundation/cutom_hooks/useClientIP';
import { useRole } from '../../foundation/cutom_hooks/useRole';
import { getItemFromStorage } from '../../foundation/utils/storageHandler';
import env_constants from '../../internals/env/env_constants.json';
import { useAppDispatch } from '../../store/hooks';
import { refreshToken } from '../authentication/redux/async_thunks';
import { selectUser } from '../authentication/redux/selectors';
import { User } from '../authentication/redux/types';
import { setClient } from '../client/redux/slice';
import Login from '../client_authentication/login/Login';
import ResetPassword from '../client_authentication/reset_password/ResetPassword';
import Signup from '../client_authentication/signup/Signup';
import ClientDashboardStartup from '../client_dashboard';
import { setPlan } from '../plan/redux/slice';
import {
  fetchFormValues,
  fetchPlanProperties,
} from '../property/redux/async_thunks';
import {
  selectedPlanOffsetAccounts,
  selectedPlanRecords,
  selectPlanProperties,
} from '../property/redux/selectors';
import PrivateRoute from './private_route/PrivateRoute';
import PublicRoute from './public_route/PublicRoute';

function Startup() {
  const [isLoading, setIsLoading] = useState(true);

  const clientIp = useClientIP();

  const user = useSelector(selectUser);

  const isClientView = useRole();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const planProperties = useSelector(selectPlanProperties);

  const planOffsetAccounts = useSelector(selectedPlanOffsetAccounts);

  const planRecords = useSelector(selectedPlanRecords);

  const routes = useRoutes(
    planProperties,
    isClientView,
    planOffsetAccounts,
    planRecords,
  );

  const queryString: any = useMemo(
    () => query_string.parse(location.search),
    [location.search],
  );

  const getAPIParams = () => {
    type refreshTokenParamsType = {
      userId: string;
      sessionId: string;
      token?: string;
      appId: number;
      clientId?: number;
      roleId: number;
    };

    let apiParams: refreshTokenParamsType | undefined = undefined;

    const currentUserData = getItemFromStorage('user');

    // If the appAccess key is in the query string then parse using base64.
    if (queryString && queryString?.appAccess) {
      const buff = Buffer.from(queryString?.appAccess, 'base64');
      const text = buff.toString();

      const parsedData = JSON.parse(text);

      apiParams = {
        userId: parsedData.UserId,
        sessionId: parsedData.SessionId,
        appId: env_constants.APP_ID,
        roleId: parsedData.RoleId,
      };
    } else if (currentUserData) {
      const data = JSON.parse(currentUserData);

      apiParams = {
        userId: data.user_id,
        sessionId: data.session_id,
        appId: env_constants.APP_ID,
        roleId: data.role_id,
        clientId: data.client_id,
        token: data.token,
      };
    }

    return apiParams;
  };

  const createNetworkCallPromisesArray = (
    updatedUserData: User,
    currentPlanData: string,
  ) => {
    const $promises: any[] = [];
    if (currentPlanData) {
      const plan = JSON.parse(currentPlanData);
      dispatch(setPlan(plan));
      if (updatedUserData && updatedUserData.token) {
        $promises.push(
          dispatch(
            fetchPlanProperties({
              token: updatedUserData.token,
              planId: plan.planId,
            }),
            // @ts-ignore
          ).unwrap(),
        );
      }
    }

    /**
     * Fetch form values
     */
    if (
      updatedUserData &&
      updatedUserData.token
      // updatedUserData.role_id !== 0
    ) {
      $promises.push(
        dispatch(
          fetchFormValues({
            token: updatedUserData.token,
          }),
          // @ts-ignore
        ).unwrap(),
      );
    }

    return $promises;
  };

  const setAuthData = async () => {
    try {
      /**
       * Fetch the values from browser's storage.
       */

      const currentClientData = getItemFromStorage('client');

      const currentPlanData = getItemFromStorage('plan');

      const apiParams = getAPIParams();

      /**
       * Call the refreshToken API to get the updated values.
       */
      let updatedUserData: any = undefined;
      if (apiParams) {
        updatedUserData = await dispatch(
          refreshToken({
            data: apiParams,
          }),
          // @ts-ignore
        ).unwrap();
      } else {
        updatedUserData = { ...user };
      }

      /**
       * Set the client and plan data in redux based on their avaialabilities.
       */
      if (currentClientData) {
        dispatch(setClient(JSON.parse(currentClientData)));
      }

      const $promises = createNetworkCallPromisesArray(
        updatedUserData,
        currentPlanData ? currentPlanData : '',
      );

      await Promise.all($promises);

      const path = location.pathname;

      if (updatedUserData.user_id) {
        /**
         * Handle browser routing based on the avaialble values.
         */
        if (updatedUserData?.role_id === 0) {
          if (currentClientData && currentPlanData) {
            if (path === '/') {
              navigate('/dashboard');
            } else {
              navigate(path);
            }
          } else if (!currentPlanData && currentClientData) {
            navigate('/plan');
          } else {
            navigate(`/login`);
          }
        } else if (currentClientData && currentPlanData) {
          if (path === '/') {
            navigate('/dashboard');
          } else {
            navigate(path);
          }
        } else if (!currentPlanData && currentClientData) {
          navigate('/plan');
        } else {
          navigate(`/`);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (user) {
        const currentPlanData = getItemFromStorage('plan');

        const $promises = createNetworkCallPromisesArray(
          user,
          currentPlanData ? currentPlanData : '',
        );

        await Promise.all($promises);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (clientIp) {
      if (!user) {
        setAuthData();
      } else {
        fetchData();
      }
    }
  }, [clientIp]);

  const routeRenderer: any = (item: RouteType) => {
    if (!item.isSubMenu) {
      return (
        <Route
          key={item.path}
          path={item.path}
          element={
            <PrivateRoute>
              <item.component />
            </PrivateRoute>
          }
        />
      );
    } else {
      const items: any[] = [];
      if (item.routes) {
        for (const route of item.routes) {
          items.push(routeRenderer(route));
        }
      }
      return items;
    }
  };

  const privateRoutes = useMemo(() => {
    const items: any[] = [];

    for (const route of routes) {
      items.push(routeRenderer(route));
    }
    return items;
  }, [routes]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/client/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/client/join"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        <Route
          path="/client/dashboard"
          element={
            <PublicRoute>
              <ClientDashboardStartup />
            </PublicRoute>
          }
        />

        <Route
          path="/client/reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        {privateRoutes}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default Startup;
