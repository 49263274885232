import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import React from 'react';

type Props = {
  onChange?: any;
  className?: string;
  picker: 'week' | 'month' | 'quarter' | 'year';
  value?: Dayjs;
  defaultValue?: string;
  style?: any;
  disabled?: boolean;
  disabledDate?: any;
};

const YearField = ({
  onChange,
  style,
  value,
  className,
  disabled,
  disabledDate,
  picker,
}: Props) => {
  return (
    <DatePicker
      onChange={onChange}
      picker={picker}
      value={value}
      style={style}
      className={className}
      disabled={disabled}
      disabledDate={disabledDate}
    />
  );
};

export default YearField;
