import { EditOutlined } from '@ant-design/icons';
import { Table, Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../foundation/components/form_modal/form_modal_types';
import FormModal from '../../foundation/components/form_modal/FormModal';
import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import DutyTransferTable from './duty_transfer_table/DutyTransferTable';
import {
  fetchCostsByStateData,
  fetchLMIRates,
  fetchStampDuties,
  updateLMIRate,
} from './redux/async_thunks';
import { selectLMIRates, selectStampDuties } from './redux/selectors';
import CostByState from './sections/CostByState';

const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs;

const StampDutyLMI = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const dataSource = useSelector(selectLMIRates);

  const stampDuties = useSelector(selectStampDuties);

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'LVR Band (Lower)',
        name: 'lvrLowerBand',
        key: 'lvrLowerBand',
        type: 'number',
        isRequired: true,
        requiredMessage: 'LVR Band (Lower) is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'LVR Band (Higher)',
        name: 'lvrHigherBand',
        key: 'lvrHigherBand',
        type: 'number',
        isRequired: true,
        requiredMessage: 'LVR Band (Higher) is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Threshold',
        name: 'threshold',
        key: 'threshold',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Threshold is required',
        addonBefore: '$',
        min: 0,
      },
      {
        label: 'Under Threshold',
        name: 'underThreshold',
        key: 'underThreshold',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Under Threshold is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Over Threshold',
        name: 'overThreshold',
        key: 'overThreshold',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Over Threshold is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
    ],
    [],
  );

  /**
   * Fetches the data
   */
  const fetchData = async () => {
    try {
      if (user?.token) {
        const $promises = [
          dispatch(fetchStampDuties({ token: user.token })).unwrap(),
          dispatch(fetchLMIRates({ token: user.token })).unwrap(),
          dispatch(fetchCostsByStateData({ token: user.token })).unwrap(),
        ];

        await Promise.all($promises);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onEditRecord = (record: any) => () => {
    setModalData({
      ...record,
      lvrLowerBand: percentageParser(record?.lvrLowerBand),
      lvrHigherBand: percentageParser(record?.lvrHigherBand),
      underThreshold: percentageParser(record?.underThreshold),
      overThreshold: percentageParser(record?.overThreshold),
    });
    setModalVisibility(true);
  };

  const sortedStampDuties = useMemo(() => {
    if (stampDuties) {
      const duties: any = {};
      for (const item of stampDuties) {
        duties[item.state] = item.brackets;
      }
      return duties;
    }
  }, [stampDuties]);

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleLMIRateEdit = async (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (user?.token) {
          const data = {
            ...values,
            userId: user.user_id,
            lvrLowerBand: values?.lvrLowerBand / 100,
            lvrHigherBand: values?.lvrHigherBand / 100,
            underThreshold: values?.underThreshold / 100,
            overThreshold: values?.overThreshold / 100,
          };

          await dispatch(
            updateLMIRate({
              data: data,
              token: user?.token,
            }),
          ).unwrap();

          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const titlesToBold: string[] = [];

  const columnValueRenderer =
    (unit?: string) => (text: string, record: any) => {
      return colValueRenderer(text, record, unit, titlesToBold, 'lvrLowerBand');
    };

  return (
    <div className="stamp_duty_lmi">
      {isModalVisible && (
        <FormModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update LMI Rates`}
          onSubmit={handleLMIRateEdit}
          formFieldsArray={formFieldsArray}
        />
      )}
      <Table
        dataSource={dataSource}
        bordered
        pagination={false}
        className="stamp_duty_lmi__main-table"
        size={useTableSize()}
        loading={isLoading}
        scroll={{ x: true }}
      >
        <ColumnGroup title="LMI Premium Rates (QBE insurance as at)">
          <Column
            title="LVR Band (Lower)"
            dataIndex="lvrLowerBand"
            key="lvrLowerBand"
            render={columnValueRenderer('%')}
          />
          <Column
            title="LVR Band (Higher)"
            dataIndex="lvrHigherBand"
            key="lvrHigherBand"
            render={columnValueRenderer('%')}
          />
        </ColumnGroup>
        <Column
          title="Threshold"
          dataIndex="threshold"
          key="threshold"
          render={columnValueRenderer('$')}
        />
        <Column
          title="Under Threshold"
          dataIndex="underThreshold"
          key="underThreshold"
          render={columnValueRenderer('%')}
        />
        <Column
          title="Over Threshold"
          dataIndex="overThreshold"
          key="overThreshold"
          render={columnValueRenderer('%')}
        />
        <Column
          title={<span className="c-action-title">Action</span>}
          key="action"
          render={(text: any, record: any) => {
            return (
              <EditOutlined
                onClick={onEditRecord(record)}
                className="stamp_duty_lmi__edit-record-btn"
              />
            );
          }}
        />
      </Table>

      <div className="stamp_duty_lmi__section-title">Duty Transfer</div>

      <Tabs defaultActiveKey="AB">
        <TabPane tab="AB" key="AB">
          <DutyTransferTable
            key="AB"
            dataSource={sortedStampDuties.AB}
            isLoading={isLoading}
            state="AB"
          />
        </TabPane>
        <TabPane tab="NSW" key="NSW">
          <DutyTransferTable
            key="NSW"
            dataSource={sortedStampDuties.NSW}
            isLoading={isLoading}
            state="NSW"
          />
        </TabPane>
        <TabPane tab="QLD" key="QLD">
          <DutyTransferTable
            dataSource={sortedStampDuties.QLD}
            isLoading={isLoading}
            key="QLD"
            state="QLD"
          />
        </TabPane>
        <TabPane tab="VIC" key="VIC">
          <DutyTransferTable
            key="VIC"
            state="VIC"
            dataSource={sortedStampDuties.VIC}
            isLoading={isLoading}
          />
        </TabPane>
        <TabPane tab="WA" key="WA">
          <DutyTransferTable
            key="WA"
            state="WA"
            dataSource={sortedStampDuties.WA}
            isLoading={isLoading}
          />
        </TabPane>
        <TabPane tab="TAS" key="TAS">
          <DutyTransferTable
            key="TAS"
            state="TAS"
            dataSource={sortedStampDuties.TAS}
            isLoading={isLoading}
          />
        </TabPane>
        <TabPane tab="SA" key="SA">
          <DutyTransferTable
            key="SA"
            state="SA"
            dataSource={sortedStampDuties.SA}
            isLoading={isLoading}
          />
        </TabPane>
      </Tabs>

      <CostByState isLoading={isLoading} />
    </div>
  );
};

export default StampDutyLMI;
