import { Tooltip } from 'antd';
import React from 'react';

const RedStatus = () => {
  return (
    <Tooltip
      title={`You can't use more than what is available.`}
      color={'#F0F9F8'}
      overlayInnerStyle={{ color: 'black', padding: 10 }}
    >
      <span className="purchase-scenario__status-indicator purchase-scenario__status-indicator--red" />
    </Tooltip>
  );
};

const GreenStatus = () => {
  return <span className="purchase-scenario__status-indicator"></span>;
};

type Props = {
  amount: number;
  use: number;
};

export default ({ amount, use }: Props) => {
  return (
    <div className="purchase-scenario__status-wrapper">
      {use > amount ? <RedStatus /> : <GreenStatus />}
    </div>
  );
};
