import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../../foundation/utils/helperFunctions';
import { RiskProfile } from '../redux/types';

type RiskProfileTableProps = {
  isLoading: boolean;
  titlesToBold: string[];
  dataSource: RiskProfile[];
  onEdit: (values: any) => void;
};

const RiskProfileTable = ({
  titlesToBold,
  dataSource,
  isLoading,
  onEdit,
}: RiskProfileTableProps) => {
  const onEditRecord = (record: RiskProfile) => () => {
    let values: any;
    switch (record.fieldType) {
      case 'percentage': {
        values = {
          ...record,
          aggressive: percentageParser(record.aggressive),
          moderate: percentageParser(record.moderate),
          conservative: percentageParser(record.conservative),
        };
        break;
      }
      case 'boolean':
      case 'price':
      default: {
        values = {
          ...record,
        };
        break;
      }
    }
    return onEdit(values);
  };

  const renderColumnValues = (text: string, record: RiskProfile) => {
    switch (record.fieldType) {
      case 'price': {
        return colValueRenderer(text, record, '$', titlesToBold, 'fieldName');
      }
      case 'boolean': {
        return <b>{text ? 'Yes' : 'No'}</b>;
      }
      case 'percentage': {
        return colValueRenderer(text, record, '%', titlesToBold, 'fieldName');
      }
      default: {
        return <b>{text}</b>;
      }
    }
  };

  /**
   * Data table's columns.
   */
  const columns: ColumnsType<RiskProfile> = [
    {
      title: '',
      dataIndex: 'fieldTitle',
      key: 'fieldTitle',
      ellipsis: true,
      width: 200,
      render: (text: string, record: any) => {
        return <b>{text}</b>;
      },
    },
    {
      title: 'Aggressive',
      dataIndex: 'aggressive',
      key: 'aggressive',
      ellipsis: true,
      width: 100,
      render: (text: string, record: any) => {
        return renderColumnValues(text, record);
      },
    },
    {
      title: 'Moderate',
      dataIndex: 'moderate',
      key: 'moderate',
      ellipsis: true,
      width: 100,
      render: (text: string, record: any) => {
        return renderColumnValues(text, record);
      },
    },
    {
      title: 'Conservative',
      dataIndex: 'conservative',
      key: 'conservative',
      ellipsis: true,
      width: 100,
      render: (text: string, record: any) => {
        return renderColumnValues(text, record);
      },
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'year',
      key: 'year',
      width: 80,
      render: (text: any, record: any) => {
        return (
          <EditOutlined
            onClick={onEditRecord(record)}
            className="l-costs-by-lga__edit-record-btn"
          />
        );
      },
    },
  ];

  return (
    <div className="l-risk-profile__table-wrapper">
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        size={useTableSize()}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default RiskProfileTable;
