import { useMediaQuery } from 'react-responsive';

export const useViewport = () => {
  const isMobileViewport = useMediaQuery({ maxWidth: 767 });
  const isTabletViewport = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isLargeTabletViewport = useMediaQuery({
    minWidth: 992,
    maxWidth: 1199,
  });

  return {
    isMobileViewport,
    isTabletViewport,
    isLargeTabletViewport,
    isDesktopViewport: !(
      isMobileViewport ||
      isTabletViewport ||
      isLargeTabletViewport
    ),
  };
};
