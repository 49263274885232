import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

type KeyValue = {
  key: string | number;
  value: string | number;
};

type Props = {
  options: KeyValue[];
  onChange?: any;
  onBlur?: any;
  className?: string;
  value?: string;
  defaultValue?: string;
  style?: any;
  placeholder?: string;
  disabled?: boolean;
};

const CustomSelect = ({
  options,
  onChange,
  style,
  value,
  defaultValue,
  className,
  placeholder,
  disabled,
  onBlur,
}: Props) => {
  const renderOptions = () => {
    const selectOptions = [];
    for (const option of options) {
      selectOptions.push(
        <Option value={option.value} key={option.value}>
          {option.key}
        </Option>,
      );
    }
    return selectOptions;
  };

  return (
    <Select
      defaultValue={defaultValue}
      style={style}
      value={value}
      onChange={onChange}
      className={`c-select ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
    >
      {renderOptions()}
    </Select>
  );
};

export default CustomSelect;
