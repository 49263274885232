import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */

export const selectDashboardCashflowResult = (state: RootState) =>
  state.dashboard?.values?.cashFlowResult;

export const selectDashboardAssetResult = (state: RootState) =>
  state.dashboard?.values?.assetResult;

export const selectDashboardNextPurchase = (state: RootState) =>
  state.dashboard?.values?.nextPurchaseStats;

export const selectDashboardNumberOfProperties = (state: RootState) =>
  state.dashboard?.values?.numberOfProperties;

export const selectDashboardPortfolioValue = (state: RootState) =>
  state.dashboard?.values?.portfolioValue;

export const selectDashboardTotalDebt = (state: RootState) =>
  state.dashboard?.values?.totalDebt;

export const selectDashboardTotalEquity = (state: RootState) =>
  state.dashboard?.values?.totalEquity;

export const selectDashboardPortfolioPerformance = (state: RootState) =>
  state.dashboard?.values?.proPortfolioPerformance?.portfolioPerformanceDetails;

export const selectDashboardAnnualCashflowProjectionData = (state: RootState) =>
  state.dashboard?.values?.annualCashFlow[0]?.data;

export const selectDashboardMonthlyCashflowProjectionData = (
  state: RootState,
) => state.dashboard?.values?.monthlyCashFlow[0]?.data;

export const selectDashboardEquityForecast = (state: RootState) =>
  state.dashboard?.values?.equityGrowth[0]?.data;

export const selectDashboardAboutProperties = (state: RootState) =>
  state.dashboard?.values?.aboutProperties?.aboutPropertiesDetails;

export const selectDashboardPurchaseRoadmap = (state: RootState) =>
  state.dashboard?.values?.propertyPurchaseRoadmap[0]?.data;

export const selectDashboardPurchaseRoadmapAxisData = (state: RootState) =>
  state.dashboard?.values?.propertyPurchaseRoadmap[0]?.axis;

export const selectDashboardPlanTargetCalendarYear = (state: RootState) =>
  state.dashboard?.values?.planTargetCalendarYear;

export const selectDashboardAchievedYear = (state: RootState) =>
  state.dashboard?.values?.achievedYear;

export const selectDashboardTimelineYears = (state: RootState) =>
  state.dashboard?.values?.timelineYears;
