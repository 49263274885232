import { Button, Input, Modal, notification, Switch } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import {
  addItemToStorage,
  getItemFromStorage,
} from '../../../foundation/utils/storageHandler';
import { selectUser } from '../../authentication/redux/selectors';
import { setClient } from '../../client/redux/slice';
import { editPlanName } from '../redux/async_thunks';
import { selectPlan } from '../redux/selectors';
import PlanNameValidationSchema from './validation_schema';

type Props = {
  closeModal: () => any;
};

const PlanNameModal = ({ closeModal }: Props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const plan = useSelector(selectPlan);

  const modalOpts = {
    title: 'Edit Plan',
    open: true,
    onCancel: () => {
      closeModal();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);

      if (user?.token) {
        const data = {
          newPlanName: values.planName,
          userId: user.user_id,
          planId: plan?.planId,
          clientId: plan?.clientId,
          isInternal: values.isInternal,
        };
        const updatedPlan = await dispatch(
          editPlanName({ token: user.token, data }),
          // @ts-ignore
        ).unwrap();

        const clientUnparsedData = getItemFromStorage('client');

        const clientData = clientUnparsedData
          ? JSON.parse(clientUnparsedData)
          : {};

        const clientUpdatedData = {
          ...clientData,
          planIds: updatedPlan.planIds,
        };

        addItemToStorage('client', JSON.stringify(clientUpdatedData));

        dispatch(setClient(clientUpdatedData));

        setIsLoading(false);
        closeModal();

        notification.success({
          message: 'Success!',
          description: 'Plan updated successfully',
        });
      }
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <Modal {...modalOpts} className="c-form-modal">
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          planName: plan?.planName,
          isInternal: plan?.isInternal,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={PlanNameValidationSchema}
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="c-form-field" style={{ marginBottom: 25 }}>
                <div className="c-form-field__label">Name:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="planName"
                    onChange={handleChange}
                    type="text"
                    value={values.planName}
                    onBlur={handleBlur}
                    placeholder={'My Plan#1'}
                  />
                  <div className="c-form-field__error">
                    {errors.planName ? errors.planName : undefined}
                  </div>
                </div>
              </div>
              <div className="c-form-field plan-view__modal-internal-trigger-wrapper">
                <div className="c-form-field__label">Internal:</div>
                <div className="c-form-field__wrapper">
                  <Switch
                    checked={values.isInternal}
                    onChange={(v: boolean) => {
                      setFieldValue('isInternal', v);
                    }}
                  />
                </div>
              </div>
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Update
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PlanNameModal;
