/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Form, InputNumber } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import Select from '../../../foundation/components/select/Select';
import { OffsetAccount, SourceAccount } from '../redux/types';
import { EditableContext } from './editable_context';

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: OffsetAccount;
  options: any[];
  inputType: string;
  handleSave: (record: any) => void;
  setError: (v: boolean) => void;
  sourceAccDataSource: SourceAccount[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  setError,
  options,
  inputType,
  sourceAccDataSource,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing && inputType !== 'select') {
      inputRef.current!.focus();
    }
  }, [editing, inputType]);

  const toggleEdit = () => {
    if (record?.isEditable) {
      setEditing(!editing);
      const value = record[dataIndex];
      form.setFieldsValue({
        [dataIndex]: value,
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      handleSave({
        ...values,
        rowId: record.rowId,
      });

      setError(false);
    } catch (errInfo) {
      setError(true);
      console.log('Save failed:', errInfo);
    }
  };

  const getIndexAndRowOfSourceAccounts = (sourceName: string) => {
    const index = sourceAccDataSource.findIndex(
      (item) => item.sourceName === sourceName,
    );

    const row = sourceAccDataSource[index];

    return {
      index,
      row,
    };
  };

  const max = useMemo(() => {
    if (record) {
      if (record.transactionType === 'Withdrawal') {
        if (record.fromTo === 'Savings') {
          return record.accountBalance + record[dataIndex];
        }
      }
      if (record.transactionType === 'Deposit') {
        if (record.fromTo === 'Savings') {
          // Get Savings Row
          const { row } = getIndexAndRowOfSourceAccounts('Savings');

          // Get Total Cash Available Row.
          const { row: totalCashRow } = getIndexAndRowOfSourceAccounts(
            'Total Cash Available',
          );

          const { newAvailableAmount: cashAvlNewAvailableAmount } =
            totalCashRow;

          const { newAvailableAmount } = row;

          /**
           * If the total Cash available value is less than the new Available amount of Savings
           * then use cashAvlNewAvailableAmount as the max allowed.
           * cashAvlNewAvailableAmount can be lower only when the Portfolio cash flow's
           * new amount available is in negative.
           */
          if (cashAvlNewAvailableAmount < newAvailableAmount) {
            const { maximumDeposit } = record;
            if (cashAvlNewAvailableAmount < maximumDeposit) {
              return cashAvlNewAvailableAmount;
            } else {
              return maximumDeposit;
            }
          } else {
            const { maximumDeposit } = record;
            if (newAvailableAmount < maximumDeposit) {
              return newAvailableAmount;
            } else {
              return maximumDeposit;
            }
          }
        }

        if (record.fromTo === 'Portfolio CashFlow') {
          const { row } = getIndexAndRowOfSourceAccounts('Portfolio CashFlow');
          const { newAvailableAmount } = row;
          const { maximumDeposit } = record;

          if (newAvailableAmount < maximumDeposit) {
            return newAvailableAmount;
          } else {
            return maximumDeposit;
          }
        }
      }
    }
    return 10000;
  }, [record, sourceAccDataSource]);

  const min = useMemo(() => {
    return 0;
  }, []);

  const renderFormItems = () => {
    if (inputType === 'select') {
      return (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={
            [
              // {
              //   required: true,
              //   message: `${title} is required.`,
              // },
            ]
          }
        >
          <Select options={options} onChange={save} onBlur={save} />
        </Form.Item>
      );
    }
    return (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
          {
            min: min,
            message: `Field must be greater than or equal to ${min}.`,
            type: 'number',
          },
          {
            max: max < 0 ? 0 : max,
            message: `Field must be less than or equal to ${max}.`,
            type: 'number',
          },
        ]}
      >
        <InputNumber
          ref={inputRef}
          addonBefore={'$'}
          precision={2}
          step={0.1}
          onPressEnter={save}
          onBlur={save}
          type="number"
        />
      </Form.Item>
    );
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      childNode = renderFormItems();
    } else {
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
