import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

const formatFocusData = (focusRates: any[]) => {
  let rentalGrowth: any[] = [];
  let priceGrowth: any[] = [];

  for (let i = 0; i < focusRates.length; i++) {
    const item = focusRates[i];
    if (item.targetGrowth === 'RentalGrowth') {
      rentalGrowth = item.rates;
    } else if (item.targetGrowth === 'PriceGrowth') {
      priceGrowth = item.rates;
    }
  }

  return { rentalGrowth, priceGrowth };
};

export const fetchFocusData = createAsyncThunk(
  'focus/get-focus-data',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/focus`,
      options,
    );
    const data = decryptData(response.data);

    const focusRates = data.focusRates;

    return formatFocusData(focusRates);
  },
);

export const updateFocusData = createAsyncThunk(
  'focus/update-focus-data',
  async (apiParams: { data: object; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/focus`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    const focusRates = data.focusRates;

    return formatFocusData(focusRates);
  },
);

export const fetchYearlySavingsRequired = createAsyncThunk(
  'focus/get-yearly-savings',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/yearly-savings-required`,
      options,
    );
    const data = decryptData(response.data);

    return data.yearlySavingsRequired;
  },
);

export const updateYearlySavingsRequired = createAsyncThunk(
  'focus/update-yearly-savings',
  async (apiParams: { data: object; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/yearly-savings-required`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data.yearlySavingsRequired;
  },
);
