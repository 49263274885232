import { Button, Modal } from 'antd';
import React from 'react';

import Close from '../../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';

type DeactivateConfirmModalProps = {
  handleCancel: () => void;
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
};

const DeactivateConfirmModal = ({
  handleCancel,
  handleSubmit,
  isLoading,
}: DeactivateConfirmModalProps) => {
  const modalProps = {
    wrapClassName: 'l-deactivate-sell-modal',
    closable: true,
    footer: null,
    maskClosable: false,
    // centered: true,
    open: true,
    title: '',
    width: 400,
    onCancel: handleCancel,
    closeIcon: <Close />,
  };

  const handleConfirm = async () => {
    await handleSubmit();
    handleCancel();
  };

  return (
    <Modal {...modalProps} className="l-deactivate-sell-modal__body">
      {isLoading && <FullPageLoader />}
      <div className="l-deactivate-sell-modal__header">
        Are you sure you want to deactivate the sale of this property?
      </div>
      <div className="l-deactivate-sell-modal__content">
        <strong>Please Note:</strong> that deactivating the sale of this
        property will deactivate all subsequent properties created after the
        Sale Settlement Date
      </div>
      <div className="l-deactivate-sell-modal__btn-container">
        <Button className="l-deactivate-sell-modal__btn" onClick={handleCancel}>
          No, Exit
        </Button>
        <Button
          type="primary"
          className="l-deactivate-sell-modal__btn"
          onClick={handleConfirm}
        >
          Yes, Deactivate Sale
        </Button>
      </div>
    </Modal>
  );
};

export default DeactivateConfirmModal;
