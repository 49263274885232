import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import auth from '../features/authentication/redux/slice';
import client from '../features/client/redux/slice';
import client_dashboard from '../features/client_dashboard/redux/slice';
import risk_profile from '../features/client_risk_profile/redux/slice';
import costs_by_lga from '../features/costs_by_lga/redux/slice';
import dashboard from '../features/dashboard/redux/slice';
import focus from '../features/focus/redux/slice';
import interest_rate from '../features/inrerest_rate_projection/redux/slice';
import offset_account from '../features/offset_account/redux/slice';
import plan from '../features/plan/redux/slice';
import property from '../features/property/redux/slice';
import property_types from '../features/property_type/redux/slice';
import report_downloads from '../features/report_downloads/redux/slice';
import stamp_and_lmi from '../features/stamp_duty_lmi/redux/slice';

export interface IRootState {
  router: RouterState;
}

// Root Reducer.
export default (history: History) => ({
  router: connectRouter(history),
  auth,
  client,
  plan,
  focus,
  costs_by_lga,
  stamp_and_lmi,
  property_types,
  property,
  dashboard,
  client_dashboard,
  offset_account,
  risk_profile,
  interest_rate,
  report_downloads,
});
