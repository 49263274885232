import { DollarOutlined, HomeOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DollarBag from '../../foundation/assets/svgs/DollarBag.svg';
import TwoBars from '../../foundation/assets/svgs/TwoBars.svg';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import CustomSelect from '../../foundation/components/select/Select';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import { selectClient } from '../client/redux/selectors';
import { selectPlan } from '../plan/redux/selectors';
import AnnualCashflowSection from './elements/AnnualCashflowSection';
import EquityGrowthSection from './elements/EquityGrowthSection';
import MonthlyCashflowSection from './elements/MonthlyCashflowSection';
import NextPurchaseCard from './elements/NextPurchaseCard';
import PortfolioPerformanceSection from './elements/PortfolioPerformanceSection';
import ProgressCard from './elements/ProgressCard';
import PurchaseRoadmapSection from './elements/PurchaseRoadmapSection';
import StatsCard from './elements/StatsCard';
import { fetchClientDashboardData } from './redux/async_thunks';
import {
  selectClientDashboardAssetResult,
  selectClientDashboardCashflowResult,
  selectClientDashboardCurrentTimelineYears,
  selectClientDashboardNumberOfProperties,
  selectClientDashboardPlanTargetCalendarYear,
  selectClientDashboardPortfolioValue,
  selectClientDashboardTimelineTypes,
  selectClientDashboardTotalDebt,
  selectClientDashboardTotalEquity,
} from './redux/selectors';
import { resetDashboard } from './redux/slice';

const ClientDashboard = () => {
  const dispatch = useAppDispatch();

  const [isClientDashboardLoading, setIsClientDashboardLoading] =
    useState(true);

  const [timeline, setTimeline] = useState<string>('10');

  const [planId, setPlanId] = useState<string | undefined>(undefined);

  // const [is2FAVisible, set2FAVisibility] = useState(true);

  const user = useSelector(selectUser);

  const cashFlowResult = useSelector(selectClientDashboardCashflowResult);

  const targetCalendarYear = useSelector(
    selectClientDashboardPlanTargetCalendarYear,
  );

  const selectedPlan = useSelector(selectPlan);

  const selectedClient = useSelector(selectClient);

  const timelineTypes = useSelector(selectClientDashboardTimelineTypes);

  const currentTimelineYears = useSelector(
    selectClientDashboardCurrentTimelineYears,
  );

  const assetResult = useSelector(selectClientDashboardAssetResult);
  const numOfProperties = useSelector(selectClientDashboardNumberOfProperties);
  const portfolioValue = useSelector(selectClientDashboardPortfolioValue);
  const totalDebt = useSelector(selectClientDashboardTotalDebt);
  const totalEquity = useSelector(selectClientDashboardTotalEquity);

  useEffect(() => {
    if (selectedPlan) {
      setPlanId(selectedPlan.planId);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (currentTimelineYears) {
      setTimeline(currentTimelineYears);
    }
  }, [currentTimelineYears]);

  /**
   * Fetches the dashboard data
   */
  const fetchData = async (years?: string, plan?: string) => {
    try {
      if (user?.token) {
        setIsClientDashboardLoading(true);
        const reqData = {
          planId: plan ? plan : planId,
          clientId: selectedClient?.clientId,
          timelineYears: parseInt(years ? years : timeline),
        };

        await dispatch(
          fetchClientDashboardData({
            token: user.token,
            values: reqData,
          }),
          // @ts-ignore
        ).unwrap();
      }
      setIsClientDashboardLoading(false);
    } catch (error) {
      setIsClientDashboardLoading(false);
    }
  };

  useEffect(() => {
    if (timeline && planId && selectedClient?.clientId) {
      fetchData();
    }
  }, [timeline, selectedClient, planId]);

  useEffect(
    () => () => {
      dispatch(resetDashboard());
    },
    [],
  );

  const handleTimelineChange = (v: string) => {
    setTimeline(v);
    fetchData(v);
  };

  return (
    <div className="dashboard">
      {isClientDashboardLoading && (
        <FullPageLoader classNames="dashboard__loader" />
      )}
      <div className="dashboard__client-wrapper">
        <div className="dashboard__header">
          <div className="dashboard__header-title">
            Property Portfolio Dashboard
          </div>
          <div className="dashboard__header-fields">
            <div
              className="dashboard__header-field"
              style={{ marginRight: 20 }}
            >
              <div className="dashboard__header-field-label">
                Portfolio Target Year:
              </div>
              <Input
                value={targetCalendarYear}
                disabled
                style={{ width: 80, height: 36 }}
              />
            </div>
            <div className="dashboard__header-field">
              <div className="dashboard__header-field-label">
                Dashboard Timeline:
              </div>
              <div>
                <CustomSelect
                  placeholder="Years"
                  options={timelineTypes ? timelineTypes : []}
                  className="cashflow-projection__performace-timeline-select"
                  onChange={handleTimelineChange}
                  value={timeline}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard__content-wrapper">
          <Row gutter={[20, 16]} style={{ marginBottom: 20 }}>
            <Col span={17}>
              <Row gutter={[20, 16]} style={{ marginBottom: 20 }}>
                <Col span={12}>
                  <ProgressCard
                    percentage={cashFlowResult?.percentage}
                    actual={cashFlowResult?.actual}
                    goal={cashFlowResult?.goal}
                    percentageTitle="Cashflow Result"
                    actualValueLabel="Annual Cashflow at Target Year"
                  />
                </Col>
                <Col span={12}>
                  <ProgressCard
                    percentage={assetResult?.percentage}
                    actual={assetResult?.actual}
                    goal={assetResult?.goal}
                    percentageTitle="Portfolio Value"
                    strokeColor="#FFB319"
                    actualValueLabel="Portfolio Value at Target Year"
                  />
                </Col>
              </Row>
              <Row gutter={[20, 16]} style={{ marginBottom: 20 }}>
                <Col span={6}>
                  <StatsCard
                    icon={<HomeOutlined />}
                    label="Properties Owned"
                    stats={numOfProperties ? `${numOfProperties}` : ''}
                    formatNumber={false}
                    background={`#E6FFFB`}
                    color={`#36CFC9`}
                  />
                </Col>
                <Col span={6}>
                  <StatsCard
                    icon={<DollarOutlined />}
                    label="Portfolio Value"
                    stats={portfolioValue ? `${portfolioValue}` : ''}
                    formatNumber={true}
                    background={`#E6F7FF`}
                    color={`#40A9FF`}
                  />
                </Col>
                <Col span={6}>
                  <StatsCard
                    icon={<DollarBag />}
                    label="Total Debt"
                    stats={totalDebt ? `${totalDebt}` : ''}
                    formatNumber={true}
                    background={`#F6FFED`}
                    color={`#73D13D`}
                  />
                </Col>
                <Col span={6}>
                  <StatsCard
                    icon={<TwoBars />}
                    label="Total Equity"
                    stats={totalEquity ? `${totalEquity}` : ''}
                    formatNumber={true}
                    background={`#FFFBE6`}
                    color={`#FFC53D`}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={7}>
              <NextPurchaseCard />
            </Col>
          </Row>
          <PurchaseRoadmapSection />
          <PortfolioPerformanceSection timeline={parseInt(timeline)} />
          <AnnualCashflowSection />
          <MonthlyCashflowSection />
          <EquityGrowthSection />
          {/* <AboutPropertiesDetails /> */}
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
