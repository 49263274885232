import { createSlice } from '@reduxjs/toolkit';

import { fetchPropertyTypes, updatePropertyType } from './async_thunks';
import { PropertyTypesState } from './types';

const initialState: PropertyTypesState = {
  yearRates: [],
  yearList: [],
};

export const nftSlice = createSlice({
  name: 'property_types',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPropertyTypes.fulfilled, (state, action) => {
        state.yearRates = action.payload.yearRates;
        state.yearList = action.payload.yearList;
      })
      .addCase(updatePropertyType.fulfilled, (state, action) => {
        state.yearRates = action.payload.yearRates;
        state.yearList = action.payload.yearList;
      });
  },
});

export default nftSlice.reducer;
