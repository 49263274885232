import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { refreshToken } from './async_thunks';
import { AuthState, User } from './types';

const initialState: AuthState = {
  oauth_info: undefined,
  user: undefined,
  location_info: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;
