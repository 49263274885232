import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchSettlementDays = createAsyncThunk(
  'settlement_period/get',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/unconditional-settlement-days`,
      options,
    );
    const data = decryptData(response.data);
    return [
      {
        name: 'Days to Settlement',
        value: data.daysSettlement,
        key: 'daysSettlement',
      },
      {
        name: 'Days to Unconditional',
        value: data.daysUnconditional,
        key: 'daysUnconditional',
      },
    ];
  },
);

export const updateSettlementDays = createAsyncThunk(
  'settlement_period/update',
  async (apiParams: { data: object; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/unconditional-settlement-days`,
      encryptedData,
      options,
    );
    const data = decryptData(response.data);
    return [
      {
        name: 'Days to Settlement',
        value: data.daysSettlement,
        key: 'daysSettlement',
      },
      {
        name: 'Days to Unconditional',
        value: data.daysUnconditional,
        key: 'daysUnconditional',
      },
    ];
  },
);
