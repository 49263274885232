import { Button, Input, Modal, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { addItemToStorage } from '../../../../foundation/utils/storageHandler';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { editClientName } from '../../redux/async_thunks';
import { selectClient } from '../../redux/selectors';
import { setClient } from '../../redux/slice';
import { clientFormValidationSchema } from '../../validation_schemas/client_form_validation';
type ClientModalProps = {
  closeModal: () => void;
};
const ClientModal = ({ closeModal }: ClientModalProps) => {
  const dispatch = useAppDispatch();
  const [modal, contextHolder] = Modal.useModal();

  const [isLoading, setIsLoading] = useState(false);

  const client = useSelector(selectClient);

  const user = useSelector(selectUser);

  const initialFormValues = useMemo(() => {
    return {
      clientName: client?.clientName,
    };
  }, [client]);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: 'Edit Client',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
  };

  const handleConsent = (data?: any) => {
    const config = {
      title: 'Duplicate Client Name Detected!',
      content: <p>Do you want to continue with the same name?</p>,
      onOk: () => {
        handleClientUpdate(data, true);
        setIsLoading(true);
      },
      okText: 'Continue',
      cancelText: 'No',
    };
    modal.confirm(config);
  };

  const handleClientUpdate = async (values: any, consent = false) => {
    try {
      const data: any = {
        clientId: client?.clientId,
        clientName: values.clientName,
        userId: user?.user_id,
        continue: consent,
      };

      if (user) {
        const updatedClient: any = await dispatch(
          editClientName({
            token: user?.token,
            data: data,
          }),
        ).unwrap();

        dispatch(setClient(updatedClient));

        addItemToStorage('client', JSON.stringify(updatedClient));

        notification.success({
          message: 'Success!',
          description: 'Client updated successfully.',
        });
      }

      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      if (error?.responseCode && error?.responseCode === 409) {
        handleConsent(values);
      }
    }
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    handleClientUpdate(values);
    setIsLoading(true);
  };

  /**
   * Formik form initialization
   */
  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleFormSubmit,
    validationSchema: clientFormValidationSchema,
  });

  const [
    errors,
    handleChange,
    values,
    handleSubmit,
    handleBlur,
    isValid,
    dirty,
  ] = useMemo(() => {
    return [
      formik.errors,
      formik.handleChange,
      formik.values,
      formik.handleSubmit,
      formik.handleBlur,
      formik.isValid,
      formik.dirty,
    ];
  }, [formik]);

  return (
    <Modal {...modalOpts} className="h-client-modal c-form-modal">
      {contextHolder}
      {isLoading && <FullPageLoader />}

      <form onSubmit={handleSubmit}>
        <div className="c-form-field">
          <div className="c-form-field__label">Client name:</div>
          <div className="c-form-field__wrapper">
            <Input
              name="clientName"
              onChange={handleChange}
              type="text"
              value={values.clientName}
              onBlur={handleBlur}
            />
            <div className="c-form-field__error">
              {/* @ts-ignore */}
              {errors.clientName ? errors.clientName : undefined}
            </div>
          </div>
        </div>
        <div className="c-form__btn-wrapper h-client-modal__form-btn-wrapper">
          <Button
            className="h-client-modal__form-cancel-btn"
            disabled={isLoading}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="h-client-modal__form-submit-btn"
            disabled={!(isValid && dirty) || isLoading}
          >
            Save & Continue
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ClientModal;
