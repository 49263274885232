import { Button, Layout, Menu, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ChevronLeft from '../../../foundation/assets/svgs/ChevronLeft';
import ChevronRight from '../../../foundation/assets/svgs/ChevronRight';
import { RouteType, useRoutes } from '../../../foundation/config/routes';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import CreateOffsetAccountModal from '../../offset_account/create_offset_account_modal/CreateOffsetAccountModal';
import { selectPlanInputs } from '../../plan/redux/selectors';
import CreatePropertyModal from '../../property/create_property_modal/CreatePropertyModal';
import {
  selectedPlanOffsetAccounts,
  selectedPlanRecords,
  selectPlanProperties,
} from '../../property/redux/selectors';
import { setSelectedPropertyId } from '../../property/redux/slice';

const { Sider: LayoutSider } = Layout;
const { SubMenu } = Menu;

const Sider = ({ routeConfig }: { routeConfig: RouteType | undefined }) => {
  const { isDesktopViewport } = useViewport();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [isOffsetModalVisible, setOffsetModalVisibility] = useState(false);
  const [collapsed, setCollapsed] = useState(!isDesktopViewport);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [previousOpenKeys, setPreviousOpenKeys] = useState<string[]>([]);

  const planProperties = useSelector(selectPlanProperties);

  const planOffsetAccounts = useSelector(selectedPlanOffsetAccounts);

  const planRecords = useSelector(selectedPlanRecords);

  const planInputs = useSelector(selectPlanInputs);

  const isClientView = useRole();

  const theme = isDesktopViewport ? 'light' : 'dark';

  const routes = useRoutes(
    planProperties,
    isClientView,
    planOffsetAccounts,
    planRecords,
  );

  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalVisibility(false);
    setOffsetModalVisibility(false);
  };

  const menuRenderer = (item: any) => {
    if (!item.isSubMenu) {
      return (
        <Menu.Item
          key={`${item.completePath}`}
          icon={item.icon ? <item.icon /> : null}
        >
          {item.name}
        </Menu.Item>
      );
    } else {
      const items: React.ReactNode[] = [];

      for (const route of item.routes) {
        if (!route.hideInMenu) {
          items.push(menuRenderer(route));
        }
      }

      return (
        <SubMenu
          key={`${item.completePath}`}
          icon={item.icon ? <item.icon /> : null}
          title={
            item.data?.isSold ? (
              <span>
                <span className="c-app-layout__sold-indicator">S</span>
                {item.name}
              </span>
            ) : (
              item.name
            )
          }
        >
          {items}
        </SubMenu>
      );
    }
  };

  const menuItems = useMemo(() => {
    const items: any = [];

    for (const item of routes) {
      if (!item.hideInMenu) {
        items.push(menuRenderer(item));
      }
    }

    return items;
  }, [routes]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setSelectedPropertyId(routeConfig?.data?.propertyId));
  }, [routeConfig]);

  const handleMenuSelect = ({ key }) => {
    if (key === '/properties/new') {
      if (!planInputs?.cashAvailable) {
        notification.info({
          message: 'Info',
          description: 'Please update Plan Inputs before creating a property.',
        });
      } else {
        setModalVisibility(true);
      }
    } else if (key === '/oa-transactions/new') {
      // Create new Offset Account Page.
      setOffsetModalVisibility(true);
    } else {
      navigate(key);
      setSelectedKeys([key]);
    }
  };

  const toggleMenuCollapse = () => {
    setCollapsed(!collapsed);

    if (collapsed) {
      setOpenKeys(previousOpenKeys);
    } else {
      setPreviousOpenKeys(openKeys);
    }
  };

  const siderProps = useMemo(() => {
    if (isDesktopViewport) {
      return {
        collapsible: true,
        trigger: null,
      };
    }
    return {
      collapsible: true,
      collapsedWidth: 0,
    };
  }, [isDesktopViewport]);

  if (routeConfig?.displaySidebar) {
    return (
      <LayoutSider
        className="c-app-layout__sider"
        theme={theme}
        breakpoint="xl"
        onCollapse={toggleMenuCollapse}
        width={isDesktopViewport ? 350 : 250}
        collapsed={collapsed}
        {...siderProps}
      >
        {isModalVisible && (
          <CreatePropertyModal closeModal={handleModalClose} />
        )}
        {isOffsetModalVisible && (
          <CreateOffsetAccountModal closeModal={handleModalClose} />
        )}
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          defaultOpenKeys={openKeys}
          theme={theme}
          openKeys={openKeys}
          onSelect={handleMenuSelect}
          onOpenChange={(keys) => {
            setOpenKeys(keys);
          }}
          className="c-app-layout__sider-menu"
        >
          {menuItems}
        </Menu>
        {collapsed && isDesktopViewport && (
          <Button
            className={`c-app-layout__sider-collapse-btn c-app-layout__sider-collapse-btn--collapsed`}
            type="primary"
            onClick={() => setCollapsed((v) => !v)}
          >
            <span>
              <ChevronRight />
            </span>
          </Button>
        )}
        {!collapsed && isDesktopViewport && (
          <Button
            className="c-app-layout__sider-collapse-btn"
            type="primary"
            onClick={() => setCollapsed((v) => !v)}
          >
            <span>Close</span>
            <span>
              <ChevronLeft />
            </span>
          </Button>
        )}
      </LayoutSider>
    );
  } else {
    return null;
  }
};

export default Sider;
