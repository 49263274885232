import { Store } from '@reduxjs/toolkit';
import { Modal, notification } from 'antd';
import axios from 'axios';
import React from 'react';

import { decryptResponseMessage } from '../utils/api';
import { clearStorage } from '../utils/storageHandler';

const Axios = axios.create({});

let store: Store;

export const injectStore = (_store: Store) => {
  store = _store;
};

const showWarningMessage = (warningMessage: string) => {
  if (
    warningMessage &&
    warningMessage !== '' &&
    warningMessage !== null &&
    warningMessage !== undefined
  ) {
    notification.info({
      message: 'Info',
      description: warningMessage,
    });
  }
};

const { confirm } = Modal;

const confirmPropertyUpdateHandler = () =>
  new Promise((resolve) => {
    confirm({
      title: 'Are you sure you want to continue?',
      content:
        'This change will deactivate this and all subsequent properties, and will require reviewing their respective Purchase Scenarios.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onCancel: () => {
        resolve(false);
        Modal.destroyAll();
      },
      onOk() {
        resolve(true);
      },
    });
  });
// Add a request interceptor
Axios.interceptors.request.use(
  async (config) => {
    if (
      (config &&
        config.url &&
        config.method === 'post' &&
        (config.url.indexOf('Info') > -1 ||
          config.url.indexOf('Plan') > -1 ||
          config.url.indexOf('PurchaseScenario') === 33 ||
          config.url.indexOf('CashRequiredEstimate') > -1 ||
          config.url.indexOf('AnnualFigures') > -1 ||
          config.url.indexOf('MonthlyModelling') > -1) &&
        config.url.indexOf('Info/notes') === -1 &&
        config.url.indexOf('Info/sell') === -1 &&
        config.url.indexOf('Info/duplicate') === -1 &&
        config.url.indexOf('Info/address') === -1 &&
        config.url.indexOf('Plan/properties') === -1 &&
        config.url.indexOf('Plan/add-property') === -1 &&
        config.url.indexOf('Plan/create-plan') === -1 &&
        config.url.indexOf('Plan/delete') === -1) ||
      (config &&
        config.url &&
        config.method === 'patch' &&
        config.url.indexOf('Info/undo-sell') > -1)
    ) {
      const state = store.getState();
      const properties = state?.property?.list;
      const selectedPropertyId = state?.property?.selected_property_id;

      let isPropertyActive = false;
      if (properties && properties?.length > 1) {
        let selectedPropertyIndex = undefined;
        for (let i = 0; i < properties.length; i++) {
          if (properties[i].propertyId === selectedPropertyId) {
            selectedPropertyIndex = i;
            isPropertyActive = properties[i].isActive;
            break;
          }
        }

        /**
         * If the property is last property, and the user is updating the
         * purchase scenario then don't show the warning message.
         */
        if (
          selectedPropertyIndex === properties.length - 1 &&
          config.url.indexOf('purchase-scenario') > -1
        ) {
          return config;
        }

        if (isPropertyActive === true) {
          const isSuccess = await confirmPropertyUpdateHandler();
          if (isSuccess) {
            return config;
          } else {
            return Promise.reject();
          }
        } else {
          return config;
        }
      } else {
        return config;
      }
    } else {
      // Do something before request is sent
      return config;
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 206) {
      /**
       * NOTE: This is dashdot's internal logic that we are using to show info
       * message in API.
       */
      showWarningMessage(
        decryptResponseMessage(response.data, 'responseMessage'),
      );

      return Promise.reject();
    } else {
      return response;
    }
  },
  (error) => {
    if (error.response.status === 403) {
      clearStorage();
      window.close();
    }
    let errorDescription;

    // For download EPs with no results:
    // Return error object as is, no decryption
    if (
      error.response.status === 404 &&
      error.response.config.responseType === 'blob'
    ) {
      return Promise.reject(error);
    }

    const responseError = decryptResponseMessage(
      error?.response?.data,
      'ResponseMessage',
    );

    if (responseError) {
      errorDescription = responseError;
    }

    if (error.response.status === 400) {
      // Bad request handler

      if (errorDescription?.includes('|')) {
        // We need to make sure one validation error appear on each link

        // Display error notification
        return notification.error({
          message: 'Error',
          // @ts-ignore
          description: (
            <>
              {errorDescription.split('|').map((message: any) => (
                <span key={message}>
                  {/* Remove single quote from error response. */}
                  {message.replace(/'/g, '').trim()}
                  <br />
                </span>
              ))}
            </>
          ),
        });
      }
    }

    /**
     * This logic is only for the Create and Update client API where we
     * receive 409 if a user use a name which already exists and in that case
     * we don't need to show the error message.
     */
    if (
      error.response.status === 409 &&
      error.response?.config?.url?.indexOf('Client') > -1
    ) {
      return Promise.reject(error);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    notification.error({
      message: 'Error',
      // @ts-ignore
      description: errorDescription,
    });
    return Promise.reject(error);
  },
);

export default Axios;
