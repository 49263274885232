import { createSlice } from '@reduxjs/toolkit';

import {
  fetchInterestRateProjectionData,
  updateInterestRateProjection,
} from './async_thunks';
import { InterestRateState } from './types';

const initialState: InterestRateState = {
  list: [],
};

export const interestRateSlice = createSlice({
  name: 'interest_rate',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchInterestRateProjectionData.fulfilled, (state, action) => {
        state.list = action.payload.interestRatesProjections;
      })
      .addCase(updateInterestRateProjection.fulfilled, (state, action) => {
        state.list = action.payload.interestRatesProjections;
      });
  },
});

export default interestRateSlice.reducer;
