import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */

export const selectPurchaseScenarioPurchaseDate = (state: RootState) =>
  state.property.purchase_scenario?.purchaseDate;

export const selectPurchaseScenarioFundsRequired = (state: RootState) =>
  state.property.purchase_scenario?.fundsRequired;

export const selectPropPurchaseScenarioIsSold = (state: RootState) =>
  state.property.purchase_scenario?.isSold;

export const selectPurchaseScenarioSummary = (state: RootState) =>
  state.property.purchase_scenario?.summary;

export const selectPurchaseScenarioLeverageEquity = (state: RootState) =>
  state.property.purchase_scenario?.leverageEquity;

export const selectPurchaseScenarioLeverageCash = (state: RootState) =>
  state.property.purchase_scenario?.leverageCash;

export const selectPurchaseScenarioNumOfMonths = (state: RootState) =>
  state.property.purchase_scenario?.numberOfMonths;

export const selectPurchaseScenarioIsPassed = (state: RootState) =>
  state.property.purchase_scenario?.isPassed;

export const selectPurchaseScenarioIsRedStatus = (state: RootState) =>
  state.property.purchase_scenario?.isRedStatus;

export const selectPurchaseScenarioShortfallAmount = (state: RootState) =>
  state.property.purchase_scenario?.shortfallAmount;

export const selectPurchaseScenarioRedrawLoanOptions = (state: RootState) =>
  state.property.purchase_scenario?.equityRedrawLoanOptions;

export const selectPurchaseScenarioLoanTypeList = (state: RootState) =>
  state.property.purchase_scenario?.loanTypeList;
