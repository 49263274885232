import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchLMIRates = createAsyncThunk(
  'stamp-duty-lmi/get-lmirates',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/lmi-rates`,
      options,
    );
    const data = decryptData(response.data);

    return data.lmiRates;
  },
);

export const fetchStampDuties = createAsyncThunk(
  'stamp-duty-lmi/get-stampduties',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/stamp-duty`,
      options,
    );
    const data = decryptData(response.data);

    return data.stampDuty;
  },
);

export const fetchCostsByStateData = createAsyncThunk(
  'stamp-duty-lmi/get-cost-by-state-data',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/cost-by-state`,
      options,
    );
    const data = decryptData(response.data);

    return data.costByStateData;
  },
);

export const updateCostsByStateData = createAsyncThunk(
  'stamp-duty-lmi/update-cost-by-state-data',
  async (apiParams: { data: object; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/cost-by-state`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);
    return data.costByStateData;
  },
);

export const updateLMIRate = createAsyncThunk(
  'stamp-duty-lmi/lmi-update',
  async (apiParams: { data: object; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/lmi-rates`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);
    return data.lmiRates;
  },
);

export const updateStampDuty = createAsyncThunk(
  'stamp-duty-lmi/stampduties-update',
  async (apiParams: { data: object; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/stamp-duty`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);
    return data.stampDuty;
  },
);
