import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const clientFormValidationSchema = () => {
  const fields: any = {
    clientName: Yup.string().trim().required(requiredMessage).nullable(),
  };

  return Yup.object().shape(fields);
};
