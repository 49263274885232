import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import SoldLabel from '../../../foundation/components/sold_label/SoldLabel';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { fetchPropertyPurchaseInfo } from '../redux/async_thunks';
import {
  selectPropCashReqIsSold,
  selectPurchasePropertyContractDate,
  selectPurchasePropertyName,
} from '../redux/selectors';
import { resetPurchase } from '../redux/slice';
import Details from './details';
import Summary from './summary';

const CashRequiredEstimates = () => {
  const { isDesktopViewport } = useViewport();
  const { id: propertyId }: any = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const propertyName = useSelector(selectPurchasePropertyName);

  const propertyContractDate = useSelector(selectPurchasePropertyContractDate);

  const isPropertySold = useSelector(selectPropCashReqIsSold);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      if (user?.token) {
        await dispatch(
          fetchPropertyPurchaseInfo({ token: user.token, propertyId }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();

    return () => {
      dispatch(resetPurchase());
    };
  }, [propertyId]);

  return (
    <div className="purchase">
      {isLoading && <FullPageLoader />}
      <div className="purchase__header">
        <div className="purchase__header-title">
          Cash Required Estimates {isPropertySold && <SoldLabel />}
        </div>
        <Row gutter={[20, 0]}>
          <Col
            xs={24}
            md={12}
            style={isDesktopViewport ? {} : { marginBottom: 10 }}
          >
            <div style={{ marginRight: 10 }}>Property</div>
            <div>
              <Input value={propertyName} disabled />
            </div>
          </Col>
          <Col xs={24} md={6}>
            <div style={{ marginRight: 10 }}>Contract Date</div>
            <div>
              <Input value={propertyContractDate} disabled />
            </div>
          </Col>
        </Row>
      </div>
      <Summary
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        propertyId={propertyId}
      />
      <Details
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        propertyId={propertyId}
      />
    </div>
  );
};

export default CashRequiredEstimates;
