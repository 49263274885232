import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/authentication/redux/selectors';

export const useRole = () => {
  const user = useSelector(selectUser);

  const [isClientView, setIsClientView] = useState(true);

  useEffect(() => {
    if (user && user?.role_id !== 0) {
      setIsClientView(false);
    } else {
      setIsClientView(true);
    }
  }, [user?.role_id]);

  return isClientView;
};
