import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Input, Switch } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FieldError from '../../foundation/components/field_error/FieldError';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import Select from '../../foundation/components/select/Select';
import { useRole } from '../../foundation/cutom_hooks/useRole';
import { KeyValue } from '../../foundation/types';
import { addItemToStorage } from '../../foundation/utils/storageHandler';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import { selectClient } from '../client/redux/selectors';
import { setClient } from '../client/redux/slice';
import { Client } from '../client/redux/types';
import { fetchPlanProperties } from '../property/redux/async_thunks';
import {
  setPlanOffsetAccounts,
  setPlanProperties,
} from '../property/redux/slice';
import { createPlan, fetchPlan } from './redux/async_thunks';

type FieldErrorType = {
  image?: string;
  name?: string;
};

type PlanProps = {
  isPlanDuplicateView?: boolean;
  handlePlanDuplicate: (v: string) => Promise<void>;
};

const Plan = ({ isPlanDuplicateView, handlePlanDuplicate }: PlanProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const [planName, setPlanName] = useState<string | undefined>(undefined);

  const [isInternalPlan, setIsInternalPlan] = useState<boolean>(false);

  const [plan, setPlan] = useState<string | undefined>(undefined);

  const [error, setError] = useState<FieldErrorType | undefined>(undefined);

  const client = useSelector(selectClient);

  const isClientView = useRole();

  /**
   * Creates plan
   */
  const handleCreatePlan = async () => {
    if (isLoading) {
      return;
    }
    try {
      if (user?.token && planName) {
        if (isPlanDuplicateView) {
          handlePlanDuplicate(planName);
        } else {
          setIsLoading(true);
          const data = {
            clientId: client?.clientId,
            planName: planName,
            userId: user?.user_id,
            isInternal: isInternalPlan,
          };

          const createdPlan: any = await dispatch(
            createPlan({ token: user.token, data: data }),
            // @ts-ignore
          ).unwrap();

          if (client) {
            const planIds = [...client.planIds];
            const oldClient: Client = { ...client };
            planIds.push({
              planId: createdPlan.planId,
              planName: createdPlan.planName,
              isInternal: createdPlan.isInternal,
            });
            oldClient.planIds = planIds;
            dispatch(setClient(oldClient));
            addItemToStorage('client', JSON.stringify(oldClient));
          }

          addItemToStorage('plan', JSON.stringify(createdPlan));

          dispatch(setPlanProperties(undefined));
          dispatch(setPlanOffsetAccounts(undefined));
          setIsLoading(false);

          navigate('/inputs');
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleExistingPlan = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      if (user?.token && plan) {
        /**
         * Fetch the selected plan and associated properties.
         */
        const [createdPlan] = await Promise.all([
          dispatch(
            fetchPlan({ token: user.token, planId: plan }),
            // @ts-ignore
          ).unwrap(),
          dispatch(
            fetchPlanProperties({ token: user.token, planId: plan }),
            // @ts-ignore
          ).unwrap(),
        ]);

        addItemToStorage('plan', JSON.stringify(createdPlan));
      }

      setIsLoading(false);

      navigate('/dashboard');
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlanName(e.target.value);
  };

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || value.trim().length < 3) {
      setError((v) => ({
        ...v,
        name: `Name should contain at least 3 characters!`,
      }));
    } else {
      setError((v) => ({
        ...v,
        name: undefined,
      }));
    }
  };

  /**
   * Existing client's plan options.
   */
  const planOptions = useMemo(() => {
    const options: KeyValue[] = [];
    if (client?.planIds && client.planIds.length > 0) {
      for (const item of client.planIds) {
        options.push({
          key: item.planName,
          value: item.planId,
        });
      }
    }
    return options;
  }, [client?.planIds]);

  const handlePlanSelection = useCallback((v: string) => {
    setPlan(v);
  }, []);

  const isCreateBtnDisabled =
    !planName || (error && error.name !== undefined) || planName?.length < 3;

  const isContinuePlanBtnDisabled = !plan;

  const headerText = useMemo(() => {
    if (isClientView) {
      return 'Select Plan';
    } else if (isPlanDuplicateView) {
      return 'Duplicate Plan Details';
    }
    return 'Create Plan';
  }, [isClientView, isPlanDuplicateView]);

  return (
    <div className="plan-view">
      {isLoading && <FullPageLoader />}
      <Card className="client-view__create-card">
        <div className="client-view__create-header">{headerText}</div>
        {!isPlanDuplicateView && planOptions && planOptions.length > 0 && (
          <>
            <div className="client-view__field-wrapper">
              <div className="client-view__create-field-label">
                Select Existing Plan:
              </div>
              <Select
                options={planOptions}
                value={plan}
                onChange={handlePlanSelection}
              />
            </div>
            <Button
              type="primary"
              className="client-view__create-client-btn"
              disabled={isContinuePlanBtnDisabled}
              onClick={handleExistingPlan}
            >
              Continue
            </Button>
            {!isClientView && <Divider>Or</Divider>}
          </>
        )}
        {!isClientView && (
          <>
            <div className="client-view__field-wrapper">
              <div className="client-view__create-field-label">Plan Name</div>
              <Input
                placeholder="Enter plan name"
                className="client-view__create-field"
                value={planName}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
              {error?.name && <FieldError message={error.name} />}
            </div>

            <div className="client-view__field-wrapper client-view__field-wrapper--flex-row">
              <div className="client-view__create-field-label">Internal:</div>
              <Switch
                checked={isInternalPlan}
                onChange={(v) => setIsInternalPlan(v)}
              />
            </div>
            <Button
              type="primary"
              className="client-view__create-client-btn"
              icon={!isPlanDuplicateView && <PlusSquareOutlined />}
              disabled={isCreateBtnDisabled}
              onClick={handleCreatePlan}
            >
              {isPlanDuplicateView ? 'Duplicate' : 'Create Plan'}
            </Button>
          </>
        )}
      </Card>
    </div>
  );
};

export default Plan;
