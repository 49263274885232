import { Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { colValueRenderer } from '../../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { updateAnnualFiguresInterestRateBulk } from '../../redux/async_thunks';
import { selectAnnualFiguresInterestRateProjections } from '../../redux/selectors';
import { InterestProjections } from '../../redux/types';
import EditableTable from '../components/InterestRateEditableTable';

type InterestRateProjectionsProps = {
  isLoading: boolean;
  propertyId: string;
  isClientView: boolean;
};

const InterestRateProjections = ({
  isLoading,
  propertyId,
  isClientView,
}: InterestRateProjectionsProps) => {
  const dispatch = useAppDispatch();

  const [dataSource, setDataSource] = useState<InterestProjections[]>([]);

  const [error, setError] = useState<boolean>(true);

  const [isEquityProjectionLoading, setIsEquityProjectionLoading] =
    useState(false);

  const initialSource = useSelector(selectAnnualFiguresInterestRateProjections);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (initialSource) {
      setDataSource(initialSource);
    }
  }, [initialSource]);

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Years',
      dataIndex: 'years',
      key: 'years',
      ellipsis: true,
      width: 200,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, undefined, [], 'years');
      },
    },
    {
      title: 'Interest Rate',
      dataIndex: 'interestRate',
      key: 'interestRate',
      ellipsis: true,
      width: 200,
      editable: true,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', [], 'years');
      },
    },
  ];

  const handleSave = async () => {
    if (isLoading || isEquityProjectionLoading || error || isClientView) {
      return;
    }
    try {
      if (user?.token) {
        setIsEquityProjectionLoading(true);
        const interestRates: {
          year: number;
          interestRate: number;
        }[] = [];

        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].isEditable && dataSource[i].rowId !== 1) {
            interestRates.push({
              year: dataSource[i].years,
              interestRate: dataSource[i].interestRate,
            });
          }
        }
        const data = {
          interestRates,
          userId: user.user_id,
          propertyId,
        };

        await dispatch(
          updateAnnualFiguresInterestRateBulk({
            values: data,
            token: user.token,
          }),
          // @ts-ignore
        ).unwrap();

        setIsEquityProjectionLoading(false);

        notification.success({
          message: 'Success!',
          description: 'Record updated successfully',
        });

        setError(true);
      }
    } catch (error) {
      setIsEquityProjectionLoading(false);
      console.log(error);
    }
  };

  const updateSource = (source: InterestProjections[]) => {
    setDataSource(source);
  };

  return (
    <div className="annual-figures__section annual-figures__section--dual">
      <div className="annual-figures__section-header">
        <div className="annual-figures__section-title">
          Interest Rate Projections
        </div>
        <div className="annual-figures__section-title">
          <Button
            type="primary"
            onClick={handleSave}
            disabled={error || isClientView}
          >
            Update
          </Button>
        </div>
      </div>

      <EditableTable
        defaultColumns={columns}
        dataSource={dataSource}
        updateSource={updateSource}
        isLoading={isEquityProjectionLoading}
        setError={setError}
        isClientView={isClientView}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default InterestRateProjections;
