import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const reportDownloadSlice = createSlice({
  name: 'report_download',
  initialState,
  reducers: {},
});

export default reportDownloadSlice.reducer;
