import React from 'react';

function DollarBag() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#73D13D"
        d="M19.523 6.199a.703.703 0 00-.68-.523H14.8C16.584 3.39 14.963 0 12.028 0s-4.556 3.39-2.77 5.676H4.93a.703.703 0 00-.683.534L.772 20.22a.703.703 0 00.052.48l1.435 2.908c.118.24.363.392.63.392h18.282a.703.703 0 00.637-.405l1.376-2.945c.204-.436.146.144-3.661-14.451zm-7.495-4.793c1.163 0 2.11.947 2.11 2.11 0 1.163-.947 2.109-2.11 2.109a2.112 2.112 0 01-2.11-2.11c0-1.162.947-2.109 2.11-2.109zm8.695 21.188H3.327l-1.128-2.286 3.28-13.226h12.823l3.5 13.204-1.079 2.308z"
      ></path>
      <path
        fill="#73D13D"
        d="M13.57 14.52c-.882-.687-2.037-.754-2.356-1.33-.148-.266-.022-.66.274-.86.375-.252 1.089-.156 1.369.102a.703.703 0 10.953-1.034 2.404 2.404 0 00-1.118-.558v-.246a.703.703 0 00-1.406 0v.296c-1.331.409-1.904 1.894-1.302 2.982.416.75 1.14 1.036 1.779 1.289.613.242 1.41.554 1.332 1.26-.038.338-.289.671-.624.829-.457.215-1.294.1-1.828-.248a.703.703 0 10-.77 1.176c.382.25.884.446 1.413.535v.3a.703.703 0 001.406 0v-.352c.97-.271 1.692-1.117 1.8-2.084.087-.782-.249-1.532-.922-2.056z"
      ></path>
    </svg>
  );
}

export default DollarBag;
