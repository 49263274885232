import { Card } from 'antd';
import React, { useCallback, useState } from 'react';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import Select from '../../foundation/components/select/Select';
import ClientPurchase from './reports/ClientPurchase';

const ReportDownloads = () => {
  const [selectedReport, setSelectedReport] = useState<any>(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const DOWNLOADABLE_REPORTS = [
    {
      key: 'Client Next Purchase',
      value: 'client-purchase-report',
      form: (
        <ClientPurchase isLoading={isLoading} setIsLoading={setIsLoading} />
      ),
    },
  ];

  const handleReportSelection = useCallback((v: string) => {
    setSelectedReport(
      DOWNLOADABLE_REPORTS.find((r) => {
        return r.value === v;
      }),
    );
  }, []);

  return (
    <div className="plan-view">
      {isLoading && <FullPageLoader />}
      <Card className="client-view__create-card">
        <div className="client-view__create-header">Download Report</div>
        <div className="client-view__field-wrapper" style={{ margin: 0 }}>
          <div className="client-view__create-field-label">Select Report:</div>
          <Select
            options={DOWNLOADABLE_REPORTS}
            value={selectedReport}
            onChange={handleReportSelection}
          />
        </div>
        {selectedReport && (
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            {selectedReport.form}
          </div>
        )}
      </Card>
    </div>
  );
};

export default ReportDownloads;
