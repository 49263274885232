import { Select } from 'antd';
import React, { useMemo } from 'react';

import { KeyValue } from '../../types';
import DebounceAjaxSelect from '../debounce_ajax_select/DebounceAjaxSelect';

type SelectType = {
  options?: KeyValue[];
  value: string | undefined | number;
  error?: string;
  onChange: any;
  label: string;
  showSearch?: boolean;
  isDebounce?: boolean;
  disabled?: boolean;
  fetchOptions?: (search: string) => Promise<KeyValue[]>;
};

export default ({
  options,
  value,
  onChange,
  error,
  label,
  showSearch,
  isDebounce,
  fetchOptions,
  disabled = false,
}: SelectType) => {
  const formattedOptions = useMemo(() => {
    if (options) {
      return options.map((item) => ({
        label: item.key,
        value: item.value,
      }));
    }
  }, [options]);

  return (
    <div className="c-form-field">
      <div className="c-form-field__label">{label}:</div>
      <div className="c-form-field__wrapper">
        {isDebounce && fetchOptions ? (
          <DebounceAjaxSelect
            fetchOptions={fetchOptions}
            value={value ? value : ''}
            onChange={onChange}
            disabled={disabled}
          />
        ) : (
          <Select
            options={formattedOptions}
            value={value}
            onChange={onChange}
            className="c-select"
            showSearch={showSearch}
            disabled={disabled}
            //   style={{ width: '100%' }}
          />
        )}
        <div className="c-form-field__error">{error ? error : undefined}</div>
      </div>
    </div>
  );
};
