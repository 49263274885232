import { Col, Row, Table } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useRole } from '../../../foundation/cutom_hooks/useRole';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import { colValueRenderer } from '../../../foundation/utils/helperFunctions';
import {
  selectedOffsetAccountFromToOptions,
  selectedOffsetAccountTransactionTypes,
} from '../../property/redux/selectors';
import { OffsetAccount, SourceAccount } from '../redux/types';
import { handleTotalForAccountOffset } from '../utils/handleTotalForOffsetAccountArray';
import EditableCell from './EditableCell';
import EditableRow from './EditableRow';

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

type AccountsProps = {
  dataSource: OffsetAccount[];
  setDataSource: React.Dispatch<React.SetStateAction<OffsetAccount[]>>;
  sourceAccDataSource: SourceAccount[];
};
const Accounts = ({
  dataSource,
  setDataSource,
  sourceAccDataSource,
}: AccountsProps) => {
  const titlesToBold = ['Total'];

  const isClientView = useRole();

  const [, setError] = useState<boolean>(true);

  const transactionTypes = useSelector(selectedOffsetAccountTransactionTypes);
  const fromToOptions = useSelector(selectedOffsetAccountFromToOptions);

  /**
   * Data table's columns.
   */
  const offsetAccountsCols: any = [
    {
      title: 'Properties',
      dataIndex: 'propertyName',
      key: 'propertyName',
      ellipsis: true,
      width: 200,
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          undefined,
          titlesToBold,
          'propertyName',
        );
      },
    },
    {
      title: 'Account Balance',
      dataIndex: 'accountBalance',
      key: 'accountBalance',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          '$',
          titlesToBold,
          'propertyName',
        );
      },
    },
    {
      title: 'Deposit/Withdrawal',
      dataIndex: 'transactionType',
      key: 'transactionType',
      ellipsis: true,
      editable: !isClientView,
      inputType: 'select',
      options: transactionTypes,
      width: 150,
      render: (text: string, record: any) => {
        return text;
      },
    },
    {
      title: 'From/To',
      dataIndex: 'fromTo',
      key: 'fromTo',
      ellipsis: true,
      editable: !isClientView,
      options: fromToOptions,
      inputType: 'select',
      width: 100,
      render: (text: string, record: any) => {
        return text;
      },
    },
    {
      title: 'Use',
      dataIndex: 'use',
      key: 'use',
      ellipsis: true,
      width: 300,
      editable: !isClientView,
      inputType: 'number',
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          '$',
          titlesToBold,
          'propertyName',
        );
      },
    },
    {
      title: 'New Offset Account Balance',
      dataIndex: 'newAccountBalance',
      key: 'newAccountBalance',
      ellipsis: true,
      width: 250,
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          '$',
          titlesToBold,
          'propertyName',
        );
      },
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row: OffsetAccount) => {
    const newData = [...dataSource];

    const index = newData.findIndex((item) => row.rowId === item.rowId);
    const item = newData[index];

    const newRow: OffsetAccount = { ...item, ...row };

    if (newRow.transactionType === 'Deposit') {
      newRow.newAccountBalance = newRow.accountBalance + newRow.use;
    } else if (newRow.transactionType === 'Withdrawal') {
      newRow.newAccountBalance = newRow.accountBalance - newRow.use;
    }

    newData.splice(index, 1, {
      ...newRow,
    });

    const source = handleTotalForAccountOffset(newData);

    setDataSource(source);
  };

  const columns = offsetAccountsCols.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        options: col.options,
        inputType: col.inputType,
        handleSave,
        setError,
        sourceAccDataSource,
      }),
    };
  });

  return (
    <Row className="offset-account__row">
      <Col xs={24}>
        <Table
          components={components}
          columns={columns as ColumnTypes}
          dataSource={dataSource}
          bordered
          rowClassName={() => 'editable-row'}
          scroll={{ x: true }}
          size={useTableSize()}
        />
      </Col>
    </Row>
  );
};

export default Accounts;
