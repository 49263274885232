import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KeyValue } from '../../../foundation/types';
import { clientLogin } from '../../client_authentication/redux/async_thunks';
import { fetchClientDashboardData, validateOTP } from './async_thunks';
import { DashboardState, LinkData, ValuesState } from './types';

const initialState: DashboardState = {
  values: undefined,
  client_plans: undefined,
  report_token: undefined,
  session_id: undefined,
  client_id: undefined,
  report_user_id: undefined,
  role_id: undefined,
  timeline_years: undefined,
  timeline_types: undefined,
  client_name: undefined,
  client_photo: undefined,
  link_data: undefined,
};

export const clientDashboard = createSlice({
  name: 'client-dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDashboard: (state) => {
      state.values = undefined;
    },
    setClientDashboardLinkData: (
      state,
      action: PayloadAction<LinkData | undefined>,
    ) => {
      state.link_data = action.payload;
    },
    setClientDashboard: (
      state,
      action: PayloadAction<
        | {
            values: ValuesState;
            timeline_years: string;
            timeline_types: KeyValue[];
          }
        | undefined
      >,
    ) => {
      state.values = action.payload?.values;
      state.timeline_types = action.payload?.timeline_types;
      state.timeline_years = action.payload?.timeline_years;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClientDashboardData.fulfilled, (state, action) => {
        state.values = action.payload.dashboard;
        state.client_plans = action.payload.clientPlans;
        state.client_id = action.payload.clientId;
        state.client_name = action.payload.clientName;
        state.client_photo = action.payload.clientPhoto;
        state.timeline_years = action.payload.timelineYears;
        state.timeline_types = action.payload.dashboardTimelineTypes;
      })
      .addCase(validateOTP.fulfilled, (state, action) => {
        state.values = action.payload.dashboard;
        state.report_token = action.payload.reportToken;
        state.client_plans = action.payload.clientPlans;
        state.session_id = action.payload.sessionId;
        state.client_id = action.payload.clientId;
        state.client_name = action.payload.clientName;
        state.client_photo = action.payload.clientPhoto;
        state.timeline_years = action.payload.timelineYears;
        state.timeline_types = action.payload.dashboardTimelineTypes;
      })
      .addCase(clientLogin.fulfilled, (state, action) => {
        state.values = action.payload.dashboard;
        state.report_token = action.payload.reportToken;
        state.client_plans = action.payload.clientPlans;
        state.session_id = action.payload.sessionId;
        state.client_id = action.payload.clientId;
        state.client_name = action.payload.clientName;
        state.client_photo = action.payload.clientPhoto;
        state.timeline_years = action.payload.timelineYears;
        state.timeline_types = action.payload.dashboardTimelineTypes;
      });
  },
});

export const {
  resetDashboard,
  setClientDashboardLinkData,
  setClientDashboard,
} = clientDashboard.actions;

export default clientDashboard.reducer;
