import { Chart as ChartJS, registerables } from 'chart.js';
import React from 'react';
import { Bubble } from 'react-chartjs-2';

import { numberFormatter } from '../../utils/helperFunctions';

type Props = {
  yAxesLabel: string;
  xAxesLabel: string;
  data: any;
  graphTitle: string;
  areaAboveFill?: string;
  xAxesType?: string;
  xAxesUnit?: string;
  titleMomentFormat?: string;
};
/**
 * NOTE: This graph component is not totally dynamic because mostly we need to plot
 * amount against time in the Portfolio Planner. In the future if we want to plot other type of values
 * we can make it dynamic.
 */
const BubbleChart = ({
  yAxesLabel,
  xAxesLabel,
  data,
  graphTitle,
  areaAboveFill,
  xAxesUnit,
  xAxesType,
  titleMomentFormat,
}: Props) => {
  ChartJS.register(...registerables);

  /**
   * Graph settings
   */
  const options: any = {
    responsive: true,
    fill: {
      target: 'origin',
      below: 'rgba(255, 77, 79, 0.2)', // Area will be red below the origin
      above: areaAboveFill,
    },

    plugins: {
      legend: {
        position: 'top',
        display: true,
      },
      title: {
        display: false,
        text: graphTitle,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            return `Required Amount: \n${numberFormatter(
              context[0].raw.amount,
              '$',
            )}\n${context[0].raw.propertyName}`;
          },
          label: (context: any) => {
            const value = context.parsed.y;
            return numberFormatter(value, '$');
          },
        },
      },
    },
    scales: {
      xAxes: {
        title: {
          text: xAxesLabel,
          display: true,
          padding: {
            bottom: 10,
          },
        },
        type: xAxesType,
        time: {
          unit: xAxesUnit,
        },
      },
      y: {
        title: {
          text: yAxesLabel,
          display: true,
          padding: {
            bottom: 10,
          },
        },
        ticks: {
          callback: (value: any) => {
            return numberFormatter(value, '$');
          },
        },
      },
    },
  };

  return <Bubble options={options} data={data} />;
};

export default BubbleChart;
