import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import {
  addItemToStorage,
  getItemFromStorage,
} from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';
import { setClient } from '../../client/redux/slice';
import { setClientDashboard } from '../../client_dashboard/redux/slice';
import { setUser } from './slice';

type refreshTokenParamsType = {
  userId: string;
  token?: string;
  sessionId: string;
  appId: number;
  clientId?: number;
  roleId: number;
};

const appStoreKeyIV = [env_constants.APP_STORE_KEY, env_constants.APP_STORE_IV];

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (apiParams: { data: refreshTokenParamsType }, thunkAPI) => {
    // @ts-ignore
    const clientData = getItemFromStorage('client_ip');
    let encryptedData = null;
    const isClientView = apiParams.data.roleId === 0;

    if (isClientView) {
      encryptedData = encryptData({
        reportUserId: apiParams.data.userId,
      });
    } else {
      encryptedData = encryptData(apiParams.data, appStoreKeyIV);
    }

    const ip = clientData;

    let headers: any = {
      'Content-type': 'application/json',
    };

    let clientId = encryptData(
      `${window.navigator.userAgent}/${ip}/${apiParams.data.sessionId}`,
      appStoreKeyIV,
    );

    if (isClientView && apiParams.data.clientId) {
      clientId = getSessionClientID();

      headers = {
        ...headers,
        clientid: clientId,
        Authorization: `Bearer ${apiParams.data.token}`,
      };
    } else {
      headers = {
        ...headers,
        clientid: clientId,
      };
    }

    const response = await axios.patch(
      isClientView
        ? `${env_constants.PP_API_BASE_URL}/Auth/refresh`
        : `${env_constants.APP_STORE_API}/Auth/refresh-token`,
      encryptedData,
      {
        headers,
      },
    );

    let data: any = {};

    if (isClientView) {
      data = decryptData(response.data);
    } else {
      data = decryptData(response.data, appStoreKeyIV);
    }

    let userData: any = {};

    if (isClientView) {
      userData = {
        session_id: data.sessionId,
        user_id: data.reportUserId,
        expiry: data.reportToken.expiry,
        token: data.reportToken.token,
        role_id: data.roleId,
      };

      const reduxClientData = {
        clientId: data.clientId,
        clientName: data.clientName,
        clientPhoto: data.clientPhoto,
        planIds: data.clientPlans,
      };

      const reportData = {
        values: data.dashboard,
        timeline_years: data.timelineYears,
        timeline_types: data.dashboardTimelineTypes,
      };

      // @ts-ignore
      const userStorageData = JSON.parse(getItemFromStorage('user'));

      thunkAPI.dispatch(setClient(reduxClientData));

      const finalUserData = { ...userStorageData, ...userData };

      thunkAPI.dispatch(setUser(finalUserData));

      thunkAPI.dispatch(setClientDashboard(reportData));

      return finalUserData;
    } else {
      userData = {
        session_id: data.sessionId,
        user_id: data.userId,
        expiry: data.appToken.expiry,
        token: data.appToken.token,
        role_id: apiParams.data.roleId,
        email: data.userEmail,
        first_name: data.userFirstName,
        last_name: data.userLastName,
        picture: data.userPicture,
      };

      addItemToStorage('user', JSON.stringify(userData));

      return userData;
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (apiParams: { token: string; data: any; isClientView: boolean }) => {
    try {
      let encryptedData: any = undefined;
      let clientId = '';
      let API_URL = `${env_constants.APP_STORE_API}/Auth/logout`;

      const isClientView = apiParams.isClientView;

      if (isClientView) {
        clientId = getSessionClientID();
        encryptedData = encryptData(apiParams.data);
        API_URL = `${env_constants.PP_API_BASE_URL}/Auth/logout`;
      } else {
        // @ts-ignore
        const clientData = getItemFromStorage('client_ip');
        // @ts-ignore
        const getUser = JSON.parse(getItemFromStorage('user'));
        const ip = clientData;
        encryptedData = encryptData(apiParams.data, appStoreKeyIV);
        clientId = encryptData(
          `${window.navigator.userAgent}/${ip}/${getUser.session_id}`,
          appStoreKeyIV,
        );
      }

      const options = {
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: clientId,
        },
      };

      const res = await axios.delete(API_URL, {
        ...options,
        data: encryptedData,
      });

      if (isClientView) {
        return decryptData(res.data);
      } else {
        return decryptData(res.data, appStoreKeyIV);
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchClientData = createAsyncThunk('auth/clientData', async () => {
  const response = await axios.get(
    `${env_constants.PP_API_BASE_URL}/Report/ip`,
    {
      headers: {
        'Content-type': 'application/json',
        clientid: encryptData(`${window.navigator.userAgent}`),
      },
    },
  );
  const decryptedDataResponse = decryptData(response.data);
  const clientData = decryptedDataResponse.ip;
  sessionStorage.setItem('client_ip', clientData);
  return clientData;
});
