import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createNewOffsetAccount,
  fetchOffsetAccount,
  fetchOffsetAccountInfoByDate,
  fetchOffsetAccounts,
} from './async_thunks';
import { OffsetAccountState, SourceAccount } from './types';

const initialState: OffsetAccountState = {
  list: undefined,
  selected_offset_account: undefined,
};

export const clientSlice = createSlice({
  name: 'offset_accounts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSourceAccounts: (state, action: PayloadAction<SourceAccount[]>) => {
      if (
        state.selected_offset_account &&
        state.selected_offset_account.accountsOffsetInfo &&
        state.selected_offset_account.accountsOffsetInfo.sourceAccounts
      ) {
        state.selected_offset_account.accountsOffsetInfo.sourceAccounts =
          action.payload;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchOffsetAccounts.fulfilled, (state, action) => {
      state.list = action.payload;
    });
    builder.addCase(createNewOffsetAccount.fulfilled, (state, action) => {
      state.selected_offset_account = action.payload;
    });
    builder.addCase(fetchOffsetAccount.fulfilled, (state, action) => {
      state.selected_offset_account = action.payload;
    });
    builder.addCase(fetchOffsetAccountInfoByDate.fulfilled, (state, action) => {
      state.selected_offset_account = action.payload;
    });
  },
});

export const { setSourceAccounts } = clientSlice.actions;

export default clientSlice.reducer;
