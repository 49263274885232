import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */

export const selectEquityForecastDetails = (state: RootState) =>
  state.property.equity_forecast?.equityDetails;

export const selectEquityForecastMonthlyGraphData = (state: RootState) => {
  if (
    state.property.equity_forecast?.monthlyGraph &&
    state.property.equity_forecast?.monthlyGraph[0]
  ) {
    return state.property.equity_forecast?.monthlyGraph[0].data;
  }
};

export const selectEquityForecastQuaterlyGraphData = (state: RootState) => {
  if (
    state.property.equity_forecast?.quaterlyGraph &&
    state.property.equity_forecast?.quaterlyGraph[0]
  ) {
    return state.property.equity_forecast?.quaterlyGraph[0].data;
  }
};

export const selectEquityForecastYearlyGraphData = (state: RootState) => {
  if (
    state.property.equity_forecast?.yearlyGraph &&
    state.property.equity_forecast?.yearlyGraph[0]
  ) {
    return state.property.equity_forecast?.yearlyGraph[0].data;
  }
};
