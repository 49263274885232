import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import { addItemToStorage } from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';
import { setUser } from '../../authentication/redux/slice';
import { setClient } from '../../client/redux/slice';

export const fetchClientDashboardData = createAsyncThunk(
  'client-dashboard/fetch-data',
  async (apiParams: { token: string; values: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Report/dashboard`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);
    return data;
  },
);

export const sendOTP = createAsyncThunk(
  'client-dashboard/send-client-otp',
  async (apiParams: { linkId: string }) => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(),
      },
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Report/${apiParams.linkId}`,
      options,
    );
    const data = decryptData(response.data);
    return data;
  },
);

const handleClientLoginLogic = (data: any, thunkAPI: any, apiParams: any) => {
  const userData = {
    session_id: data.sessionId,
    user_id: data.reportUserId,
    expiry: data.reportToken.expiry,
    token: data.reportToken.token,
    role_id: data.roleId,
    email: apiParams.email,
    client_id: data.clientId,
  };

  const clientData = {
    clientId: data.clientId,
    clientName: data.clientName,
    clientPhoto: data.clientPhoto,
    planIds: data.clientPlans,
  };

  thunkAPI.dispatch(setClient(clientData));

  thunkAPI.dispatch(setUser(userData));

  addItemToStorage('user', JSON.stringify(userData));
  addItemToStorage('client', JSON.stringify(clientData));
};

export const validateOTP = createAsyncThunk(
  'client-dashboard/validate-otp',
  async (apiParams: { values: any; email?: string }, thunkAPI) => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(),
      },
    };
    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Report/validate-otp`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    handleClientLoginLogic(data, thunkAPI, apiParams);
    return data;
  },
);

export const sendInternalOTP = createAsyncThunk(
  'client-dashboard/internal-access',
  async (apiParams: { values: any }) => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(),
      },
    };

    const encryptedData = encryptData(apiParams.values);
    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Report/internal-access`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);
    return data;
  },
);
