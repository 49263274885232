import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const resetPasswordFormValidationSchema = () => {
  const fields: any = {
    email: Yup.string()
      .email('Please enter a valid email.')
      .required(requiredMessage)
      .nullable(),
    newPassCode: Yup.string()
      .required(requiredMessage)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
      )
      .nullable(),
    comfirmPassCode: Yup.string()
      .required(requiredMessage)
      .oneOf([Yup.ref('newPassCode'), null], 'Passwords must match'),
  };

  return Yup.object().shape(fields);
};
