import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';
import React from 'react';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      if (value && value.trim().length >= 3) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);

        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          setOptions(value && value !== '' ? [...newOptions] : newOptions);
          setFetching(false);
        });
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select<ValueType>
      // labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

type Props = {
  fetchOptions: (search: string) => Promise<any[]>;
  onChange?: (value: any) => void;
  value: string | number;
  placeholder?: string;
  disabled?: boolean;
};

const DebounceAjaxSelect = ({
  fetchOptions,
  onChange,
  value,
  placeholder,
  disabled = false,
}: Props) => {
  return (
    <DebounceSelect
      showSearch
      value={value}
      placeholder={placeholder}
      fetchOptions={fetchOptions}
      onChange={onChange}
      style={{ width: '100%' }}
      showArrow={false}
      className="c-select"
      disabled={disabled}
    />
  );
};

export default DebounceAjaxSelect;
