import 'chartjs-adapter-moment';

import { Col, Row, Table } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import LineGraph from '../../foundation/components/line_graph/LineGraph';
import CustomSelect from '../../foundation/components/select/Select';
import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import { colValueRenderer } from '../../foundation/utils/helperFunctions';
import {
  selectCashFlowProjectionMonthlyGraphData,
  selectCashFlowProjectionPerformanceDetails,
  selectCashFlowProjectionQuaterlyGraphData,
  selectCashFlowProjectionYearlyGraphData,
} from '../cashflow_projection/redux/selectors';
import {
  selectEquityForecastDetails,
  selectEquityForecastMonthlyGraphData,
  selectEquityForecastQuaterlyGraphData,
  selectEquityForecastYearlyGraphData,
} from '../equity_forecast/redux/selectors';
import { selectPerformanceTimelineTypes } from '../property/redux/selectors';

type Props = {
  dataView: 'm' | 'q' | 'a';
  isLoading: boolean;
  handleTimelineChange: (v: string) => void;
  timeline: string;
};

const FinancialProjections = ({
  dataView,
  isLoading,
  handleTimelineChange,
  timeline,
}: Props) => {
  /**
   * Equity Forecase Selectors
   */
  const equityForecastMonthlyGraphData = useSelector(
    selectEquityForecastMonthlyGraphData,
  );
  const equityForecastQuaterlyGraphData = useSelector(
    selectEquityForecastQuaterlyGraphData,
  );
  const equityForecastYearlyGraphData = useSelector(
    selectEquityForecastYearlyGraphData,
  );
  const equityForecastPerformanceData = useSelector(
    selectEquityForecastDetails,
  );

  /**
   * Cashflow Projection Selectors
   */
  const cashflowProjectionMonthlyGraphData = useSelector(
    selectCashFlowProjectionMonthlyGraphData,
  );
  const cashflowProjectionQuaterlyGraphData = useSelector(
    selectCashFlowProjectionQuaterlyGraphData,
  );
  const cashflowProjectionYearlyGraphData = useSelector(
    selectCashFlowProjectionYearlyGraphData,
  );

  const cashflowProjectionPerformanceData = useSelector(
    selectCashFlowProjectionPerformanceDetails,
  );

  const performanceTimelineTypes = useSelector(selectPerformanceTimelineTypes);

  const titlesToBold: string[] = [];
  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          undefined,
          titlesToBold,
          'details',
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          record.unit,
          titlesToBold,
          'details',
        );
      },
    },
  ];

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetCashflowProjection());
  //     dispatch(resetEequityForecast());
  //   };
  // }, [propertyId]);

  // useEffect(() => {
  //   console.log(dataView);
  //   fetchInitialData();
  // }, [dataView]);

  /**
   * X-axis labels.
   */
  const cashflowMonthlylabels = useMemo(() => {
    return cashflowProjectionMonthlyGraphData
      ? cashflowProjectionMonthlyGraphData.map((item) => moment(item.date))
      : [];
  }, [cashflowProjectionMonthlyGraphData]);

  /**
   * X-axis labels.
   */
  const cashflowYearlylabels = useMemo(() => {
    return cashflowProjectionYearlyGraphData
      ? cashflowProjectionYearlyGraphData.map((item) => moment(item.date))
      : [];
  }, [cashflowProjectionYearlyGraphData]);
  /**
   * X-axis labels.
   */
  const cashflowQuaterlylabels = useMemo(() => {
    return cashflowProjectionQuaterlyGraphData
      ? cashflowProjectionQuaterlyGraphData.map((item) => item.date) // TODO: maybe here we don't need map
      : [];
  }, [cashflowProjectionQuaterlyGraphData]);

  /**
   * Formatted data for net monthly cashflow graph.
   */
  const cashflowMonthlyGraphDataSet = useMemo(() => {
    return cashflowProjectionMonthlyGraphData
      ? cashflowProjectionMonthlyGraphData.map((item) => ({
          y: item.netMonthlyCashFlow,
          x: moment(item.date),
        }))
      : [];
  }, [cashflowProjectionMonthlyGraphData]);

  /**
   * Formatted data for net yearly cashflow graph.
   */
  const cashflowYearlyGraphDataSet = useMemo(() => {
    return cashflowProjectionYearlyGraphData
      ? cashflowProjectionYearlyGraphData.map((item) => ({
          y: item.netMonthlyCashFlow,
          x: moment(item.date),
        }))
      : [];
  }, [cashflowProjectionYearlyGraphData]);

  /**
   * Formatted data for net quaterly cashflow graph.
   */
  const cashflowQuaterlyGraphDataSet = useMemo(() => {
    return cashflowProjectionQuaterlyGraphData
      ? cashflowProjectionQuaterlyGraphData.map((item) => ({
          y: item.netMonthlyCashFlow,
          x: item.date,
        }))
      : [];
  }, [cashflowProjectionQuaterlyGraphData]);

  const cashflowProjectFixedGraphConfigurations = {
    segment: {},
    borderColor: 'rgba(0, 178, 163,0.7)',
    backgroundColor: 'rgba(0, 178, 163,0.7)',
    color: 'rgba(0,0,0,1)',
    pointRadius: 2,
    fill: {
      target: 'origin',
      below: 'rgba(255, 77, 79, 0.1)', // Area will be red above the origin
      above: 'rgba(0, 178, 163,0.1)',
    },
  };
  /**
   * Formatted data used by the cashflow monthly line graph.
   */
  const cashflowMonthlyGraphData = {
    cashflowMonthlylabels,
    datasets: [
      {
        label: 'Net Monthly Cashflow ',
        data: cashflowMonthlyGraphDataSet,
        ...cashflowProjectFixedGraphConfigurations,
      },
    ],
  };
  /**
   * Formatted data used by the cashflow yearly line graph.
   */
  const cashflowYearlyGraphData = {
    cashflowYearlylabels,
    datasets: [
      {
        label: 'Net Yearly Cashflow ',
        data: cashflowYearlyGraphDataSet,
        ...cashflowProjectFixedGraphConfigurations,
      },
    ],
  };
  /**
   * Formatted data used by the cashflow quaterly line graph.
   */
  const cashflowQuaterlyGraphData = {
    cashflowQuaterlylabels,
    datasets: [
      {
        label: 'Net Quaterly Cashflow ',
        data: cashflowQuaterlyGraphDataSet,
        ...cashflowProjectFixedGraphConfigurations,
      },
    ],
  };

  /**
   * Data selector for cashflow line graph
   */
  const cashflowGraphData = useMemo(() => {
    if (dataView === 'm') {
      return cashflowMonthlyGraphData;
    } else if (dataView === 'q') {
      return cashflowQuaterlyGraphData;
    } else {
      return cashflowYearlyGraphData;
    }
  }, [
    dataView,
    cashflowMonthlyGraphData,
    cashflowQuaterlyGraphData,
    cashflowYearlyGraphData,
  ]);

  const xAxesTypeForCashflow = useMemo(() => {
    if (dataView === 'm') {
      return 'time';
    } else if (dataView === 'q') {
      return 'string';
    } else {
      return 'time';
    }
  }, [dataView]);

  const xAxesTimeUnitForCashflow = useMemo(() => {
    if (dataView === 'm') {
      return 'month';
    } else if (dataView === 'q') {
      return '';
    } else {
      return 'year';
    }
  }, [dataView]);

  /**
   * X-axis equity montly labels.
   */
  const equityMonthlyLabels = useMemo(() => {
    return equityForecastMonthlyGraphData
      ? equityForecastMonthlyGraphData.map((item) => moment(item.date))
      : [];
  }, [equityForecastMonthlyGraphData]);

  /**
   * X-axis equity quaterly labels.
   */
  const equityQuaterlyLabels = useMemo(() => {
    return equityForecastQuaterlyGraphData
      ? equityForecastQuaterlyGraphData.map((item) => item.date)
      : [];
  }, [equityForecastQuaterlyGraphData]);

  /**
   * X-axis equity quaterly labels.
   */
  const equityYearlyLabels = useMemo(() => {
    return equityForecastYearlyGraphData
      ? equityForecastYearlyGraphData.map((item) => item.date)
      : [];
  }, [equityForecastYearlyGraphData]);

  /**
   * Formatted data for equity monthly forecast graph.
   */
  const equityMonthlyGraphDataSet = useMemo(() => {
    return equityForecastMonthlyGraphData
      ? equityForecastMonthlyGraphData.map((item) => ({
          y: item.equityAvailable,
          x: moment(item.date),
        }))
      : [];
  }, [equityForecastMonthlyGraphData]);

  /**
   * Formatted data for equity quaterly forecast graph.
   */
  const equityQuaterlyGraphDataSet = useMemo(() => {
    return equityForecastQuaterlyGraphData
      ? equityForecastQuaterlyGraphData.map((item) => ({
          y: item.equityAvailable,
          x: item.date,
        }))
      : [];
  }, [equityForecastQuaterlyGraphData]);

  /**
   * Formatted data for equity yearly forecast graph.
   */
  const equityYearlyGraphDataSet = useMemo(() => {
    return equityForecastYearlyGraphData
      ? equityForecastYearlyGraphData.map((item) => ({
          y: item.equityAvailable,
          x: moment(item.date),
        }))
      : [];
  }, [equityForecastYearlyGraphData]);

  const equityForecastFixedGraphConfigurations = {
    segment: {},
    borderColor: 'rgba(255, 179, 25, 0.7)',
    backgroundColor: 'rgba(255, 179, 25, 0.7)',
    color: 'rgba(0,0,0,1)',
    pointRadius: 2,
    fill: {
      target: 'origin',
      below: 'rgba(255, 77, 79, 0.1)', // Area will be red above the origin
      above: 'rgba(255, 179, 25, 0.2)',
    },
  };

  const equityMonthlyGraphData = {
    labels: equityMonthlyLabels,
    datasets: [
      {
        label: 'Equity Available ',
        data: equityMonthlyGraphDataSet,
        ...equityForecastFixedGraphConfigurations,
      },
    ],
  };

  const equityQuaterlyGraphData = {
    labels: equityQuaterlyLabels,
    datasets: [
      {
        label: 'Equity Available ',
        data: equityQuaterlyGraphDataSet,
        ...equityForecastFixedGraphConfigurations,
      },
    ],
  };

  const equityYearlyGraphData = {
    labels: equityYearlyLabels,
    datasets: [
      {
        label: 'Equity Available ',
        data: equityYearlyGraphDataSet,
        ...equityForecastFixedGraphConfigurations,
      },
    ],
  };

  /**
   * Data selector for cashflow line graph
   */
  const equityGraphData = useMemo(() => {
    if (dataView === 'm') {
      return equityMonthlyGraphData;
    } else if (dataView === 'q') {
      return equityQuaterlyGraphData;
    } else {
      return equityYearlyGraphData;
    }
  }, [
    dataView,
    equityMonthlyGraphData,
    equityQuaterlyGraphData,
    equityYearlyGraphData,
  ]);

  // const handleTimeline = (v: string) => {
  //   fetchInitialData(v);
  //   setTimeline(v);
  // };

  return (
    <div className="financial_projections">
      {isLoading && <FullPageLoader />}
      <div className="financial_projections__header">
        <Row align="middle" gutter={[10, 0]} style={{ marginBottom: '20px' }}>
          <Col xs={24} md={12}>
            <div className="financial_projections__header-title">
              Financial Projections
            </div>
          </Col>
          <Col xs={24} md={7} className="h-text-align">
            <span>Timeline</span>
          </Col>
          <Col xs={24} md={5}>
            <CustomSelect
              placeholder="Years"
              options={performanceTimelineTypes ? performanceTimelineTypes : []}
              onChange={handleTimelineChange}
              value={timeline}
            />
          </Col>
        </Row>
      </div>
      <div className="financial_projections__details-table-wrapper">
        <Row align="middle" gutter={[30, 20]} style={{ marginBottom: '10px' }}>
          <Col xs={24} md={12}>
            <div className="financial_projections__details-table-header">
              Cashflow Projection
            </div>
            <Table
              columns={columns}
              dataSource={cashflowProjectionPerformanceData}
              size={useTableSize()}
              pagination={false}
              bordered
              scroll={{ x: true }}
            />
          </Col>
          <Col xs={24} md={12}>
            <div className="financial_projections__details-table-header">
              Equity Projection
            </div>
            <Table
              columns={columns}
              dataSource={equityForecastPerformanceData}
              size={useTableSize()}
              pagination={false}
              bordered
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
      <div className="financial_projections__details-table-wrapper">
        <Row align="middle" gutter={[30, 20]} style={{ marginBottom: '10px' }}>
          <Col xs={24} xxl={12}>
            <div className="dashboard__chart-wrapper">
              <section className="dashboard__chart-content">
                <LineGraph
                  xAxesLabel="Graph Timeline"
                  yAxesLabel="Net Cashflow"
                  data={cashflowGraphData}
                  graphTitle="Financial Projection"
                  areaAboveFill="rgba(0, 178, 163,0.2)"
                  xAxesType={xAxesTypeForCashflow}
                  xAxesTimeUnit={xAxesTimeUnitForCashflow}
                />
              </section>
            </div>
          </Col>

          <Col xs={24} xxl={12}>
            <div className="dashboard__chart-wrapper">
              <section className="dashboard__chart-content">
                <LineGraph
                  xAxesLabel="Graph Timeline"
                  yAxesLabel="Equity Forecast"
                  data={equityGraphData}
                  graphTitle="Financial Projection"
                  areaAboveFill="rgba(0, 178, 163,0.2)"
                  xAxesType={xAxesTypeForCashflow}
                  xAxesTimeUnit={xAxesTimeUnitForCashflow}
                />
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FinancialProjections;
