import {
  AppstoreAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  LogoutOutlined,
  ProjectOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Layout, MenuProps, Modal } from 'antd';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import { RouteType } from '../../../foundation/config/routes';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import {
  clearStorage,
  getItemFromStorage,
  removeItemToStorage,
} from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';
import { useAppDispatch } from '../../../store/hooks';
import { logout } from '../../authentication/redux/async_thunks';
import { selectUser } from '../../authentication/redux/selectors';
import ClientModal from '../../client/modals/client_modal/ClientModal';
import { selectClient } from '../../client/redux/selectors';
import { setClient } from '../../client/redux/slice';
import PlanDuplicate from '../../plan/plan_duplicate/PlanDuplicate';
import PlanNameModal from '../../plan/plan_name_modal/PlanNameModal';
import { deletePlan } from '../../plan/redux/async_thunks';
import { selectPlan } from '../../plan/redux/selectors';
import { setPlan } from '../../plan/redux/slice';

const { Header: LayoutHeader } = Layout;

const { confirm } = Modal;

const Header = ({
  children,
  routeConfig,
}: {
  children: ReactNode;
  routeConfig: RouteType | undefined;
}) => {
  const { isDesktopViewport } = useViewport();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [isEditPlanModalVisible, setIsEditPlanModalVisible] = useState(false);

  const [isClientModalVisible, setIsClientModalVisible] = useState(false);

  const [isDuplicatePlanModalVisible, setIsDuplicatePlanModalVisible] =
    useState(false);

  const user = useSelector(selectUser);

  const isClientView = useRole();

  const plan = useSelector(selectPlan);

  const client = useSelector(selectClient);

  const [isIndex, setIsIndex] = useState(true);

  const handleLogout = async () => {
    const getUser = getItemFromStorage('user');
    // @ts-ignore
    const userStorage = JSON.parse(getUser);
    const fixUser = user || userStorage;
    try {
      if (fixUser) {
        const data: any = {
          sessionId: fixUser.session_id,
        };
        if (isClientView) {
          data.email = fixUser.email;
        } else {
          data.appId = env_constants.APP_ID;
          data.userId = fixUser?.user_id;
        }

        await dispatch(
          logout({
            token: fixUser?.token,
            data,
            isClientView,
          }),
          // @ts-ignore
        ).unwrap();
        clearStorage();

        if (isClientView) {
          window.location.reload();
        } else {
          window.close();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const menu = useMemo(
    () => [
      {
        key: '1',
        icon: <LogoutOutlined />,
        onClick: handleLogout,
        label: `Logout`,
      },
    ],
    [isClientView],
  );

  const clearActivity = () => {
    /**
     * Remove values for client and plan.
     */
    removeItemToStorage('client');
    removeItemToStorage('plan');
    dispatch(setClient(undefined));
    dispatch(setPlan(undefined));
  };

  const handleSelectNewClient = useCallback(() => {
    clearActivity();

    // Show client selection page.
    navigate('/');
  }, []);

  const handleEditClient = () => {
    setIsClientModalVisible(true);
  };

  const handleSelectNewPlan = useCallback(() => {
    /**
     * Remove values for  plan.
     */
    removeItemToStorage('plan');
    dispatch(setPlan(undefined));

    // Show plan selection page.
    navigate('/plan');
  }, []);

  const handlePlanDelete = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);

      if (user) {
        const reqData = {
          planId: plan?.planId,
          userId: user?.user_id,
        };

        const respClient = await dispatch(
          deletePlan({ token: user.token, data: reqData }),
          // @ts-ignore
        ).unwrap();

        dispatch(setClient(respClient));

        setLoading(false);
        // Show plan selection page.
        navigate('/plan');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this plan?',
      icon: <ExclamationCircleOutlined />,
      content:
        'This action is irreversible, and deleting this plan will also delete all the properties associated with it.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      keyboard: false,
      okButtonProps: {
        loading,
        disabled: loading,
      },
      cancelButtonProps: {
        loading,
        disabled: loading,
      },
      onOk: handlePlanDelete,
    });
  };

  const handleHomeNavClick = (e: any) => {
    e.preventDefault();
    clearActivity();
    setIsIndex(true);
    navigate('/');
  };

  const handleReportsNavClick = (e: any) => {
    e.preventDefault();
    clearActivity();
    setIsIndex(false);
    navigate('/report-downloads');
  };

  const handleEditPlanNameClick = () => {
    setIsEditPlanModalVisible(true);
  };

  const handleDuplicatePlanNameClick = () => {
    setIsDuplicatePlanModalVisible(true);
  };

  const handleEditPlanNameClose = () => {
    setIsEditPlanModalVisible(false);
  };

  const handleDuplicatePlanClose = () => {
    setIsDuplicatePlanModalVisible(false);
  };

  const handleClientModalClose = () => {
    setIsClientModalVisible(false);
  };

  const clienMenu: MenuProps['items'] = useMemo(() => {
    const items = [
      {
        key: '1',
        icon: <UserSwitchOutlined />,
        onClick: handleSelectNewClient,
        label: `Select new client`,
      },
    ];

    if (client?.isEditable) {
      items.push({
        key: '2',
        icon: <EditOutlined />,
        onClick: handleEditClient,
        label: `Edit client`,
      });
    }

    return items;
  }, [client]);

  const planMenuItems: () => MenuProps['items'] = () => {
    const planItems: MenuProps['items'] = [
      {
        key: '1',
        icon: <AppstoreAddOutlined />,
        onClick: handleSelectNewPlan,
        label: `Select new plan`,
      },
    ];

    if (!isClientView) {
      const restrictedPlanItems = [
        {
          key: '2',
          icon: <EditOutlined />,
          onClick: handleEditPlanNameClick,
          label: `Edit plan`,
        },
        {
          key: '3',
          icon: <CopyOutlined />,
          onClick: handleDuplicatePlanNameClick,
          label: `Duplicate this plan`,
        },
        {
          key: '4',
          icon: <DeleteOutlined style={{ color: '#eb5757' }} />,
          onClick: showDeleteConfirm,
          label: <span style={{ color: '#eb5757' }}>Delete this plan</span>,
        },
      ];

      planItems.push(...restrictedPlanItems);
    }

    return planItems;
  };

  const full_name = useMemo(() => {
    if (user && !isClientView) {
      return `${user.first_name} ${user.last_name}`;
    } else if (isClientView) {
      return `${user?.email}`;
    } else {
      return `User`;
    }
  }, [user, isClientView]);

  const activeClassName = ' c-app-layout__header-nav-item--active';

  return (
    <LayoutHeader className="c-app-layout__header">
      {isDuplicatePlanModalVisible && (
        <PlanDuplicate closeModal={handleDuplicatePlanClose} />
      )}
      {isEditPlanModalVisible && (
        <PlanNameModal closeModal={handleEditPlanNameClose} />
      )}

      {isClientModalVisible && (
        <ClientModal closeModal={handleClientModalClose} />
      )}

      <div className="c-app-layout__header-left-side">
        <div className="c-app-layout__header-logo">
          {env_constants.Environ !== 'Demo' && <Logo />}
        </div>
      </div>

      <div className="c-app-layout__header-right-side">
        {isDesktopViewport && (
          <div className="c-app-layout__header-nav">
            <button
              // href=""
              onClick={handleHomeNavClick}
              className={`c-app-layout__header-nav-item${isIndex ? activeClassName : ''}`}
            >
              Home
            </button>
            <button
              // href=""
              onClick={handleReportsNavClick}
              className={`c-app-layout__header-nav-item${!isIndex ? activeClassName : ''}`}
            >
              Reports
            </button>
          </div>
        )}
        <div className="c-app-layout__header-all-details-wrapper">
          {client && client.clientName && (
            <Dropdown menu={{ items: clienMenu }} disabled={isClientView}>
              <div
                className="c-app-layout__header-client"
                style={isClientView ? { cursor: 'default' } : {}}
              >
                <div className="c-app-layout__header-client-details">
                  <div
                    style={{ marginRight: 10 }}
                    className="c-app-layout__header-client-name-wrapper"
                  >
                    {client.clientName}
                  </div>
                  <div className="c-app-layout__header-client-image-wrapper">
                    <Avatar src={client.clientPhoto} icon={<UserOutlined />} />
                  </div>
                </div>

                <div style={{ fontSize: 12 }}>
                  <DownOutlined />
                </div>
              </div>
            </Dropdown>
          )}
          {plan && plan.planName && (
            <Dropdown menu={{ items: planMenuItems() }}>
              <div className="c-app-layout__header-details-wrapper">
                <div className="c-app-layout__header-details">
                  Portfolio Plan
                </div>
                <div className="c-app-layout__headehe`r-details c-app-layout__header-portfolio-type">
                  <div className="c-app-layout__header-portfolio-type-text">
                    {plan?.planName}
                  </div>
                  <div style={{ fontSize: 12, marginLeft: 20 }}>
                    <DownOutlined />
                  </div>
                </div>
              </div>
            </Dropdown>
          )}
        </div>

        <div style={{ display: 'flex' }}>
          {!isDesktopViewport && (
            <div className="c-app-layout__header-nav-small">
              <button onClick={handleHomeNavClick}>
                <Avatar icon={<HomeOutlined />} />
              </button>

              <button onClick={handleReportsNavClick}>
                <Avatar icon={<ProjectOutlined />} />
              </button>
            </div>
          )}
          <Dropdown menu={{ items: menu }}>
            <div className="c-app-layout__header-user">
              {isDesktopViewport && (
                <div style={{ marginRight: 5 }}>{full_name}</div>
              )}
              <div style={{ marginRight: 5 }}>
                <Avatar src={user?.picture} icon={<UserOutlined />} />
              </div>
              <div style={{ marginRight: 25, fontSize: 12 }}>
                <DownOutlined />
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </LayoutHeader>
  );
};

export default Header;
