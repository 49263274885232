import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getItemFromStorage } from '../../../foundation/utils/storageHandler';

// Typescript Props
type Props = {
  children: React.ReactElement;
};

const PublicRoute = ({ children }: Props): JSX.Element => {
  const location = useLocation();
  if (getItemFromStorage('user')) {
    return (
      <Navigate
        replace
        to="/"
        state={{
          from: location,
        }}
      />
    );
  } else {
    return children;
  }
};

export default PublicRoute;
