import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import { addItemToStorage } from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';
import { setUser } from '../../authentication/redux/slice';
import { setClient } from '../../client/redux/slice';
import { setClientDashboard } from '../../client_dashboard/redux/slice';

export const clientSignup = createAsyncThunk(
  'client-authentication/signup',
  async (apiParams: { values: any }) => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(),
      },
    };
    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Auth/join`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

const handleClientLoginLogic = (data: any, thunkAPI: any, apiParams: any) => {
  const userData = {
    session_id: data.sessionId,
    user_id: data.reportUserId,
    expiry: data.reportToken.expiry,
    token: data.reportToken.token,
    role_id: data.roleId,
    email: apiParams.values.email,
    client_id: data.clientId,
  };

  const clientData = {
    clientId: data.clientId,
    clientName: data.clientName,
    clientPhoto: data.clientPhoto,
    planIds: data.clientPlans,
  };

  const reportData = {
    values: data.dashboard,
    timeline_years: data.timelineYears,
    timeline_types: data.dashboardTimelineTypes,
  };

  thunkAPI.dispatch(setClient(clientData));

  thunkAPI.dispatch(setUser(userData));

  thunkAPI.dispatch(setClientDashboard(reportData));

  addItemToStorage('user', JSON.stringify(userData));
  addItemToStorage('client', JSON.stringify(clientData));

  // TODO: Set all the other values necessary here as well.
};

export const clientLogin = createAsyncThunk(
  'client-authentication/login',
  async (
    apiParams: { values: { email: string; passCode: string } },
    thunkAPI,
  ) => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(),
      },
    };
    const encryptedData = encryptData(apiParams.values);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Auth/login`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    handleClientLoginLogic(data, thunkAPI, apiParams);
    return data;
  },
);

export const clientForgotPass = createAsyncThunk(
  'client-authentication/forgot-pass',
  async (apiParams: { data: { email: string } }) => {
    const options = {
      headers: {
        'Content-type': 'application/json',
        clientid: getSessionClientID(),
      },
    };
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Auth/forgot-pass/otp`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export const clientResetPass = createAsyncThunk(
  'client-authentication/reset-pass',
  async (apiParams: {
    data: { email: string; otp: string; newPassCode: string };
  }) => {
    try {
      const options = {
        headers: {
          'Content-type': 'application/json',
          clientid: getSessionClientID(),
        },
      };
      const encryptedData = encryptData(apiParams.data);

      const response = await axios.post(
        `${env_constants.PP_API_BASE_URL}/Auth/forgot-pass`,
        encryptedData,
        options,
      );

      const data = decryptData(response.data);

      return data;
    } catch (err) {
      console.log(err);
      console.log(decryptData(err));
    }
  },
);
