import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { Buffer } from 'buffer';
import { Formik } from 'formik';
import randomstring from 'randomstring';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { loginFormValidationSchema } from '../validations/login_form_validation';
import { signupFormValidationSchema } from '../validations/signup_form_validation';

type ClientAuthenticationFormProps = {
  handleFormSubmit: (values: any) => void;
  isFormLoading: boolean;
  formType: 'login' | 'signup';
  redactedEmail?: string | undefined;
};

const ClientAuthenticationForm = ({
  handleFormSubmit,
  isFormLoading,
  formType,
  redactedEmail = '***@****.com',
}: ClientAuthenticationFormProps) => {
  const navigate = useNavigate();
  const formInformattionText = useMemo(() => {
    if (formType === 'signup') {
      return `Please enter the email address that is an exact match of ${redactedEmail} \nThen choose a password to view your Portfolio Growth plan.`;
    } else {
      return `Welcome back! Please enter your details.`;
    }
  }, [formType]);

  const handleForgotPasswordClick = () => {
    navigate('/client/reset-password');
  };

  const formSubmitHandler = (values) => {
    const passSuffix = randomstring.generate(20);
    const passPrefix = randomstring.generate(20);

    const appendedPass = `${passPrefix}${values.passCode}${passSuffix}`;

    const encodedPass = Buffer.from(appendedPass).toString('base64');

    const finalData = {
      email: values.email,
      passCode: encodedPass,
    };

    handleFormSubmit(finalData);
  };

  return (
    <div className="client-dash-login__form-wrapper">
      <Formik
        initialValues={{
          email: undefined,
          passCode: undefined,
          comfirmPassCode: undefined,
        }}
        enableReinitialize
        onSubmit={formSubmitHandler}
        validationSchema={
          formType === 'login'
            ? loginFormValidationSchema
            : signupFormValidationSchema
        }
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="client-dash-login__form">
              <div className="client-dash-login__pgp-heading">
                Portfolio Growth Plan
              </div>
              <div className="client-dash-login__form-heading">{`Let’s sign you ${
                formType === 'signup' ? 'up' : 'in'
              }.`}</div>
              <div className="client-dash-login__form-sub-heading">
                {formInformattionText}
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">Email:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    onBlur={handleBlur}
                    placeholder={'Enter your email'}
                  />
                  <div className="c-form-field__error">
                    {errors.email ? errors.email : undefined}
                  </div>
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">Password:</div>
                <div className="c-form-field__wrapper">
                  <Input.Password
                    name="passCode"
                    onChange={handleChange}
                    type="text"
                    value={values.passCode}
                    onBlur={handleBlur}
                    placeholder={'*********'}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  <div className="c-form-field__error">
                    {errors.passCode ? errors.passCode : undefined}
                  </div>
                </div>
              </div>
              {formType === 'signup' && (
                <div className="c-form-field">
                  <div className="c-form-field__label">Confirm Password:</div>
                  <div className="c-form-field__wrapper">
                    <Input.Password
                      name="comfirmPassCode"
                      onChange={handleChange}
                      type="text"
                      value={values.comfirmPassCode}
                      onBlur={handleBlur}
                      placeholder={'*********'}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                    <div className="c-form-field__error">
                      {errors.comfirmPassCode
                        ? errors.comfirmPassCode
                        : undefined}
                    </div>
                  </div>
                </div>
              )}
              <div className="client-dash-login__form-actions">
                <div></div>
                <div
                  className="client-dash-login__form-btn-forgot-pass"
                  onClick={handleForgotPasswordClick}
                >
                  Forgot password
                </div>
              </div>
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="client-dash-login__form-btn"
                  disabled={!(isValid && dirty) || isFormLoading}
                >
                  Sign in
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ClientAuthenticationForm;
