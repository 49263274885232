import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

import EmailFeatured from '../../assets/svgs/EmailFeatured.svg';
import OtpInput from '../otp_input/OtpInput';

let timer: any = undefined;

type Props = {
  onSubmit: (otp: string) => void;
  email: string;
  handleResendOTP: () => Promise<any>;
  handleCancel: () => void;
};

const OTPView = ({ onSubmit, email, handleResendOTP, handleCancel }: Props) => {
  const [isOTPLoading, setIsOTPLoading] = useState(false);

  const [OTP, setOTP] = useState<string | undefined>(undefined);

  const [otpExpiry, setOtpExpiry] = useState<number | undefined>(120);

  const errorHandler = () => {
    setIsOTPLoading(false);
  };

  const otpTimerHandler = () => {
    if (otpExpiry && otpExpiry !== 0) {
      setOtpExpiry((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else if (time) {
          return time - 1;
        }
      });
    }
  };

  useEffect(() => {
    if (otpExpiry && otpExpiry === 120) {
      timer = setInterval(otpTimerHandler, 1000);
    }
  }, [otpExpiry]);

  const handleOTPValidation = async () => {
    if (isOTPLoading) {
      return;
    }

    try {
      if (OTP) {
        setIsOTPLoading(true);

        onSubmit(OTP);

        setIsOTPLoading(false);
      }
    } catch (error) {
      errorHandler();
    }
  };

  const handleOTPChange = (v: any) => {
    setOTP(v);
  };

  const handleResend = async () => {
    if (isOTPLoading || otpExpiry === undefined || otpExpiry !== 0) {
      return;
    }

    try {
      setIsOTPLoading(true);
      await handleResendOTP();
      setOtpExpiry(120);
      setIsOTPLoading(false);
    } catch (error) {
      console.log(error);
      errorHandler();
    }
  };

  const handleOTPCancel = () => {
    clearInterval(timer);
    handleCancel();
  };

  return (
    <div className="l-otp">
      <div className="l-otp__form-wrapper">
        <div>
          <EmailFeatured />
        </div>
        <div className="l-otp__form-msg">Please check your email</div>
        <div className="l-otp__form-sub-msg">{`We've sent a code to ${
          email ? email : `your email.`
        }`}</div>
        <div>
          <OtpInput
            value={OTP}
            inputStyle="inputStyle"
            numInputs={6}
            errorStyle="error"
            separatorPlacement={3}
            onChange={handleOTPChange}
            separator={<span className="otp-separator">-</span>}
            isInputNum={true}
            shouldAutoFocus
            placeholder={306600}
          />
        </div>
        <div className="l-otp__resend-code ">
          <span style={{ marginRight: 5 }}>{`Didn’t get a code?`}</span>
          <a
            onClick={handleResend}
            className={`l-otp__resend-code ${
              otpExpiry === undefined || otpExpiry !== 0
                ? ' l-otp__resend-code--disabled '
                : 'l-otp__resend-code--enabled'
            }`}
          >
            Click to resend
            {otpExpiry && otpExpiry !== 0 ? `(in ${otpExpiry}s)` : ''}
          </a>
        </div>

        <div className={`l-otp__btn-wrapper `}>
          <Button
            className="l-otp__btn l-otp__btn--cancel"
            loading={isOTPLoading}
            onClick={handleOTPCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleOTPValidation}
            className="l-otp__btn l-otp__btn--verify"
            loading={isOTPLoading}
            disabled={OTP && OTP?.length >= 6 ? false : true}
          >
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OTPView;
